import { Canvas3D } from '../draw3d/Canvas3D';
import { Functions } from '../helpers/functions';
import { Stairs } from './stairs';
import { RemoveRasters } from './removeRasters';
import { RemoveRaster } from './removeRaster';
import { Profiles } from './profiles';
import { Bracings } from './bracings.js';
import { BraceColumns } from './braceColumns';
import { HandRails } from './handRails';
import { Configuration } from './configuration';
import { PalletGates } from './palletGates';
import { Holes } from './holes';
import { Errors } from './errors';

import { Plates } from './plates';
import { PortalBracings } from './portalBracings';
import { BracingRules } from './bracingRules';
export class Etage {
	// types moet static worden
	objectName = 'Etage';
	_type = '';
	_height = 3000;
	hasBraceErrors = false;
	kwadrantError = false;
	usedSurface = [];

	id = '';
	get type() {
		return this._type;
	}
	set type(value) {
		this._type = value;
		this.updateStairsHeight();
		this.rasterChanged();
	}
	get height() {
		return this._height;
	}
	set height(value) {
		this._height = parseInt(value);
	}
	setHeight(etageIndex) {
		this.updateStairsHeight(etageIndex);

		// BuildingColumns updaten
		Configuration.CURRENT.buildingColumns.buildingColumns.forEach((bc) => {
			if (bc.customHeight === false) {
				bc.updateHeight(Configuration.CURRENT.etages.getTotalHeight(Configuration.CURRENT.etages.etages.length - 1, true));
			}
		});
		this.rasterChanged();
	}
	updateStairsHeight(etageIndex) {
		// Bij aanpassing van etageType hoogte van stairs updaten
		// Ook bij hoogte aanpassing updaten.
		if (Configuration.CURRENT !== null && typeof Configuration.CURRENT !== 'undefined') {
			let eHeight = 0;
			Configuration.CURRENT.etages.etages.forEach((etage, index) => {
				eHeight += etage.getHeight(true);
				if (etage.stairs.stairs.length > 0) {
					etage.stairs.stairs.forEach((stair) => {
						if (typeof stair.setEtageHeight === 'function') {
							if (stair.objectName === 'StairOutSide') {
								stair.setEtageHeight(eHeight, index);
							}
							if (stair.objectName === 'StairInFloor') {
								stair.setEtageHeight(etage.getHeight(true));
							}
						}
					});
				}
			});
		}
	}
	removeRasters = new RemoveRasters(
		[],
		function (status) {
			// this.calculateSurface();
			this.raster.onChange(status);
		}.bind(this),
	);
	stairs = new Stairs(this.onChange.bind(this), (boundaries, self) => {
		this.checkCollisions(boundaries, self);
	});
	palletGates = new PalletGates(this.onChange.bind(this), (boundaries, self) => {
		this.checkCollisions(boundaries, self);
	});
	portalBracings = new PortalBracings(this.redraw.bind(this));
	bracingRules = new BracingRules(() => {
		this.redraw.bind(this);
	});
	bracings = new Bracings(this.redraw.bind(this));
	braceColumns = new BraceColumns(this.redraw.bind(this));

	handRails = new HandRails(() => {
		this.onChange.bind(this);
	});

	plates = new Plates(() => {
		this.onChange();
	});

	holes = new Holes(this.onChange.bind(this));

	_surface = 0;
	get surface() {
		return this._surface;
	}
	set surface(value) {
		this._surface = value;
	}
	floor = { length: 0, width: 0 };
	raster = null;

	active = false;
	constructor(type, height, active) {
		this.type = type;
		this.height = height;
		this.active = active;
		this.id = Functions.uuidv4();
		this.setId();
	}
	setId() {
		this.handRails.setEtageId(this.id);
	}
	afterReconstruct() {
		this.setId();
		this.usedSurface = [];
	}
	checkMoveProfilePossible(params) {
		let possible = true;
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].moveProfilePossible === 'function') {
				let possibleCheck = this[object].moveProfilePossible(params);
				if (possibleCheck === false) {
					possible = false;
				}
			}
		});
		return possible;
	}

	onRasterChanged(params) {
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].onRasterChanged === 'function') {
				this[object].onRasterChanged(params);
			}
		});
	}
	calculateAmount(params) {
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].calculateAmount === 'function') {
				this[object].calculateAmount(params);
			}
		});
	}
	redraw() {
		if (this._redraw !== null && typeof this._redraw === 'function') {
			this._redraw();
		}
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._rasterChanged = params.rasterChanged;
		this.raster = params.raster;
		this._redraw = params.redraw;

		this.removeRasters.setReferences({
			onChange: function (status) {
				// this.calculateSurface();
				this.raster.onChange(status);
			}.bind(this),
		});
		const referenceParams = {
			onChange: this.rasterChanged.bind(this),
			redraw: this.redraw.bind(this),
			checkCollisions: this.checkCollisions.bind(this),
		};
		this.handRails.setReferences(referenceParams);
		this.bracings.setReferences(referenceParams);
		this.portalBracings.setReferences(referenceParams);
		this.stairs.setReferences(referenceParams);
		this.palletGates.setReferences(referenceParams);
		this.holes.setReferences(referenceParams);
		this.braceColumns.setReferences(referenceParams);
		this.bracingRules.setReferences(referenceParams);
	}
	removeReferences() {
		this._onChange = null;
		this._rasterChanged = null;
		this._redraw = null;
		if (typeof this.removeRasters.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.removeRasters.removeReferences();
		}
		if (typeof this.handRails.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.handRails.removeReferences();
		}
		if (typeof this.bracings.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.bracings.removeReferences();
		}
		if (typeof this.stairs.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.stairs.removeReferences();
		}
		if (typeof this.palletGates.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.palletGates.removeReferences();
		}
		if (typeof this.holes.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.holes.removeReferences();
		}
		if (typeof this.braceColumns.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.braceColumns.removeReferences();
		}

		if (typeof this.bracingRules.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.bracingRules.removeReferences();
		}
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	rasterChanged() {
		if (typeof this._rasterChanged === 'function') {
			this._rasterChanged();
		}
	}
	toggleRaster(raster) {
		this.removeRasters.toggleRaster(raster);
	}
	getHeight(heightOnTop = true) {
		let height = this.height;

		if (heightOnTop === false) {
			// niet hoogte on top (dus freeheight)
			// als opgegeven hoogte = under is dat dus de hoogte
			if (this.type !== 'under') {
				// als hoogte niet is under dan is hoogte tot top en dus packetheight eraf.
				height -= this.getPacketHeight();
			}
		}
		// hoogte tot bovenkant vloer
		// type niet under dan is dat tot bovenkant vloer
		else if (this.type === 'under') {
			// onderkant vloer dan packethoogte erbij.
			height += this.getPacketHeight();
		}

		return height;
	}
	getPacketHeight() {
		return Configuration.CURRENT.profiles.getMaxProfileHeight() + Configuration.CURRENT.finish.height; // voorlopig maxprofile vanuit profile omdat er maar 1 soort van main en child is. Als er per lengte een andere kan zijn dan vanuit etage.
	}

	getAmount() {
		// dubbel met calculateAmount. Voorlopig onderscheiden en is dit alleen de verzameloptie en bij calculate berekent hij het.
		// get amount zou dan ook in de ui gebruikt kunnen worden
		let amountObject = {};
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].getAmount === 'function') {
				let amount = this[object].getAmount(amountObject);
				Object.keys(amount).forEach((object) => {
					amountObject[object] = amount[object];
				});
			}
		});
		this.getErrors(); // nog een keer checken voor bracings. Weet niet wat het met performance doet. Maar probleem is dat na amount bracings pas geteld kunnen worden
		return amountObject;
	}
	getRasterWidthAndLength() {
		let obj = {
			x: 0,
			y: 0,
			minX: 0,
			minY: 0,
			width: 0,
			length: 0,
		};

		let floor = Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].floor;

		this.raster.spansX.getSpans().forEach((spanX, indexX) => {
			this.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (this.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					const bovenliggendActief = this.isActiveRaster(new RemoveRaster(indexX, indexY - 1));
					const linksActief = this.isActiveRaster(new RemoveRaster(indexX - 1, indexY));
					const rechtsActief = this.isActiveRaster(new RemoveRaster(indexX + 1, indexY));
					if ((rechtsActief === true && bovenliggendActief === false) || (bovenliggendActief === false && rechtsActief === false)) {
						obj.x += spanX.value;
					}
					if ((bovenliggendActief === false && linksActief === false) || (bovenliggendActief === true && linksActief === false)) {
						obj.y += spanY.value;
					}
				}
			});
		});

		obj.width = floor.width;
		obj.length = floor.length;
		obj.minX = floor.width - obj.x;
		obj.minY = floor.length - obj.y;

		return obj;
	}
	getSurface(calculateOverhang = true) {
		let surface = 0;
		this.usedSurface = [];
		// Forceer berekenen van usedSurface
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].addUsedSurface === 'function') {
				this[object].addUsedSurface();
			}
		});

		Configuration.CURRENT.buildingColumns.addUsedSurface();

		this.raster.spansX.getSpans().forEach((spanX, indexX) => {
			this.raster.spansY.getSpans().forEach((spanY, indexY) => {
				const overhang = Configuration.CURRENT.overhang;
				if (this.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					let rasterSurface = spanX.value * spanY.value;

					if (calculateOverhang === true) {
						const bovenliggendActief = this.isActiveRaster(new RemoveRaster(indexX, indexY - 1));
						const onderliggendActief = this.isActiveRaster(new RemoveRaster(indexX, indexY + 1));
						const linksActief = this.isActiveRaster(new RemoveRaster(indexX - 1, indexY));
						const rechtsActief = this.isActiveRaster(new RemoveRaster(indexX + 1, indexY));
						const linksBovenActief = this.isActiveRaster(new RemoveRaster(indexX - 1, indexY - 1));
						const rechtsBovenActief = this.isActiveRaster(new RemoveRaster(indexX + 1, indexY - 1));
						const linksOnderActief = this.isActiveRaster(new RemoveRaster(indexX - 1, indexY + 1));
						const rechsOnderActief = this.isActiveRaster(new RemoveRaster(indexX + 1, indexY + 1));
						if (bovenliggendActief === false) {
							rasterSurface += spanX.value * overhang.size;
						}
						if (onderliggendActief === false) {
							rasterSurface += spanX.value * overhang.size;
						}
						if (rechtsActief === false) {
							let length = spanY.value;
							if (rechtsBovenActief === true) {
								length -= overhang.size;
							}
							if (rechsOnderActief === true) {
								length -= overhang.size;
							}
							rasterSurface += length * overhang.size;
						}
						if (linksActief === false) {
							let length = spanY.value;
							if (linksBovenActief === true) {
								length -= overhang.size;
							}
							if (linksOnderActief === true) {
								length -= overhang.size;
							}
							rasterSurface += length * overhang.size;
						}
						if (linksBovenActief === false && bovenliggendActief === false && linksActief === false) {
							rasterSurface += overhang.size * overhang.size;
						}
						if (rechtsBovenActief === false && bovenliggendActief === false && rechtsActief === false) {
							rasterSurface += overhang.size * overhang.size;
						}
						if (linksOnderActief === false && onderliggendActief === false && linksActief === false) {
							rasterSurface += overhang.size * overhang.size;
						}
						if (rechsOnderActief === false && onderliggendActief === false && rechtsActief === false) {
							rasterSurface += overhang.size * overhang.size;
						}
					}
					surface += rasterSurface;
				}
			});
		});

		this.usedSurface.forEach((surf) => {
			let m2 = surf.width * surf.depth;
			if (!isNaN(m2)) {
				surface -= m2;
			}
		});

		return surface / 1000000; // van mm3 naar m3
	}
	calculateSurface(calculateOverhang = false) {
		this.floor.width = this.raster.spansX.getSize();
		this.floor.length = this.raster.spansY.getSize();
		this.surface = this.getSurface();
	}
	select(parameters, canvas) {
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].select === 'function') {
				this[object].select(parameters, canvas);
			}
		});
	}

	calculateMainBeamAmount(direction, mainBeamHotFormed, doubleDouble, profiles) {
		this.raster.spansX.getSpans().forEach((spanX, indexX) => {
			this.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (direction === Profiles.MB_HORIZONTAL) {
					if (this.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
						// als raster actief
						if (mainBeamHotFormed === true) {
							// als hotFormed dan kan doubledouble niet en voegen we lengte aan mb toe (2 per raster maar bij aanliggende rasters maar 1).
							profiles.addAmountMainBeam(spanX.value, 1, spanX);
							if (this.isActiveRaster(new RemoveRaster(indexX, indexY + 1))) {
								// als aanliggend raster niet actief dan extra meanbeam lengte van huidige raster
								this.addAmountMainBeam(spanX.value, 1, spanX);
							}
						} else {
							// niet hotFormed
							profiles.addAmountMainBeam(spanX.value, doubleDouble ? 4 : 2, spanX); // voeg lengte aan mb toe. Bij doubledouble dubbel (x4 dus) en bij enkel 2x (2 per raster)
						}
					}
				} else {
					if (this.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
						// als raster actief
						if (mainBeamHotFormed === true) {
							// als hotFormed dan kan doubledouble niet en voegen we lengte aan mb toe (2 per raster maar bij aanliggende rasters maar 1).
							profiles.addAmountMainBeam(spanY.value, 1, spanY);
							if (this.isActiveRaster(new RemoveRaster(indexX, indexY + 1))) {
								// als aanliggend raster niet actief dan extra meanbeam lengte van huidige raster
								this.addAmountMainBeam(spanY.value, 1, spanY);
							}
						} else {
							// niet hotFormed
							profiles.addAmountMainBeam(spanY.value, doubleDouble ? 4 : 2, spanY); // voeg lengte aan mb toe. Bij doubledouble dubbel (x4 dus) en bij enkel 2x (2 per raster)
						}
					}
				}
			});
		});
	}

	calculateChildBeamAmount(direction, profiles, centerToCenter) {
		let mainBeamSpans = null;
		if (direction === Profiles.MB_HORIZONTAL) {
			mainBeamSpans = this.raster.spansX.getSpans();
			// calculate childBeam
			this.raster.spansY.getSpans().forEach((spanY, indexY) => {
				const childBeamCount = Math.ceil(this.raster.spansX.getSize() / centerToCenter) + 1; // bereken aantal kinderbalken +1 beginbalk
				// omdat nog niet bekend is of ze in een actief raster vallen. Doorloop het aantal kinderbalken en kijk of ze in een actief raster vallen. Dan pas meetellen
				for (let childBeam = 0; childBeam < childBeamCount; childBeam++) {
					const cbPosition = childBeam * centerToCenter;
					const rx = this.raster.spansX.getRaster(cbPosition);
					if (this.isActiveRaster(new RemoveRaster(rx, indexY)) === true) {
						profiles.addAmountChildBeam(spanY.value, 1, spanY, mainBeamSpans[rx]); // Nog niet goed. Zou articlegroup moeten zijn maar wordt nog niet meegegeven
					}
				}
			});
			// als naastliggende rasters niet actief extra kinderbalken toevoegen
			this.raster.spansX.getSpans().forEach((spanX, indexX) => {
				this.raster.spansY.getSpans().forEach((spanY, indexY) => {
					if (this.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
						// huidige raster actief

						if (indexX > 0 && this.isActiveRaster(new RemoveRaster(indexX - 1, indexY)) === false) {
							// als voorgaande niet actief altijd extra profiel aan begin toevoegen. Namelijk 2 opties: 1. profiel valt precies op de maat (hoort dus bij vorige raster)
							// of valt over de maat. Nu geen rekening met offset maar aan begin is dan geen profiel
							profiles.addAmountChildBeam(spanY.value, 1, spanY, mainBeamSpans[indexX]);
						}

						if (indexX + 1 === this.raster.spansX.length && this.raster.getSizeX(indexX) % centerToCenter > 0) {
							// als bij laatste raster nog lengte rasters is niet deelbaar door centerToCenter over is dan extra profiel
							profiles.addAmountChildBeam(spanY.value, 1, spanY, mainBeamSpans[indexX]);
						}
						if (indexX + 1 < this.raster.spansX.length && this.isActiveRaster(new RemoveRaster(indexX + 1, indexY)) === false && this.raster.getSizeX(indexX) % centerToCenter > 0) {
							// als nog niet laatste en volgende raster is niet actief en lengte inclusief huidige raster is niet deelbaar door centerToCenter dan extra profiel

							profiles.addAmountChildBeam(spanY.value, 1, spanY, mainBeamSpans[indexX]);
						}
					}
				});
			});
		} else {
			mainBeamSpans = this.raster.spansY.getSpans();
			this.raster.spansX.getSpans().forEach(
				function (spanX, indexX) {
					const childBeamCount = Math.ceil(this.raster.spansY.getSize() / centerToCenter) + 1; // bereken aantal kinderbalken +1 beginbalk
					// omdat nog niet bekend is of ze in een actief raster vallen. Doorloop het aantal kinderbalken en kijk of ze in een actief raster vallen. Dan pas meetellen
					for (let childBeam = 0; childBeam < childBeamCount; childBeam++) {
						const cbPosition = childBeam * centerToCenter;
						const ry = this.raster.spansY.getRaster(cbPosition);
						if (this.isActiveRaster(new RemoveRaster(indexX, ry)) === true) {
							profiles.addAmountChildBeam(spanX.value, 1, spanX, mainBeamSpans[ry]);
						}
					}
				}.bind(this),
			);

			// als onder/bovenliggende rasters niet actief extra kinderbalken toevoegen
			this.raster.spansX.getSpans().forEach(
				function (spanX, indexX) {
					this.raster.spansY.getSpans().forEach(
						function (spanY, indexY) {
							if (this.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
								if (indexY > 0 && this.isActiveRaster(new RemoveRaster(indexX, indexY - 1)) === false) {
									// als voorgaande niet actief altijd extra profiel aan begin toevoegen. Namelijk 2 opties: 1. profiel valt precies op de maat (hoort dus bij vorige raster)
									// of valt over de maat. Nu geen rekening met offset maar aan begin is dan geen profiel
									profiles.addAmountChildBeam(spanX.value, 1, spanX, mainBeamSpans[indexY]);
								}

								if (indexY + 1 === this.raster.spansY.length && this.raster.getSizeY(indexY) % centerToCenter > 0) {
									// als bij laatste raster nog lengte rasters is niet deelbaar door centerToCenter over is dan extra profiel
									profiles.addAmountChildBeam(spanX.value, 1, spanX, mainBeamSpans[indexY]);
								}
								if (indexY + 1 < this.raster.spansY.length && this.isActiveRaster(new RemoveRaster(indexX, indexY + 1)) === false && this.raster.getSizeY(indexY) % centerToCenter > 0) {
									// als nog niet laatste en volgende raster is niet actief en lengte inclusief huidige raster is niet deelbaar door centerToCenter dan extra profiel

									profiles.addAmountChildBeam(spanX.value, 1, spanX, mainBeamSpans[indexY]);
								}
							}
						}.bind(this),
					);
				}.bind(this),
			);
		}
	}

	isActiveRaster(raster) {
		if (raster.x < 0 || raster.y < 0 || raster.x > this.raster.spansX.getSpans().length - 1 || raster.y > this.raster.spansY.getSpans().length - 1) {
			return false; // valt buiten beschikbare rasterrange
		}
		return this.removeRasters.isActive(raster);
	}
	checkCollisions(boundaries, self) {
		if (typeof boundaries === 'undefined' || boundaries === null) {
			return;
		}
		let collisions = false;
		let errorResult = [];
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].collisions === 'function') {
				let collisionCheck = this[object].collisions(boundaries, self);
				if (collisionCheck.result === true) {
					collisions = true;
					collisionCheck.errors.forEach((error) => {
						errorResult.push(error);
					});
				}
			}
		});
		let columnCheck = Configuration.CURRENT.columns.collisions(boundaries, self);
		if (columnCheck.result === true) {
			collisions = true;
			columnCheck.errors.forEach((error) => {
				errorResult.push(error);
			});
		}
		let buildingColumnCheck = Configuration.CURRENT.buildingColumns.collisions(boundaries, self);
		if (buildingColumnCheck.result === true) {
			collisions = true;
			buildingColumnCheck.errors.forEach((error) => {
				errorResult.push(error);
			});
		}
		return { result: collisions, errors: errorResult };
	}
	collisionCheck() {
		Object.keys(this).forEach((object, index) => {
			// tijdelijke oplossing. toevoegen raster om die uit te sluiten omdat die ook via de actieve etage getekend wordt
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].collisionCheck === 'function') {
				this[object].collisionCheck();
			}
		});
	}
	createDrawObjects(canvas, params, accessoriesType) {
		let regenerate = false;
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].addDrawObjects === 'function' && object !== 'canvas') {
				let result = this[object].addDrawObjects(canvas, params);
				if (typeof result !== 'undefined' && result !== null) {
					if (result.regenerate === true) {
						regenerate = true;
					}
				}
			}
		});

		const accessories = this[accessoriesType];
		if (typeof accessories !== 'undefined' && typeof accessories.addPossiblePositions === 'function') {
			let result = accessories.addPossiblePositions(canvas, params);
			if (typeof result !== 'undefined' && result !== null) {
				if (result.regenerate === true) {
					regenerate = true;
				}
			}
		}
		return { regenerate: regenerate };
	}

	hasErrors() {
		let hasErrors = 0;
		Object.keys(this).forEach((object, index) => {
			// Loop over de errors per etage object.
			if (this[object] !== null && typeof this[object] === 'object') {
				if (typeof this[object].hasErrors === 'function') {
					let objectHasErrors = this[object].hasErrors();
					if (objectHasErrors === true) {
						hasErrors++;
					}
				} else if (this[object].hasErrors !== 'undefined' && typeof this[object].hasErrors === 'boolean' && typeof this[object] === 'object') {
					let objectHasErrors = this[object].hasErrors;
					if (objectHasErrors === true) {
						hasErrors++;
					}
				}
			}
		});
		return hasErrors > 0;
	}
	getErrors() {
		let errors = new Errors();
		Object.keys(this).forEach((object, index) => {
			// Loop over de errors per etage object.

			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].getErrors === 'function') {
				let objectErrors = this[object].getErrors();
				if (typeof objectErrors !== 'undefined' && objectErrors !== null) {
					if (typeof objectErrors.getAll === 'function') {
						objectErrors.getAll().forEach((error) => {
							errors.push(error);
						});
					} else {
						errors.push(objectErrors);
					}
				}
			}
		});

		return errors;
	}

	addDrawObjects3d(canvas3D, etage, raster, posY, etageIndex) {
		Object.keys(this).forEach((object, index) => {
			if (
				this[object] !== null &&
				typeof this[object] === 'object' &&
				this[object].objectName3d !== null &&
				this[object].objectName3d !== 'undefined' &&
				typeof this[object].addDrawObjects3d === 'function'
			) {
				this[object].addDrawObjects3d(Canvas3D.CURRENT, etage, raster, posY, etageIndex);
			}
		});
	}
	getSpecifications() {
		return { type: this.type, height: this.getHeight(true), freeHeight: this.getHeight(false), packetHeight: this.getPacketHeight(), active: this.active, surface: this.surface };
	}
	create3DAssets(canvas3D, etageIndex) {
		Object.keys(this).forEach((object, index) => {
			if (
				this[object] !== null &&
				typeof this[object] === 'object' &&
				this[object].objectName3d !== null &&
				this[object].objectName3d !== 'undefined' &&
				typeof this[object].create3DAssets === 'function'
			) {
				this[object].create3DAssets(Canvas3D.CURRENT, etageIndex);
			}
		});
	}
}

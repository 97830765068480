import { Data } from './data';
import * as Sentry from '@sentry/browser';
export class RalColors extends Data {
	objectName = 'RalColors';
	static CURRENT = null;
	url = 'RalColors';
	searchRalColors = [];

	get ralColors() {
		return this.ralColors;
	}

	set ralColors(value) {}

	colorRanges = [];
	ralColors = [];

	allRalColors() {
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			this.list();
			return [];
		}
		return this.ralColors;
	}
	unique(value, index, self) {
		return self.indexOf(value.range) === index;
	}
	colorRange() {
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			this.list();
		}
		this._list.list.forEach((item) => {
			let isInArray = this.colorRanges.filter(function (range) {
				return range.type === item.range;
			});
			if (isInArray.length === 0) {
				this.colorRanges.push({ type: item.range });
			}
		});
		return this.colorRanges;
	}
	colorGroup(rangeName) {
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			return this.list();
		}
		return this._list.list.filter((x) => x.range === rangeName);
	}
	hexColor(ral) {
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			this.list();
		}
		return this._list.list.filter((x) => x.ral === ral);
	}

	searchRalColor(data, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof urlExtension === 'undefined' || urlExtension === null) {
			urlExtension = '?searchText=' + data;
		}
		let url = this.baseUrl + this.url + urlExtension;

		this.store.dataAccess
			.get(url)
			.then((result) => {
				this.searchRalColors = result;
				if (typeof thenCallBack === 'function') {
					thenCallBack();
				}
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
				if (typeof catchCallBack === 'function') {
					catchCallBack(error);
				}
			});
	}
}

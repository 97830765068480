import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { HandRail3D } from '../draw3d/handrail/handRail3D';
import { Canvas3D } from '../draw3d/Canvas3D';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { HandRail } from './handRail';
import { RemoveRaster } from './removeRaster';
import { Configuration } from './configuration';
import { SelectionListOption } from './selectionListOption';
import { LocalAsset3D } from '../draw3d/assets/LocalAsset3D';
import {Store} from "@/data/store";
// import { FloorDeflections } from '../data/floorDeflections';
// import { PalletGates } from './palletGates';
export class HandRails {
	objectName = 'HandRails';
	handRails = [];
	etageId = '';
	automaticOptions = [
		new SelectionListOption(HandRails.TOP, '', require('~/assets/top.png'), null, this.toggleAutomaticOption.bind(this)),
		new SelectionListOption(HandRails.BOTTOM, '', require('~/assets/bottom.png'), null, this.toggleAutomaticOption.bind(this)),
		new SelectionListOption(HandRails.LEFT, '', require('~/assets/left.png'), null, this.toggleAutomaticOption.bind(this)),
		new SelectionListOption(HandRails.RIGHT, '', require('~/assets/right.png'), null, this.toggleAutomaticOption.bind(this)),
	];

	static LEFT = 1;
	static TOP = 2;
	static RIGHT = 3;
	static BOTTOM = 4;
	static COLORS = { handRails: '#4d4d4d', possible: '#6ba3ff', added: '#10821a', mouseMove: '#00286d', addedMouseMove: '#0f6d01' };
	static drawOffset = { x: 3, y: 3 };

	lengthHandRail = 0;
	mouseMoveActive = null;
	mousePriority = 10;
	mouseAreaOffset = { x: 10, y: 10 };

	length() {
		return this.handRails.length;
	}
	afterReconstruct() {
		this.automaticOptions.forEach((option) => {
			option.setReferences({ onToggle: this.toggleAutomaticOption.bind(this) });
		});
	}
	amount() {
		return this.lengthHandRail;
	}
	calculateAmount(params) {
		this.lengthHandRail = 0;

		let etage = Configuration.CURRENT.etages.getById(this.etageId);

		if (etage == null) {
			etage = Configuration.CURRENT.etages.activeEtage();
		}

		this.handRails.forEach((handRail, item) => {
			this.lengthHandRail += handRail.length(etage);
		});

		this.lengthHandRail += this.stairsInFloorLength(etage);
		this.lengthHandRail = Math.round(this.lengthHandRail);
	}

	stairsInFloorLength(etage) {
		let length = 0;
		if (etage.stairs.stairs.length > 0) {
			etage.stairs.stairs.forEach((stair, index) => {
				//dit moet eigenlijk naar stair verplaatst worden
				if (stair.objectName === 'StairInFloor') {
					let stairDirectionIsHorizontal = etage.stairs.directionIsHorizontal(stair);
					let placementAgainstEgde = etage.stairs.positionAgainstEdge(stair);
					if (stairDirectionIsHorizontal) {
						if (placementAgainstEgde) {
							length += stair.stairWell.width * 2;
						} else {
							length += stair.stairWell.width * 2;
							length += stair.stairWell.depth;
						}
					}
					if (!stairDirectionIsHorizontal) {
						if (placementAgainstEgde) {
							length += stair.stairWell.depth * 2;
						} else {
							length += stair.stairWell.width;
							length += stair.stairWell.depth * 2;
						}
					}
				}
			});
		}

		// afronden naar 0 decimalen. + zorgt ervoor dat het weer een getal wordt
		return +length.toFixed(0);
	}

	getAmount() {
		//edgeTrim: this.selected.edgeTrim,
		let edgeTrim = Configuration.CURRENT.finish.edgeTrim;
		let amount = { HandRails: [] };
		if (this.lengthHandRail > 0) {
			amount = {
				HandRails: [{ length: this.lengthHandRail / 1000, id: Store.CURRENT.handRails.getItemByArticleOid(Configuration.CURRENT.handRailType)?.oid, edgeTrim: edgeTrim }],
			};
		}
		return amount;
	}
	toggleAutomaticOption(option, startRasterChange = true) {
		const activeEtage = Configuration.CURRENT.etages.activeEtage();

		switch (option.id) {
			case HandRails.TOP:
				activeEtage.raster.spansX.getSpans().forEach((spanX, indexX) => {
					let gevonden = false;
					activeEtage.raster.spansY.getSpans().forEach((spanY, indexY) => {
						const isRasterActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY));

						if (gevonden === false && isRasterActive === true) {
							gevonden = true;
							if (option.selected === true) {
								this.pushUnique(indexX, indexY, HandRails.TOP, startRasterChange);
							} else {
								this.remove(indexX, indexY, HandRails.TOP, startRasterChange);
							}
						}
						if (gevonden === false && isRasterActive === false) {
							const rasterLeftActive = activeEtage.isActiveRaster(new RemoveRaster(indexX - 1, indexY));
							const rasterRightActive = activeEtage.isActiveRaster(new RemoveRaster(indexX + 1, indexY));
							if (rasterLeftActive === true) {
								if (option.selected === true) {
									this.pushUnique(indexX - 1, indexY, HandRails.RIGHT, startRasterChange);
								} else {
									this.remove(indexX - 1, indexY, HandRails.RIGHT, startRasterChange);
								}
							}
							if (rasterRightActive === true) {
								if (option.selected === true) {
									this.pushUnique(indexX + 1, indexY, HandRails.LEFT, startRasterChange);
								} else {
									this.remove(indexX + 1, indexY, HandRails.LEFT, startRasterChange);
								}
							}
						}
					});
				});
				break;
			case HandRails.BOTTOM:
				const spansY = activeEtage.raster.spansY.getSpans();

				activeEtage.raster.spansX.getSpans().forEach((spanX, indexX) => {
					let gevonden = false;
					for (let indexY = spansY.length - 1; indexY >= 0; indexY--) {
						const isRasterActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY));

						if (gevonden === false && isRasterActive === true) {
							gevonden = true;
							if (option.selected === true) {
								this.pushUnique(indexX, indexY, HandRails.BOTTOM, startRasterChange);
							} else {
								this.remove(indexX, indexY, HandRails.BOTTOM, startRasterChange);
							}
						}
						if (gevonden === false && isRasterActive === false) {
							const rasterLeftActive = activeEtage.isActiveRaster(new RemoveRaster(indexX - 1, indexY));
							const rasterRightActive = activeEtage.isActiveRaster(new RemoveRaster(indexX + 1, indexY));
							if (rasterLeftActive === true) {
								if (option.selected === true) {
									this.pushUnique(indexX - 1, indexY, HandRails.RIGHT, startRasterChange);
								} else {
									this.remove(indexX - 1, indexY, HandRails.RIGHT, startRasterChange);
								}
							}
							if (rasterRightActive === true) {
								if (option.selected === true) {
									this.pushUnique(indexX + 1, indexY, HandRails.LEFT, startRasterChange);
								} else {
									this.remove(indexX + 1, indexY, HandRails.LEFT, startRasterChange);
								}
							}
						}
					}
				});
				break;
			case HandRails.LEFT:
				activeEtage.raster.spansY.getSpans().forEach((spanY, indexY) => {
					let gevonden = false;
					activeEtage.raster.spansX.getSpans().forEach((spanX, indexX) => {
						const isRasterActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY));

						if (gevonden === false && isRasterActive === true) {
							gevonden = true;
							if (option.selected === true) {
								this.pushUnique(indexX, indexY, HandRails.LEFT, startRasterChange);
							} else {
								this.remove(indexX, indexY, HandRails.LEFT, startRasterChange);
							}
						}
						if (gevonden === false && isRasterActive === false) {
							const rasterTopActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY - 1));
							const rasterBottomActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY + 1));
							if (rasterTopActive === true) {
								if (option.selected === true) {
									this.pushUnique(indexX, indexY - 1, HandRails.BOTTOM, startRasterChange);
								} else {
									this.remove(indexX, indexY - 1, HandRails.BOTTOM, startRasterChange);
								}
							}
							if (rasterBottomActive === true) {
								if (option.selected === true) {
									this.pushUnique(indexX, indexY + 1, HandRails.TOP, startRasterChange);
								} else {
									this.remove(indexX, indexY + 1, HandRails.TOP, startRasterChange);
								}
							}
						}
					});
				});
				break;
			case HandRails.RIGHT:
				const spansX = activeEtage.raster.spansX.getSpans();
				activeEtage.raster.spansY.getSpans().forEach((spanY, indexY) => {
					let gevonden = false;
					for (let indexX = spansX.length - 1; indexX >= 0; indexX--) {
						const isRasterActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY));

						if (gevonden === false && isRasterActive === true) {
							gevonden = true;
							if (option.selected === true) {
								this.pushUnique(indexX, indexY, HandRails.RIGHT, startRasterChange);
							} else {
								this.remove(indexX, indexY, HandRails.RIGHT, startRasterChange);
							}
						}
						if (gevonden === false && isRasterActive === false) {
							const rasterTopActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY - 1));
							const rasterBottomActive = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY + 1));
							if (rasterTopActive === true) {
								if (option.selected === true) {
									this.pushUnique(indexX, indexY - 1, HandRails.BOTTOM, startRasterChange);
								} else {
									this.remove(indexX, indexY - 1, HandRails.BOTTOM, startRasterChange);
								}
							}
							if (rasterBottomActive === true) {
								if (option.selected === true) {
									this.pushUnique(indexX, indexY + 1, HandRails.TOP, startRasterChange);
								} else {
									this.remove(indexX, indexY + 1, HandRails.TOP, startRasterChange);
								}
							}
						}
					}
				});
				break;
		}
		this.calculateAmount();
	}

	constructor(onChange) {
		this._onChange = onChange;
	}
	setEtageId(etageId) {
		this.etageId = etageId;
	}
	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
		this.automaticOptions.forEach((option) => {
			if (typeof option.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				option.removeReferences();
			}
		});
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	onMouseDown(evt, object, canvas, params) {
		return { stopPropagation: true };
	}
	onMouseMove(evt, object, canvas, params) {
		if (this.findIndex(object.objectParams.x, object.objectParams.y, object.objectParams.position) > -1) {
			object.lineColor = HandRails.COLORS.addedMouseMove;
		} else {
			object.lineColor = HandRails.COLORS.mouseMove;
		}

		this.mouseMoveActive = new HandRail(object.objectParams.x, object.objectParams.y, object.objectParams.position);

		return { stopPropagation: true };
	}
	onMouseUp(evt, object, canvas, params) {
		return { stopPropagation: true };
	}
	onMouseDrag(evt, object, canvas, params) {
		return { stopPropagation: true };
	}
	onMouseLeave(evt, object, canvas, params) {
		if (this.findIndex(object.objectParams.x, object.objectParams.y, object.objectParams.position) > -1) {
			object.lineColor = HandRails.COLORS.added;
		} else {
			object.lineColor = HandRails.COLORS.possible;
		}

		this.mouseMoveActive = null;
		return { stopPropagation: true };
	}
	onRasterChanged(params) {
		this.automaticOptions.forEach((automaticOption) => {
			if (automaticOption.selected === true) {
				this.toggleAutomaticOption(automaticOption, false);
			}
		});

		let etage = Configuration.CURRENT.etages.getById(this.etageId);
		this.handRails.forEach((handRail, index) => {
			handRail.onRasterChanged(params, etage);
		});
	}
	onClick(evt, object, canvas, params) {
		// geen check op aanwezigheid schoorkolom omdat hij hem ook al niet tekent. Mogelijk wel hier of in push check inbouwen

		this.togglePush(object.objectParams.x, object.objectParams.y, object.objectParams.position);
		this.calculateAmount();
		this.onChange();

		return { stopPropagation: true };
	}

	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}

	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		if (raster.x > -1) {
			if (raster.x === drawObject.objectParams.x) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
			}
			if (raster.x - 1 === drawObject.objectParams.x && (drawObject.objectParams.position === HandRails.TOP || drawObject.objectParams.position === HandRails.BOTTOM)) {
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			}
		} else if (raster.y > -1) {
			if (raster.y === drawObject.objectParams.y) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
			}
			if (raster.y - 1 === drawObject.objectParams.y && (drawObject.objectParams.position === HandRails.LEFT || drawObject.objectParams.position === HandRails.RIGHT)) {
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			}
		}
	}
	findIndex(rasterX, rasterY, position) {
		let findIndex = -1;

		this.handRails.forEach((handRail, index) => {
			if (handRail.equals(rasterX, rasterY, position) === true) {
				findIndex = index;
			}
		});
		return findIndex;
	}
	togglePush(rasterX, rasterY, position) {
		const index = this.findIndex(rasterX, rasterY, position);

		if (index === -1) {
			this.push(rasterX, rasterY, position);
		} else {
			this.handRails.splice(index, 1);
		}
	}
	remove(rasterX, rasterY, position, startRasterChange = true) {
		const index = this.findIndex(rasterX, rasterY, position);

		if (index > -1) {
			this.handRails.splice(index, 1);
		}
		if (startRasterChange === true) {
			this.onChange();
		}
	}
	pushUnique(rasterX, rasterY, position, startRasterChange = true) {
		const index = this.findIndex(rasterX, rasterY, position);

		if (index === -1) {
			this.push(rasterX, rasterY, position);
		}
		if (startRasterChange === true) {
			this.onChange();
		}
	}
	push(rasterX, rasterY, position) {
		this.handRails.push(new HandRail(rasterX, rasterY, position, this.etageId));
	}
	addDrawObjects(canvas, params) {
		let etage = Configuration.CURRENT.etages.getById(this.etageId);
		if (etage == null) {
			etage = Configuration.CURRENT.etages.activeEtage();
		}
		this.handRails.forEach((handRail, index) => {
			handRail.addDrawObjects(canvas, params, this.createHandRail.bind(this), etage); // Meegeven om code om bracecolumn te maken maar 1x te hoeven maken/wijzigen
		});
	}
	create3DAssets(canvas3D) {
		// Plaatjes van houders zitten nog niet in ERP, dus lokale gebruiken.

		canvas3D.addAsset(
			new LocalAsset3D('handrail_capplate_middle', null, {
				fallBackData: { depth: 40, height: 10, width: 120 },
			}),
		);

		// Kickedge holder for against the pole
		canvas3D.addAsset(
			new LocalAsset3D('handrail_middleplate_holder', null, {
				fallBackData: { height: 60, depth: 25, width: 40 },
			}),
		);

		// Kickedge holder
		canvas3D.addAsset(
			new LocalAsset3D('handrail_kickedge_holder_left', null, {
				fallBackData: { width: 80, height: 140, depth: 40 },
			}),
		);
		// Kickedge holder
		canvas3D.addAsset(
			new LocalAsset3D('handrail_kickedge_holder_right', null, {
				fallBackData: { width: 80, height: 140, depth: 40 },
			}),
		);
		// Kleine houders die de topplate aan het paaltje bevestigen
		canvas3D.addAsset(
			new LocalAsset3D('handrail_plate_holder', null, {
				fallBackData: { height: 30, depth: 50, width: 120 },
			}),
		);

		// Handrail kindvriendelijk houder kindvriendelijke paaltjes boven en onder.
		canvas3D.addAsset(
			new LocalAsset3D('childfriendly_pole_holder', null, {
				fallBackData: { height: 60, depth: 30, width: 120 },
			}),
		);
	}

	addHandRail3D(canvas3d, params) {
		if (params.handRailPosition.startX === null || params.handRailPosition.endX === null || params.handRailPosition.startY === null || params.handRailPosition.endY === null) {
			return;
		}
		let handRailPosition = params.handRailPosition;

		//* * Interuptions inbouwen
		let lengthInterruptions = this.getInteruptions3D(params);
		lengthInterruptions.sort((a, b) => {
			// sorteren in juiste volgorde van links naar rechts
			if (Math.round(a.endY - a.startY) > Math.round(a.endX - a.startX)) {
				if (a.startY < b.startY) {
					return -1;
				}
				if (a.startY > b.startY) {
					return 1;
				}
				return 0;
			} else {
				if (a.startX < b.startX) {
					return -1;
				}
				if (a.startX > b.startX) {
					return 1;
				}
				return 0;
			}
		});

		// TODO: Childfriendly op een andere manier checken, in data -> childFriendly(id)
		// Op basis van die waarde dan true of false meegeven.
		// Dit is voor nu een hotfix..
		let paramPreset = {
			childFriendly: Store.CURRENT.handRails.isChildFriendly(Configuration.CURRENT.handRailType),
			handRailType: Configuration.CURRENT.handRailType,
		};

		if (lengthInterruptions.length > 0) {
			switch (params.position) {
				case HandRails.TOP:
				case HandRails.BOTTOM:
					if (handRailPosition.startX.value < lengthInterruptions[0].startX) {
						// voor de interrupties nog ruimte dan tekenen
						canvas3d.addDrawObject(
							new HandRail3D(
								handRailPosition.startX.value,
								params.yPosition,
								handRailPosition.startY.value,
								paramPreset,
								params.position,
								lengthInterruptions[0].startX - handRailPosition.startX.value,
							),
							Canvas3D.TYPE_HANDRAIL,
						);
					}
					if (lengthInterruptions.length > 1) {
						// meer dan 1 tussen interuptions checken
						for (let i = 0; i < lengthInterruptions.length - 1; i++) {
							if (lengthInterruptions[i].endX < lengthInterruptions[i + 1].startX) {
								canvas3d.addDrawObject(
									new HandRail3D(
										lengthInterruptions[i].endX,
										params.yPosition,
										handRailPosition.startY.value,
										paramPreset,
										params.position,
										lengthInterruptions[i + 1].startX - lengthInterruptions[i].endX,
									),
									Canvas3D.TYPE_HANDRAIL,
								);
							}
						}
					}
					// Na de laatste interuption
					if (lengthInterruptions[lengthInterruptions.length - 1].endX < handRailPosition.endX.value) {
						canvas3d.addDrawObject(
							new HandRail3D(
								lengthInterruptions[lengthInterruptions.length - 1].endX,
								params.yPosition,
								handRailPosition.startY.value,
								paramPreset,
								params.position,
								handRailPosition.endX.value - lengthInterruptions[lengthInterruptions.length - 1].endX,
							),
							Canvas3D.TYPE_HANDRAIL,
						);
					}
					break;
				case HandRails.RIGHT:
				case HandRails.LEFT:
					if (handRailPosition.startY.value < lengthInterruptions[0].startY) {
						canvas3d.addDrawObject(
							new HandRail3D(
								handRailPosition.startX.value,
								params.yPosition,
								handRailPosition.startY.value,
								paramPreset,
								params.position,
								lengthInterruptions[0].startY - handRailPosition.startY.value,
							),
							Canvas3D.TYPE_HANDRAIL,
						);
					}

					if (lengthInterruptions.length > 1) {
						for (let i = 0; i < lengthInterruptions.length - 1; i++) {
							if (lengthInterruptions[i].endY < lengthInterruptions[i + 1].startY) {
								canvas3d.addDrawObject(
									new HandRail3D(
										handRailPosition.startX.value,
										params.yPosition,
										lengthInterruptions[i].endY,
										paramPreset,
										params.position,
										lengthInterruptions[i + 1].startY - lengthInterruptions[i].endY,
									),
									Canvas3D.TYPE_HANDRAIL,
								);
							}
						}
					}
					if (lengthInterruptions[lengthInterruptions.length - 1].endY < handRailPosition.endY.value) {
						canvas3d.addDrawObject(
							new HandRail3D(
								handRailPosition.startX.value,
								params.yPosition,
								lengthInterruptions[lengthInterruptions.length - 1].endY,
								paramPreset,
								params.position,
								handRailPosition.endY.value - lengthInterruptions[lengthInterruptions.length - 1].endY,
							),
							Canvas3D.TYPE_HANDRAIL,
						);
					}
					break;
			}
		} else {
			if (params.position === HandRails.TOP || params.position === HandRails.BOTTOM) {
				canvas3d.addDrawObject(
					new HandRail3D(handRailPosition.startX.value, params.yPosition, handRailPosition.startY.value, paramPreset, params.position, handRailPosition.endX.value - handRailPosition.startX.value),
					Canvas3D.TYPE_HANDRAIL,
				);
			}
			if (params.position === HandRails.LEFT || params.position === HandRails.RIGHT) {
				canvas3d.addDrawObject(
					new HandRail3D(handRailPosition.startX.value, params.yPosition, handRailPosition.startY.value, paramPreset, params.position, handRailPosition.endY.value - handRailPosition.startY.value),
					Canvas3D.TYPE_HANDRAIL,
				);
			}
		}
	}
	getInteruptions3D(params) {
		let lengthInterruptions = [];
		let handRailPosition = params.handRailPosition;
		params.etage.stairs.findByHandrailPosition(handRailPosition).forEach((s) => {
			let edgePosition = s.getEdgePosition(handRailPosition);
			lengthInterruptions.push(edgePosition);
		});

		params.etage.palletGates.findByHandrailPosition(handRailPosition).forEach((s) => {
			let edgePosition = s.getEdgePosition(handRailPosition);
			lengthInterruptions.push(edgePosition);
		});

		return lengthInterruptions;
	}

	addDrawObjects3d(canvas3d, etage, raster, posY, etageIndex) {
		posY += etage.getHeight(true);

		// start met lege data
		let handRailTop = { startX: null, endX: null, startY: null, endY: null };
		let handRailBottom = { startX: null, endX: null, startY: null, endY: null };
		let handRailLeft = { startX: null, endX: null, startY: null, endY: null };
		let handRailRight = { startX: null, endX: null, startY: null, endY: null };
		// horizontaal kijken naar aansluitende handrails top en bottom
		raster.spansY.getSpans().forEach((spanY, indexY) => {
			raster.spansX.getSpans().forEach((spanX, indexX) => {
				// Opzoeken van handrail top en bottom op huidige raster.
				let currentRasterHandRailsTop = this.handRails.find((hr) => hr.rasterX === indexX && hr.rasterY === indexY && hr.position === HandRails.TOP && hr.active === true);
				let currentRasterHandRailsBottom = this.handRails.find((hr) => hr.rasterX === indexX && hr.rasterY === indexY && hr.position === HandRails.BOTTOM && hr.active === true);
				let overhangPositionRight = null;

				// Als huidige raster bovenkant handrail
				if (typeof currentRasterHandRailsTop !== 'undefined') {
					const topPosition = Configuration.CURRENT.overhang.getPosition(currentRasterHandRailsTop.rasterX, currentRasterHandRailsTop.rasterY, currentRasterHandRailsTop.position, etage);
					if (topPosition !== null) {
						// Als er nog geen start op deze as bekend was, dan de start bepalen.
						if (handRailTop.startX === null && handRailTop.startY === null) {
							handRailTop.startX = topPosition.startX.value;
							handRailTop.startY = topPosition.startY.value;
						}
						// Als er al een start bekend was van een vorig raster, en het raster erna heeft ook handrail aan dezelfde kant dan de eind waarde ook updaten.
						handRailTop.endX = topPosition.endX.value;
						handRailTop.endY = topPosition.endY.value;
					}
					// Bij top en bottom moeten we voor de eind positie checken op position Rechts.
					// Als deze niet null is dan heeft dit raster dus een overhang positie aan de rechterkant.
					// We nemen dan die waarde over als eindPositie van de handrail.
					overhangPositionRight = Configuration.CURRENT.overhang.getPosition(currentRasterHandRailsTop.rasterX, currentRasterHandRailsTop.rasterY, HandRails.RIGHT, etage);
					if (overhangPositionRight !== null) {
						handRailTop.endX = overhangPositionRight.endX.value;
					}
				}
				// Als van het huidige raster geen HR gevonden is en de waardes bestaan.
				// Dan had het vorige raster dus HR aan de bovenkant, dan wegschrijven.
				else if (handRailTop.startX !== null && handRailTop.startY !== null) {
					this.addHandRail3D(canvas3d, {
						position: HandRails.TOP,
						handRailPosition: {
							startX: new DrawValue(handRailTop.startX),
							endX: new DrawValue(handRailTop.endX),
							startY: new DrawValue(handRailTop.startY),
							endY: new DrawValue(handRailTop.endY),
						},
						yPosition: posY,
						etage: etage,
					});
					handRailTop = { startX: null, endX: null, startY: null, endY: null };
				}

				// Als huidige raster onderkant handrail
				if (typeof currentRasterHandRailsBottom !== 'undefined') {
					// Positie ophalen van het raster aan de onderkant.
					const bottomPosition = Configuration.CURRENT.overhang.getPosition(currentRasterHandRailsBottom.rasterX, currentRasterHandRailsBottom.rasterY, currentRasterHandRailsBottom.position, etage);
					if (bottomPosition !== null) {
						// Als er nog geen start op deze as bekend was, dan de start bepalen.
						if (handRailBottom.startX === null && handRailBottom.startY === null) {
							handRailBottom.startX = bottomPosition.startX.value;
							handRailBottom.startY = bottomPosition.startY.value;
						}
						// Als er al een start bekend was van een vorig raster, en het raster erna heeft ook handrail aan dezelfde kant dan de eind waarde ook updaten.
						handRailBottom.endX = bottomPosition.endX.value;
						handRailBottom.endY = bottomPosition.endY.value;
					}

					// Bij top en bottom moeten we voor de eind positie checken op position Rechts.
					// Als deze niet null is dan heeft dit raster dus een overhang positie aan de rechterkant.
					// We nemen dan die waarde over als eindPositie van de handrail.
					overhangPositionRight = Configuration.CURRENT.overhang.getPosition(currentRasterHandRailsBottom.rasterX, currentRasterHandRailsBottom.rasterY, HandRails.RIGHT, etage);
					if (overhangPositionRight !== null) {
						handRailBottom.endX = overhangPositionRight.endX.value;
					}
				}
				// Als van het huidige raster geen HR gevonden is en de waardes bestaan.
				// Dan had het vorige raster dus HR aan de onderkant, dan wegschrijven.
				else if (handRailBottom.startX !== null && handRailBottom.endX !== null && handRailBottom.startY !== null && handRailBottom.endY !== null) {
					this.addHandRail3D(canvas3d, {
						position: HandRails.BOTTOM,
						handRailPosition: {
							startX: new DrawValue(handRailBottom.startX),
							endX: new DrawValue(handRailBottom.endX),
							startY: new DrawValue(handRailBottom.startY),
							endY: new DrawValue(handRailBottom.endY),
						},
						yPosition: posY,
						etage: etage,
					});
					handRailBottom = { startX: null, endX: null, startY: null, endY: null };
				}
			});

			// kijk na de lus of er nog een stuk handrail is die dan ook opslaan
			if (handRailTop.startX !== null && handRailTop.endX !== null && handRailTop.startY !== null && handRailTop.endY !== null) {
				this.addHandRail3D(canvas3d, {
					position: HandRails.TOP,
					handRailPosition: {
						startX: new DrawValue(handRailTop.startX),
						endX: new DrawValue(handRailTop.endX),
						startY: new DrawValue(handRailTop.startY),
						endY: new DrawValue(handRailTop.endY),
					},
					yPosition: posY,
					etage: etage,
				});
				handRailTop = { startX: null, endX: null, startY: null, endY: null };
			}

			// kijk na de lus of er nog een stuk handrail is die dan ook opslaan
			if (handRailBottom.startX !== null && handRailBottom.endX !== null && handRailBottom.startY !== null && handRailBottom.endY !== null) {
				this.addHandRail3D(canvas3d, {
					position: HandRails.BOTTOM,
					handRailPosition: {
						startX: new DrawValue(handRailBottom.startX),
						endX: new DrawValue(handRailBottom.endX),
						startY: new DrawValue(handRailBottom.startY),
						endY: new DrawValue(handRailBottom.endY),
					},
					yPosition: posY,
					etage: etage,
				});
				handRailBottom = { startX: null, endX: null, startY: null, endY: null };
			}
		});

		raster.spansX.getSpans().forEach((spanX, indexX) => {
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				let currentRasterHandRailsLeft = this.handRails.find((hr) => hr.rasterX === indexX && hr.rasterY === indexY && hr.position === HandRails.LEFT && hr.active === true);
				let currentRasterHandRailsRight = this.handRails.find((hr) => hr.rasterX === indexX && hr.rasterY === indexY && hr.position === HandRails.RIGHT && hr.active === true);
				let overhangPositionBottom = null;

				// Als huidige raster handrail linkerkant.
				if (typeof currentRasterHandRailsLeft !== 'undefined') {
					const leftPosition = Configuration.CURRENT.overhang.getPosition(currentRasterHandRailsLeft.rasterX, currentRasterHandRailsLeft.rasterY, currentRasterHandRailsLeft.position, etage);
					if (leftPosition !== null) {
						// Als er nog geen start op deze as bekend was, dan de start bepalen.
						if (handRailLeft.startX === null && handRailLeft.startY === null) {
							handRailLeft.startX = leftPosition.startX.value;
							handRailLeft.startY = leftPosition.startY.value;
						}
						// Als er al een start bekend was van een vorig raster, en het raster erna heeft ook handrail aan dezelfde kant dan de eind waarde ook updaten.
						handRailLeft.endX = leftPosition.endX.value;
						handRailLeft.endY = leftPosition.endY.value;
					}
					// Bij left en right moeten we voor de eind positie checken op position bottom.
					// Als deze niet null is dan heeft dit raster dus een overhang positie aan de onderkant.
					// We nemen dan die waarde over als eindPositie van de handrail.
					overhangPositionBottom = Configuration.CURRENT.overhang.getPosition(currentRasterHandRailsLeft.rasterX, currentRasterHandRailsLeft.rasterY, HandRails.BOTTOM, etage);
					if (overhangPositionBottom !== null) {
						handRailLeft.endY = overhangPositionBottom.endY.value;
					}
				}
				// Als van het huidige raster geen HR gevonden is en de waardes bestaan.
				// Dan had het vorige raster dus HR aan de linkerkant, dan wegschrijven.
				else if (handRailLeft.startX !== null && handRailLeft.endX !== null && handRailLeft.startY !== null && handRailLeft.endY !== null) {
					this.addHandRail3D(canvas3d, {
						position: HandRails.LEFT,
						handRailPosition: {
							startX: new DrawValue(handRailLeft.startX),
							endX: new DrawValue(handRailLeft.endX),
							startY: new DrawValue(handRailLeft.startY),
							endY: new DrawValue(handRailLeft.endY),
						},
						yPosition: posY,
						overhang: Configuration.CURRENT.overhang.size,
						etage: etage,
					});
					handRailLeft = { startX: null, endX: null, startY: null, endY: null };
				}

				// Als huidige raster handrail rechterkant.
				if (typeof currentRasterHandRailsRight !== 'undefined') {
					const rightPosition = Configuration.CURRENT.overhang.getPosition(currentRasterHandRailsRight.rasterX, currentRasterHandRailsRight.rasterY, currentRasterHandRailsRight.position, etage);
					if (rightPosition !== null) {
						// Als er nog geen start op deze as bekend was, dan de start bepalen.
						if (handRailRight.startX === null && handRailRight.startY === null) {
							handRailRight.startX = rightPosition.startX.value;
							handRailRight.startY = rightPosition.startY.value;
						}
						// Als er al een start bekend was van een vorig raster, en het raster erna heeft ook handrail aan dezelfde kant dan de eind waarde ook updaten.
						handRailRight.endX = rightPosition.endX.value;
						handRailRight.endY = rightPosition.endY.value;
					}
					// Bij left en right moeten we voor de eind positie checken op position bottom.
					// Als deze niet null is dan heeft dit raster dus een overhang positie aan de onderkant.
					// We nemen dan die waarde over als eindPositie van de handrail.
					overhangPositionBottom = Configuration.CURRENT.overhang.getPosition(currentRasterHandRailsRight.rasterX, currentRasterHandRailsRight.rasterY, HandRails.BOTTOM, etage);
					if (overhangPositionBottom !== null) {
						handRailRight.endY = overhangPositionBottom.endY.value;
					}
				}
				// Als van het huidige raster geen HR gevonden is en de waardes bestaan.
				// Dan had het vorige raster dus HR aan de rechterkant, dan wegschrijven.
				else if (handRailRight.startX !== null && handRailRight.endX !== null && handRailRight.startY !== null && handRailRight.endY !== null) {
					this.addHandRail3D(canvas3d, {
						position: HandRails.RIGHT,
						handRailPosition: {
							startX: new DrawValue(handRailRight.startX),
							endX: new DrawValue(handRailRight.endX),
							startY: new DrawValue(handRailRight.startY),
							endY: new DrawValue(handRailRight.endY),
						},
						yPosition: posY,
						overhang: Configuration.CURRENT.overhang.size,
						etage: etage,
					});
					handRailRight = { startX: null, endX: null, startY: null, endY: null };
				}
			});
			// kijk na de lus of er nog een stuk handrail is die dan ook opslaan
			if (handRailLeft.startX !== null && handRailLeft.endX !== null && handRailLeft.startY !== null && handRailLeft.endY !== null) {
				this.addHandRail3D(canvas3d, {
					position: HandRails.LEFT,
					handRailPosition: {
						startX: new DrawValue(handRailLeft.startX),
						endX: new DrawValue(handRailLeft.endX),
						startY: new DrawValue(handRailLeft.startY),
						endY: new DrawValue(handRailLeft.endY),
					},
					yPosition: posY,
					overhang: Configuration.CURRENT.overhang.size,
					etage: etage,
				});
				handRailLeft = { startX: null, endX: null, startY: null, endY: null };
			}

			// kijk na de lus of er nog een stuk handrail is die dan ook opslaan
			if (handRailRight.startX !== null && handRailRight.endX !== null && handRailRight.startY !== null && handRailRight.endY !== null) {
				this.addHandRail3D(canvas3d, {
					position: HandRails.RIGHT,
					handRailPosition: {
						startX: new DrawValue(handRailRight.startX),
						endX: new DrawValue(handRailRight.endX),
						startY: new DrawValue(handRailRight.startY),
						endY: new DrawValue(handRailRight.endY),
					},
					yPosition: posY,
					etage: etage,
				});
				handRailRight = { startX: null, endX: null, startY: null, endY: null };
			}
		});
	}

	addPossiblePositions(canvas, params) {
		const activeEtage = params.actieveEtage;
		activeEtage.raster.spansX.getSpans().forEach((spanX, indexX) => {
			activeEtage.raster.spansY.getSpans().forEach((spanY, indexY) => {
				const huidigRasterActief = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY));
				const bovenliggendRasterActief = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY - 1));
				const rechtsRasterActief = activeEtage.isActiveRaster(new RemoveRaster(indexX + 1, indexY));
				const linksRasterActief = activeEtage.isActiveRaster(new RemoveRaster(indexX - 1, indexY));
				const onderRasterActief = activeEtage.isActiveRaster(new RemoveRaster(indexX, indexY + 1));
				const column = Configuration.CURRENT.columns.find(indexX, indexY);

				if (huidigRasterActief === true) {
					if (bovenliggendRasterActief === false) {
						// huidige raster actief en bovenliggend niet

						const drawObjectTop = this.createHandRail(column.x, column.y, HandRails.TOP, true);
						if (typeof drawObjectTop !== 'undefined' && drawObjectTop !== null) {
							canvas.addDrawObject(drawObjectTop);
						}
					}
					if (onderRasterActief === false) {
						// huidige raster actief en bovenliggend niet
						const drawObjectBottom = this.createHandRail(column.x, column.y, HandRails.BOTTOM, true);
						if (typeof drawObjectBottom !== 'undefined' && drawObjectBottom !== null) {
							canvas.addDrawObject(drawObjectBottom);
						}
					}
					if (rechtsRasterActief === false) {
						// huidige raster actief en bovenliggend niet
						const drawObjectRight = this.createHandRail(column.x, column.y, HandRails.RIGHT, true);
						if (typeof drawObjectRight !== 'undefined' && drawObjectRight !== null) {
							canvas.addDrawObject(drawObjectRight);
						}
					}
					if (linksRasterActief === false) {
						// huidige raster actief en bovenliggend niet
						const drawObjectLeft = this.createHandRail(column.x, column.y, HandRails.LEFT, true);
						if (typeof drawObjectLeft !== 'undefined' && drawObjectLeft !== null) {
							canvas.addDrawObject(drawObjectLeft);
						}
					}
				}
			});
		});
	}
	createHandRailWithoutInterrupts(rasterX, rasterY, position, possiblePositions, handRailPosition, lineDash, color) {
		switch (position) {
			case HandRails.TOP:
				return new Line(
					handRailPosition.startX,
					handRailPosition.startY,
					handRailPosition.endX,
					handRailPosition.startY,
					new DrawValue(0, 1),
					lineDash,
					color,
					null,
					null,
					possiblePositions === true,
					this,
					{
						x: rasterX,
						y: rasterY,
						position: position,
					},
				);
			case HandRails.BOTTOM:
				return new Line(
					handRailPosition.startX,
					handRailPosition.startY,
					handRailPosition.endX,
					handRailPosition.startY,
					new DrawValue(0, 1),
					lineDash,
					color,
					null,
					null,
					possiblePositions === true,
					this,
					{
						x: rasterX,
						y: rasterY,
						position: position,
					},
				);
			case HandRails.RIGHT:
				return new Line(
					handRailPosition.endX,
					handRailPosition.startY,
					handRailPosition.endX,
					handRailPosition.endY,
					new DrawValue(0, 1),
					lineDash,
					color,
					null,
					null,
					possiblePositions === true,
					this,
					{
						x: rasterX,
						y: rasterY,
						position: position,
					},
				);
			case HandRails.LEFT:
				return new Line(
					handRailPosition.endX,
					handRailPosition.startY,
					handRailPosition.endX,
					handRailPosition.endY,
					new DrawValue(0, 1),
					lineDash,
					color,
					null,
					null,
					possiblePositions === true,
					this,
					{
						x: rasterX,
						y: rasterY,
						position: position,
					},
				);
		}
	}
	createHandRailInterrupts(rasterX, rasterY, position, possiblePositions, handRailPosition, lineDash, color, lengthInterruptions) {
		const drawObjects = [];
		switch (position) {
			case HandRails.TOP:
			case HandRails.BOTTOM:
				if (handRailPosition.startX.value < lengthInterruptions[0].startX) {
					// voor de interrupties nog ruimte dan tekenen

					drawObjects.push(
						new Line(
							handRailPosition.startX,
							handRailPosition.startY,
							new DrawValue(lengthInterruptions[0].startX),
							handRailPosition.startY,
							new DrawValue(0, 1),
							lineDash,
							color,
							null,
							null,
							possiblePositions === true,
							this,
							{
								x: rasterX,
								y: rasterY,
								position: position,
							},
						),
					);
				}

				if (lengthInterruptions.length > 1) {
					// meer dan 1 tussen interuptions checken
					for (let i = 0; i < lengthInterruptions.length - 1; i++) {
						if (lengthInterruptions[i].endX < lengthInterruptions[i + 1].startX) {
							drawObjects.push(
								new Line(
									new DrawValue(lengthInterruptions[i].endX),
									handRailPosition.startY,
									new DrawValue(lengthInterruptions[i + 1].startX),
									handRailPosition.startY,
									new DrawValue(0, 1),
									lineDash,
									color,
									null,
									null,
									possiblePositions === true,
									this,
									{
										x: rasterX,
										y: rasterY,
										position: position,
									},
								),
							);
						}
					}
				}
				if (lengthInterruptions[lengthInterruptions.length - 1].endX < handRailPosition.endX.value) {
					drawObjects.push(
						new Line(
							new DrawValue(lengthInterruptions[lengthInterruptions.length - 1].endX),
							handRailPosition.startY,
							handRailPosition.endX,
							handRailPosition.startY,
							new DrawValue(0, 1),
							lineDash,
							color,
							null,
							null,
							possiblePositions === true,
							this,
							{
								x: rasterX,
								y: rasterY,
								position: position,
							},
						),
					);
				}
				break;
			case HandRails.RIGHT:
			case HandRails.LEFT:
				if (handRailPosition.startY.value < lengthInterruptions[0].startY) {
					drawObjects.push(
						new Line(
							handRailPosition.endX,
							handRailPosition.startY,
							handRailPosition.endX,
							new DrawValue(lengthInterruptions[0].startY),
							new DrawValue(0, 1),
							lineDash,
							color,
							null,
							null,
							possiblePositions === true,
							this,
							{
								x: rasterX,
								y: rasterY,
								position: position,
							},
						),
					);
				}
				if (lengthInterruptions.length > 1) {
					// meer dan 1 tussen interuptions checken
					for (let i = 0; i < lengthInterruptions.length - 1; i++) {
						if (lengthInterruptions[i].endY < lengthInterruptions[i + 1].startY) {
							drawObjects.push(
								new Line(
									handRailPosition.endX,
									new DrawValue(lengthInterruptions[i].endY),
									handRailPosition.endX,
									new DrawValue(lengthInterruptions[i + 1].startY),
									new DrawValue(0, 1),
									lineDash,
									color,
									null,
									null,
									possiblePositions === true,
									this,
									{
										x: rasterX,
										y: rasterY,
										position: position,
									},
								),
							);
						}
					}
				}
				if (lengthInterruptions[lengthInterruptions.length - 1].endY < handRailPosition.endY.value) {
					drawObjects.push(
						new Line(
							handRailPosition.endX,
							new DrawValue(lengthInterruptions[lengthInterruptions.length - 1].endY),
							handRailPosition.endX,
							handRailPosition.endY,
							new DrawValue(0, 1),
							lineDash,
							color,
							null,
							null,
							possiblePositions === true,
							this,
							{
								x: rasterX,
								y: rasterY,
								position: position,
							},
						),
					);
				}
				break;
		}
		return drawObjects;
	}
	createHandRail(rasterX, rasterY, position, possiblePositions, lengthInterruptions = []) {
		let color = HandRails.COLORS.handRails;

		let lineDash = null;
		if (possiblePositions === true) {
			lineDash = [5, 3];
			color = HandRails.COLORS.possible;
			if (this.findIndex(rasterX, rasterY, position) > -1) {
				color = HandRails.COLORS.added;
			}
		}

		const handRailPosition = Configuration.CURRENT.overhang.getPosition(rasterX, rasterY, position);
		if (handRailPosition === null) {
			return;
		}
		if (lengthInterruptions.length > 0) {
			return this.createHandRailInterrupts(rasterX, rasterY, position, possiblePositions, handRailPosition, lineDash, color, lengthInterruptions);
		}

		return this.createHandRailWithoutInterrupts(rasterX, rasterY, position, possiblePositions, handRailPosition, lineDash, color);
	}
}

import { Configuration } from './configuration';
import { Hole } from './hole';
import { RemoveRaster } from './removeRaster';
import { Rectangle } from '../draw/rectangle';
import { Serializer } from './serializer';
import { DrawValue } from '../draw/drawValue';
import { Canvas3D } from '../draw3d/Canvas3D';

export class Holes {
	objectName = 'Holes';
	holes = [];
	showConfigurator = false;
	_onChange = null;
	_redraw = null;
	_edit = null;
	_remove = null;
	possiblePossitionsVisible = false;
	holeSettings = new Hole();
	static COLORS = {
		possiblePosition: 'gray',
		possiblePositionOutSideSelected: 'green',
		possiblePositionOutSide: 'blue',
		lineDash: [5, 3],
		column: '#ededed',
		possible: '#6ba3ff',
		mouseMove: '#00286d',
		selected: '#c2c9f0',
		notSelected: '#f2f2f2',
		error: 'red',
		notOnEtage: '#cce6ff',
	};

	constructor(onChange, redraw) {
		this._onChange = onChange;
		this._redraw = redraw;

		setTimeout(() => {
			this.init();
		});
	}
	init() {
		if (typeof this.onMainBeamDirectionChange === 'function') {
			Configuration.CURRENT.profiles.onMainBeamDirectionChangeEvents.push((oldValue, newValue) => {
				this.onMainBeamDirectionChange(oldValue, newValue);
			});
		}
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._redraw = params.redraw;
		this._checkCollisions = params.checkCollisions;

		params.edit = this.edit.bind(this);
		params.remove = this.remove.bind(this);
		params.onChange = this.onChange.bind(this);

		this.holes.forEach((hole) => {
			hole.setReferences(params);
		});
	}
	removeReferences() {
		this._onChange = null;
		this._redraw = null;
		this.holes.forEach((hole) => {
			hole.removeReferences();
		});
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}

	cancel(object) {
		let serializer = new Serializer();
		this.holeSettings.update(serializer.parse(this.holeSettings.oldValues), false);
	}

	redraw() {
		if (this._redraw !== null && typeof this._redraw === 'function') {
			this._redraw();
		}
	}

	afterReconstruct() {
		this.showConfigurator = false; // false slaat hij niet altijd op omdat cancel niet een wijziging in de configuratie tot gevolg heeft. Maar bij reconstruct altijd configurator verbergen
	}

	addDrawObjects(canvas, params) {
		this.holes.forEach((hole, index) => {
			hole.addDrawObjects(canvas);
		});
	}

	addDrawObjects3d(canvas3d, etage, raster, posY) {
		this.holes.forEach((hole, index) => {
			hole.addDrawObjects3d(canvas3d, etage, raster, posY);
		});
	}

	get() {
		return this.holes;
	}

	// Na submit van edit-hole component.
	createHole(object) {
		this.showConfigurator = false;

		if (object.hole.isNewHole) {
			this.holeSettings = object.hole;
			Configuration.CURRENT.setAccessoriesType('holes', false);
			this.possiblePossitionsVisible = true;

			Configuration.CURRENT.notification.show('holes.position', null, () => {
				Configuration.CURRENT.setAccessoriesType('');
				this.possiblePossitionsVisible = false;
			});
		} else {
			Configuration.CURRENT.setAccessoriesType('');
			object.hole.update(object.hole);
		}
	}

	showPossiblePostion(object) {
		object.opacity = 0.5;
	}
	hidePossiblePostion(object) {
		object.opacity = 0;
	}

	// Openen van de popup.
	newHoleInFloor() {
		if (this.possiblePossitionsVisible) {
			this.possiblePossitionsVisible = false;
			Configuration.CURRENT.notification.hide();
			this.onChange();
		}
		Configuration.CURRENT.setAccessoriesType('holes', false);

		this.showConfigurator = true;
	}

	onRasterChanged(params) {
		this.holes.forEach((hole) => {
			hole.calculate();
		});
	}

	calculateAmount(params) {
		this.holes.forEach((hole) => {
			hole.calculateAmount(params);
		});
	}

	findByProfilePosition(coordinates, etageHeight) {
		return this.holes.filter((h) => h.onProfilePosition(coordinates, etageHeight) === true);
	}

	onClickPossiblePosition(evt, object, canvas, params) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(object.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(object.objectParams.y);

		let offsetX = (lengthX.value - this.holeSettings.width) / 2;
		let offsetY = (lengthY.value - this.holeSettings.depth) / 2;

		let posX = Configuration.CURRENT.raster.getSizeX(object.objectParams.x) - offsetX - this.holeSettings.width;
		let posY = Configuration.CURRENT.raster.getSizeY(object.objectParams.y) - offsetY - this.holeSettings.depth;

		this.holeSettings.x = posX;
		this.holeSettings.y = posY;

		this.create(this.holeSettings, canvas, params);
		Configuration.CURRENT.setAccessoriesType('');
		this.clearSettings();
	}

	clearSettings() {
		this.holeSettings = new Hole();
	}

	create(object, canvas, params) {
		this.showConfigurator = false;
		if (object.isNewHole) {
			if (object.x === '' && object.y === '') {
				Configuration.CURRENT.accessoriesType = 'holes';
				Configuration.CURRENT.redraw();
				return;
			}
			let hole = new Hole(this.holeSettings, canvas, params);
			this.push(hole);
		} else {
			object.update(object);
		}

		this.onChange();
	}

	select(parameters, canvas) {
		this.holes.forEach((hole) => {
			hole.select(parameters, canvas);
		});
	}

	remove(item) {
		let foundIndex = -1;
		Configuration.CURRENT.contextMenu.hide();
		this.holes.forEach((hole, index) => {
			if (hole.id === item.id) {
				foundIndex = index;
			}
		});
		this.holes.splice(foundIndex, 1);
		this.onChange();
	}

	edit(item) {
		this.showConfigurator = true;
		this.holeSettings = item;
		this.holeSettings.isNewHole = false;
		let serializer = new Serializer();
		this.holeSettings.oldValues = serializer.stringify(this.holeSettings);
	}

	push(item) {
		item.setReferences({
			edit: this.edit.bind(this),
			remove: this.remove.bind(this),
			onChange: this.onChange.bind(this),
			redraw: this.redraw.bind(this),
			checkCollisions: this._checkCollisions,
		});
		this.holes.push(item);
	}

	onClick(evt, object, canvas, params) {
		this.onClickPossiblePosition(evt, object, canvas, params);
		this.onChange();
		return { stopPropagation: true };
	}
	onMouseUp(evt, object, canvas, params) {
		this.onChange();
		return { stopPropagation: true };
	}
	onMouseMove(evt, object, canvas) {
		this.onMouseMovePossiblePosition(evt, object, canvas);

		return { stopPropagation: true };
	}
	onMouseLeave(evt, object, canvas) {
		this.onMouseLeavePossiblePosition(evt, object, canvas);
		return { stopPropagation: true };
	}

	onMouseMovePossiblePosition(evt, object, canvas) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(object.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(object.objectParams.y);
		if (lengthX !== null && lengthY !== null && this.isPossible(lengthX.value, lengthY.value)) {
			object.opacity = 0.5;
		}

		return { stopPropagation: true };
	}
	onMouseLeavePossiblePosition(evt, object, canvas) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(object.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(object.objectParams.y);
		if (lengthX !== null && lengthY !== null && this.isPossible(lengthX.value, lengthY.value)) {
			object.opacity = 0.2;
		}
		return { stopPropagation: true };
	}

	addPossiblePositions(canvas, params) {
		let possiblePositionFound = false;
		params.raster.spansX.getSpans().forEach((spanX, indexX) => {
			params.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (params.actieveEtage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
					let raster = new Rectangle(
						new DrawValue(params.raster.getSizeX(indexX - 1), 1),
						new DrawValue(params.raster.getSizeY(indexY - 1), 1),
						new DrawValue(spanX.value, -2),
						new DrawValue(spanY.value, -2),

						Holes.COLORS.possiblePosition,
						null,
						null,
						true,
						this,
						{ x: indexX, y: indexY },
					);
					raster.border = true;
					if (this.isPossible(spanX.value, spanY.value) === true) {
						raster.opacity = 0.2;
						possiblePositionFound = true;
					} else {
						raster.opacity = 0;
					}

					canvas.addDrawObject(raster);
				}
			});
		});

		if (possiblePositionFound === false) {
			Configuration.CURRENT.notification.hide();
			Configuration.CURRENT.notification.show(window.Vue.$translate('holes.noPosition'), 1000);
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.setAccessoriesType('');
		}
	}

	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];

		this.holes.forEach((hole) => {
			let holeResult = hole.collisions(boundaries, self);

			if (holeResult.result === true) {
				collisionsDetect = true;
				holeResult.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult, objectName: this.objectName };
	}
	overlap(boundaries) {
		let overlap = false;

		this.holes.forEach((hole, index) => {
			if (hole.overlap(boundaries) === true) {
				overlap = true;
			}
		});

		return overlap;
	}
	collisionCheck() {
		let collisions = false;
		let errorResult = [];
		this.holes.forEach((hole, index) => {
			let collisionCheck = hole.collisionCheck();
			if (collisionCheck.result === true) {
				collisions = true;
				collisionCheck.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisions, errors: errorResult };
	}

	isPossible(spanX, spanY) {
		return true;
	}
}

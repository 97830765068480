/* eslint-disable no-new */
import { ObjectGroup } from '../draw/objectGroup';
import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { TrimmingTop3D } from '../draw3d/buildingcolumn/trimming/TrimmingTop3D';
import { TrimmingBottom3D } from '../draw3d/buildingcolumn/trimming/TrimmingBottom3D';
import { TrimmingLeft3D } from '../draw3d/buildingcolumn/trimming/TrimmingLeft3D';
import { TrimmingRight3D } from '../draw3d/buildingcolumn/trimming/TrimmingRight3D';
import { Configuration } from './configuration';
import { Columns } from './columns';

import { Profiles } from './profiles';
import { BuildingColumnTrimmings } from './buildingColumnTrimmings';
import { Canvas3D } from '../draw3d/Canvas3D';

export class BuildingColumnTrimming {
	objectName = 'BuildingColumnTrimming';
	startX = 0;
	startY = 0;
	endX = 0;
	endY = 0;
	position = -1;
	// Om bij te houden op welke etage de trimming hoort.
	// Dan kunnen we er in 2D rekening mee houden dat hij netjes de beams tekenent die bij etage horen.
	// Bij het 3D tekenen dan gewoon allemaal tekenen op de juiste hoogte die bij die etage hoort.
	etageId = null;
	active = true;
	drawObjectId = null;

	get length() {
		if (this.startX === this.endX) {
			return this.endY - this.startY;
		} else {
			return this.endX - this.startX;
		}
	}
	constructor(trimming) {
		if (typeof trimming !== 'undefined' && trimming !== null) {
			this.update(trimming);
		}
	}
	update(trimming) {
		this.startX = trimming.startX;
		this.startY = trimming.startY;
		this.endX = trimming.endX;
		this.endY = trimming.endY;
		this.position = trimming.position;
		this.etageId = trimming.etageId;
		this.active = typeof trimming.active !== 'undefined' && trimming.active !== null ? trimming.active : true;
	}
	calculateAmount(params) {
		let raster = params.raster.getRasterByCoordinate(this.startX, this.startY);
		let lastRaster;
		let firstRaster;
		let margin = 50;
		let span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[raster.x] : params.raster.spansY.getSpans()[raster.y];

		// Gebouwkolom steekt rechts buiten de vloer.
		if (this.startX >= params.etages.floor.width) {
			if (span === null || typeof span === 'undefined') {
				lastRaster = params.raster.getRasterByCoordinate(params.etages.floor.width - margin, this.startY);
				span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[lastRaster.x] : params.raster.spansY.getSpans()[lastRaster.y];
			}
		}
		// Gebouwkolom steekt links buiten de vloer.
		else if (this.startX <= 0) {
			if (span === null || typeof span === 'undefined') {
				firstRaster = params.raster.getRasterByCoordinate(0, this.startY);
				span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[firstRaster.x] : params.raster.spansY.getSpans()[firstRaster.y];
			}
		}
		// Gebouwkolom steekt onder buiten de vloer.
		if (this.startY >= params.etages.floor.length) {
			if (span === null || typeof span === 'undefined') {
				lastRaster = params.raster.getRasterByCoordinate(0, params.etages.floor.length - margin);
				span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[lastRaster.x] : params.raster.spansY.getSpans()[lastRaster.y];
			}
		}
		// Gebouwkolom steekt boven buiten de vloer.
		else if (this.startY <= 0) {
			if (span === null || typeof span === 'undefined') {
				firstRaster = params.raster.getRasterByCoordinate(this.startX, 0);
				span = Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL ? params.raster.spansX.getSpans()[firstRaster.x] : params.raster.spansY.getSpans()[firstRaster.y];
			}
		}

		if (this.startX === this.endX) {
			Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endY - this.startY), 1, span);
		} else {
			Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endX - this.startX), 1, span);
		}
	}
	addDrawObjects() {
		let profiles = Configuration.CURRENT.profiles;

		// mainbeams worden tegen kolommen aangetekend en kolommen staan precies op het midden. Dus helft van kolom gaat eraf. Waarvan is afhankelijk van richting hoofdbalken
		let columnShiftX = 0;
		let columnShiftY = 0;
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (this.position === BuildingColumnTrimmings.LEFT || this.position === BuildingColumnTrimmings.RIGHT) {
				columnShiftX = 0;
				columnShiftY = Columns.COLUMN_SIZE / 2;
			}
		} else if (this.position === BuildingColumnTrimmings.TOP || this.position === BuildingColumnTrimmings.BOTTOM) {
			columnShiftX = Columns.COLUMN_SIZE / 2;
			columnShiftY = 0;
		}

		let trimming = new Line(
			new DrawValue(this.startX, columnShiftX),
			new DrawValue(this.startY, columnShiftY),
			new DrawValue(this.endX, -columnShiftX),
			new DrawValue(this.endY, -columnShiftY),
			null,
			null,
			profiles.colors.mainBeam,
			null,
			null,
			false,
			null,
			{ type: 'buildingColumn', color: null },
		);
		trimming.colorFromParent = false;
		this.drawObjectId = trimming.id;

		return trimming;
	}
	addDrawObjects3d(canvas3d, raster, drawHeight) {
		let multipleFloors = Configuration.CURRENT.etages.length > 1;
		let rasterCoords = raster.getRasterByCoordinate(this.startX, this.startY);
		let rowData = raster.getRowPositions(rasterCoords.x, rasterCoords.y);
		let span;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (rasterCoords.x === -1) {
				rasterCoords.x = 0;
			}
			span = raster.spansX.get(rasterCoords.x);
		} else {
			if (rasterCoords.y === -1) {
				rasterCoords.y = 0;
			}
			span = raster.spansY.get(rasterCoords.y);
		}

		let paramsObject = {
			drawHeight: drawHeight,
			multipleFloors: multipleFloors,
			rowPositions: rowData.rowPositions,
			placement: rowData.placement,
			oid: Configuration.CURRENT.profiles.mainBeam.oid,
			assetName: Configuration.CURRENT.profiles.mainBeam.description,
			profileType: 'mainbeam',
			oid: span.beam.oid,
			mainBeamDepth: Canvas3D.CURRENT.getBeamData(span.beam.oid, 'depth'),
			mainBeamHeight: Canvas3D.CURRENT.getBeamData(span.beam.oid, 'height'),
		};
		switch (this.position) {
			case BuildingColumnTrimmings.TOP:
				canvas3d.addDrawObject(new TrimmingTop3D(this.startX, this.startY, this.endX - this.startX, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case BuildingColumnTrimmings.BOTTOM:
				canvas3d.addDrawObject(new TrimmingBottom3D(this.startX, this.startY, this.endX - this.startX, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case BuildingColumnTrimmings.LEFT:
				canvas3d.addDrawObject(new TrimmingLeft3D(this.startX, this.startY, this.endY - this.startY, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case BuildingColumnTrimmings.RIGHT:
				canvas3d.addDrawObject(new TrimmingRight3D(this.startX, this.startY, this.endY - this.startY, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
		}
	}
}

import { Data } from './data';
import { Languages } from './languages';
import { Store } from './store';

export class CrossBracings extends Data {
	objectName = 'CrossBracings';
	url = 'CrossBracings';
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		data.language = Languages.CURRENT.currentLanguage;

		if (data.companyId !== -1) {
			super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
		}
	}

	getItemBasedOnWidth(width) {
		if (this._list.list !== null && typeof this._list.list !== 'undefined') {
			const item = this._list.list.find((item) => item.columnWidthFrom >= width);
			if (typeof item !== 'undefined') {
				return item;
			}
		}
	}
}

import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { Canvas3D } from '../draw3d/Canvas3D';
import { Etage } from './etage';
import { RemoveRaster } from './removeRaster';
import { Errors } from './errors';
import { Configuration } from './configuration';
import { CustomError } from './CustomError';

export class Etages {
	objectName = 'Etages';
	objectName3d = 'Etage';
	_onChange = null;
	raster = null;
	_activeIndex = 0;
	totalHeight = 0;
	totalBracings = 0;
	totalLengthHandRails = 0;
	floor = { lenght: 0, width: 0 };
	types = [
		{ value: 'above', caption: 'floors.height.above' },
		{ value: 'under', caption: 'floors.height.under' },
	];
	get activeIndex() {
		return this._activeIndex;
	}
	set activeIndex(index) {
		this._activeIndex = index;
		this.onChange();
	}

	etages = []; // getEtages werkt niet in de VUE-pagina's. Daarom die ook rechtstreeks etages aan laten roepen
	constructor(value, onChange, rasterChanged, raster, redraw) {
		this._onChange = onChange;
		this._rasterChanged = rasterChanged;

		this.raster = raster;
		this._redraw = redraw;

		if (typeof value !== 'undefined' && value !== null) {
			value.forEach(
				function (value) {
					this.push(value);
				}.bind(this),
			);
		}
	}
	get length() {
		return this.etages.length;
	}
	remove(index) {
		if (index < this.etages.length) {
			window.Vue.$confirm({
				isHtml: true,
				title: window.Vue.$translate('confirm.title.removeEtage'),
				content: window.Vue.$translate('confirm.content.removeEtage', { etage: index + 1 }),
				okText: window.Vue.$translate('confirm.button.yes'),
				cancelText: window.Vue.$translate('confirm.button.no'),
			})
				.then((success) => {
					let etageId = this.etages[index].id;

					this.etages.splice(index, 1);
					if (this.activeIndex >= this.etages.length) {
						this.activeIndex = this.etages.length - 1;
					}

					this.etages.forEach((etage, index) => {
						if (etage.stairs.stairs.length > 0) {
							etage.stairs.stairs.forEach((stair) => {
								if (typeof stair.removeLandingsFromEtage === 'function') {
									if (stair.objectName === 'StairOutSide') {
										stair.removeLandingsFromEtage(etageId);
									}
								}
							});
						}
						etage.setHeight(index);
					});

					Configuration.CURRENT.buildingColumns.buildingColumns.forEach((bc) => {
						if (bc.customHeight === false) {
							bc.updateHeight(Configuration.CURRENT.etages.getTotalHeight(this.etages.length - 1, true));
						}
					});
					this.raster.onChange(null);
				})
				.catch((cancel) => {});
		}
	}
	get(index) {
		return this.etages[index];
	}
	getEtages() {
		return this.etages;
	}
	getByGivenHeight(height) {
		const etages = this.etages.filter((e) => height <= e.getHeight(true));
		if (typeof etages !== 'undefined' && etages !== null) {
			return etages[0].id;
		}
	}
	getTotalHeight(etageIndex, alsoCurrent) {
		// Totale hoogte tot etageindex, dus als etageindex 2 is halen we 3 etagehoogtes met bijhoorende packetheight op en returnen deze.
		let totalHeight = 0;
		let etages = this.getEtagesUnderIndex(etageIndex, alsoCurrent);
		// this.etages.filter((etage, index) => index <= etageIndex);

		etages.forEach((etage) => {
			totalHeight += etage.getHeight(true);
		});

		return totalHeight;
	}
	getEtagesUnderIndex(etageIndex, alsoCurrent) {
		if (alsoCurrent) {
			return this.etages.filter((etage, index) => index <= etageIndex);
		} else {
			return this.etages.filter((etage, index) => index < etageIndex);
		}
	}
	getEtagesAboveIndex(etageIndex, alsoCurrent) {
		if (alsoCurrent) {
			return this.etages.filter((etage, index) => index >= etageIndex);
		} else {
			return this.etages.filter((etage, index) => index > etageIndex);
		}
	}
	getAmount() {
		let amounts = {};
		this.etages.forEach((etage, index) => {
			let etageAmount = etage.getAmount();
			Object.keys(etageAmount).forEach((amount) => {
				if (typeof amounts[amount] === 'undefined') {
					amounts[amount] = etageAmount[amount];
				} else {
					etageAmount[amount].forEach((amountItem) => {
						amounts[amount].push(amountItem);
					});
				}
			});
		});
		return amounts;
	}
	select(parameters, canvas) {
		this.etages.forEach((etage) => {
			etage.select(parameters, canvas);
		});
	}
	rasterChanged() {
		if (this._onChange !== null && typeof this._rasterChanged === 'function') {
			this._rasterChanged();
		}
	}
	onChange(status) {
		if (this._onChange !== null && typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	redraw() {
		if (this._redraw !== null && typeof this._redraw === 'function') {
			this._redraw();
		}
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._rasterChanged = params.rasterChanged;
		this.raster = params.raster;
		this._redraw = params.redraw;

		this.etages.forEach((etage) => {
			etage.setReferences({ onChange: this._onChange, raster: this.raster, rasterChanged: this._rasterChanged, redraw: this.redraw });
		});
	}
	removeReferences() {
		this._onChange = null;
		this._rasterChanged = null;
		this.raster = null;
		this._redraw = null;
		this.etages.forEach((etage) => {
			if (typeof etage.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				etage.removeReferences();
			}
		});
	}
	checkCollisions(boundaries, self) {
		// objecten die hier staan controleren met andere objecten per etage
		if (typeof boundaries === 'undefined' || boundaries === null) {
			return;
		}
		let collisions = false;
		let errorResult = [];
		this.etages.forEach((etage) => {
			let collisionCheck = etage.checkCollisions(boundaries, self);
			if (collisionCheck.result === true) {
				collisions = true;
				collisionCheck.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});

		return { result: collisions, errors: errorResult };
	}

	onRasterChanged(params) {
		// krijg hier calculateSurfaces niet aangeroepen. Daarom maar forEach gekopieerd
		this.totalHeight = 0;
		this.etages.forEach((element, index) => {
			element.calculateSurface();

			this.totalHeight += element.getHeight();
			if (index === 0) {
				// eerste etage
				this.floor = element.floor;
			}

			element.onRasterChanged(params);
		});
	}
	calculateAmount(params) {
		this.totalBracings = 0;
		this.totalLengthHandRails = 0;
		this.etages.forEach((etage, index) => {
			etage.calculateAmount(params);
			this.totalBracings += etage.bracings.length();
			this.totalLengthHandRails += etage.handRails.lengthHandRail;
		});
	}
	getRemoveRasters() {
		let removeRasters = [];
		this.getEtages().forEach((etage, index) => {
			removeRasters.push(etage.removeRasters);
		});
		return removeRasters;
	}
	cloneLast() {
		const lastEtage = this.etages[this.etages.length - 1];
		let newEtage = new Etage(lastEtage.type, lastEtage.height, true);
		newEtage.setReferences({ onChange: this._onChange, raster: this.raster, rasterChanged: this._rasterChanged, redraw: this._redraw });
		lastEtage.removeRasters.get().forEach((removeRaster, index) => {
			newEtage.removeRasters.push(new RemoveRaster(removeRaster.x, removeRaster.y));
		});
		this.etages.push(newEtage);
		this.activeIndex = this.etages.length - 1;
		this.raster.onChange(null);

		// Updaten van de buildingColumns
		Configuration.CURRENT.buildingColumns.buildingColumns.forEach((bc) => {
			if (bc.customHeight === false) {
				bc.updateHeight(Configuration.CURRENT.etages.getTotalHeight(this.etages.length - 1, true));
			}
		});
	}
	activeEtage() {
		return this.etages[this._activeIndex];
	}
	getById(etageId) {
		let findEtages = this.etages.filter((etage) => etage.id === etageId);
		if (findEtages.length > 0) {
			return findEtages[0];
		}
		return null;
	}
	get activeEtageIndex() {
		return this._activeIndex;
	}
	push(value) {
		value.setReferences({ onChange: this._onChange, raster: this.raster, rasterChanged: this._rasterChanged, redraw: this._redraw });
		return this.etages.push(value);
	}

	calculateSurfaces() {
		this.etages.forEach((element, index) => {
			element.calculateSurface();
		});
	}

	hasErrors() {
		let hasErrors = 0;
		// 'akker' over de errors per etage.
		this.etages.forEach((etage, index) => {
			let objectHasErrors = etage.hasErrors();
			if (objectHasErrors === true) {
				hasErrors++;
			}
		});
		return hasErrors > 0;
	}
	getErrors() {
		let errors = new Errors();
		// 'akker' over de errors per etage.
		this.etages.forEach((etage, index) => {
			let objectErrors = etage.getErrors();
			if (typeof objectErrors !== 'undefined' && objectErrors !== null) {
				objectErrors.getAll().forEach((error) => {
					errors.push(error);
				});
			}
		});

		if (this.activeEtageIndex !== 0) {
			errors.push(new CustomError(window.Vue.$translate('configuration.etages.goto.first.level'), Errors.ERRORTYPE.collision, '', this.objectName, this.objectName));
		}

		return errors;
	}
	addDrawObjects3d(canvas3d, raster) {
		// Per etage de objecten bekijken en kijken of deze 3d objecten moeten toevoegen
		let posY = 0;
		this.etages.forEach((etage, index) => {
			etage.addDrawObjects3d(canvas3d, etage, raster, posY, index);
			posY += etage.getHeight(true);
		});
	}
	create3DAssets(canvas3d) {
		this.etages.forEach((etage, index) => {
			etage.create3DAssets(canvas3d, index);
		});
	}

	// Alle stairs berekenen maar niet tekenen.
	redrawStairs() {
		this.etages.forEach((etage, index) => {
			// Alleen redraw van de stairs die niet op huidige etage zitten.
			if (index !== this.activeEtageIndex) {
				if (etage.stairs.stairs.length > 0) {
					etage.stairs.stairs.forEach((stair) => {
						if (typeof stair.addDrawObjects === 'function') {
							stair.addDrawObjects(null);
						}
					});
				}
			}
		});
	}
}

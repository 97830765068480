import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
import { JsonBraceColumn } from '@/configurator/hicad/jsonBraceColumn';

export class BraceColumnBottom3D extends Model3D {
	objectName = 'BraceColumnBottom3D';
	rotationX = -20;
	fallBackName = 'column';
	fallBackData = { width: 90, depth: 90 };
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		if (Configuration.CURRENT.colors.bracingColumns.finish === 1 || Configuration.CURRENT.colors.bracingColumns.finish === 2) {
			this.ralColor = 7036;
		} else {
			this.ralColor = Configuration.CURRENT.colors.bracingColumns.ralColor;
		}

		// Diepte meerekenen
		this.z += this.info.depth;

		// Z + de kolom breedte omdat we de bracecolumn aan de onderkant van de huidige kolom tekenen.
		this.z += columnWidth;

		this.height += columnWidth / 2;
		this.height -= deckingFinishHeight;
		this.height -= this.mainBeamHeight;

		// Min 70 omdat we op een andere as roteren
		this.y -= 70;

		this.basePlate.x = this.x;
		this.basePlate.z = this.z;
		this.basePlate.draw(app);
		super.draw(app);
	}

	convert() {
		return new JsonBraceColumn(this);
	}
}

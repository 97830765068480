import { Store } from '../data/store';

import { Configuration } from './configuration';

import { Stair } from './stair';
import { Serializer } from './serializer';

import { StairDynamic } from './StairDynamic';
import { Errors } from './errors';
import { Standard } from './standards/standard';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { Canvas3D } from '../draw3d/Canvas3D';
import { LocalAsset3D } from '../draw3d/assets/LocalAsset3D';

export class Stairs {
	static PASSAGE_HEIGHT = 2300; // trap op kunnen zonder hoofd te stoten
	static PASSAGE_WIDTH = 400; // extra breedte in het trapgat om leuning te monteren en ook vast te kunnen houden
	static COLORS = {
		stairWell: 'white',
		stair: 'gray',
		landing: 'gray',
		stairTail: 'gray',
		stairCollisions: 'red',
		possiblePosition: 'gray',
		possiblePositionOutSideSelected: 'green',
		possiblePositionOutSide: 'blue',
		lineDash: [5, 3],
		selected: 'blue',
	};

	static getOppositeUpComing(i) {
		if (i === Stair.UPCOMING_RIGHT) {
			return Stair.UPCOMING_LEFT;
		}
		if (i === Stair.UPCOMING_TOP) {
			return Stair.UPCOMING_BOTTOM;
		}
		if (i === Stair.UPCOMING_LEFT) {
			return Stair.UPCOMING_RIGHT;
		}

		if (i === Stair.UPCOMING_BOTTOM) {
			return Stair.UPCOMING_TOP;
		}
	}

	objectName = 'Stairs';
	showConfigurator = false;
	stairConfiguratorSettings = new Stair();
	stairs = [];
	mousePriority = 10;
	mouseAreaOffset = { x: 10, y: 10 };
	possiblePossitionsVisible = '';
	constructor(onChange, checkCollisions) {
		this._onChange = onChange;
		this._checkCollisions = checkCollisions;
	}
	select(parameters, canvas) {
		this.stairs.forEach((stair) => {
			stair.select(parameters, canvas);
		});
	}
	positionAgainstEdge(stair) {
		let width = Configuration.CURRENT.etages.floor.width;
		let length = Configuration.CURRENT.etages.floor.length;
		return stair.stairWell.startX + stair.stairWell.width === width || stair.stairWell.startY + stair.stairWell.depth === length || stair.stairWell.startX === 0 || stair.stairWell.startY === 0;
	}
	directionIsHorizontal(stair) {
		return stair.stairWell.width > stair.stairWell.depth;
	}
	get(index) {
		if (typeof index !== 'undefined' && index !== null) {
			return this.stairs[index];
		}
		return this.stairs;
	}
	onClick(evt, object, canvas, params) {
		this.stairConfiguratorSettings.onClickPossiblePosition(evt, object, canvas, params, this.stairConfiguratorSettings, this);

		return { stopPropagation: true };
	}
	onMouseMove(evt, object, canvas) {
		this.stairConfiguratorSettings.onMouseMovePossiblePosition(evt, object, canvas);

		return { stopPropagation: true };
	}
	onMouseLeave(evt, object, canvas) {
		this.stairConfiguratorSettings.onMouseLeavePossiblePosition(evt, object, canvas);
		return { stopPropagation: true };
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}
	push(stairConfiguration, params) {
		const referenceParams = { onChange: this.onChange.bind(this), checkCollisions: this._checkCollisions.bind(this), edit: this.edit.bind(this), remove: this.remove.bind(this) };
		this.stairs.push(new StairDynamic(stairConfiguration.place, stairConfiguration, params));
		this.stairs[this.stairs.length - 1].setReferences(referenceParams);
	}
	get length() {
		let count = 0;
		this.stairs.forEach((stair) => {
			if (stair.active === true) {
				count++;
			}
		});
		return count;
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._checkCollisions = params.checkCollisions;
		this._redraw = params.redraw;
		params.edit = this.edit.bind(this);
		params.remove = this.remove.bind(this);
		this.stairs.forEach((stair) => {
			stair.setReferences(params);
		});
	}
	removeReferences() {
		this._onChange = null;
		this._checkCollisions = null;
		this._redraw = null;
		this.stairs.forEach((stair) => {
			if (typeof stair.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				stair.removeReferences();
			}
		});
	}

	setEtageHeight(value) {
		this.stairs.forEach((stair) => {
			stair.setEtageHeight(value);
		});
	}
	afterReconstruct() {
		this.stairConfiguratorSettings.newStair = true;
		this.showConfigurator = false; // false slaat hij niet altijd op omdat cancel niet een wijziging in de configuratie tot gevolg heeft. Maar bij reconstruct altijd configurator verbergen
	}
	addUsedSurface() {
		this.stairs.forEach((stair, index) => {
			if (typeof stair.addUsedSurface === 'function') {
				stair.addUsedSurface();
			}
		});
	}
	containsStair(raster) {
		let contains = false;
		this.stairs.forEach((stair) => {
			if (stair.inRaster(raster) === true) {
				contains = true;
			}
		});
		return contains;
	}
	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params, actualSize) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params, actualSize); // voor overhang mainbeamlength zelfde als childbeamlength
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params, actualSize) {
		this.stairConfiguratorSettings.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params, actualSize);
	}
	collisionCheck() {
		this.stairs.forEach((stair, index) => {
			stair.collisionCheck();
		});
	}
	redraw() {
		if (this._redraw !== null && typeof this._redraw === 'function') {
			this._redraw();
		}
	}
	addDrawObjects(canvas, params) {
		let regenerate = false;
		this.stairs.forEach((stair, index) => {
			let result = stair.addDrawObjects(canvas);
			if (typeof result !== 'undefined' && result !== null) {
				canvas.addDrawObject(result.stair);
				if (result.regenerate === true) {
					regenerate = true;
				}
			}
		});
		return { regenerate: regenerate };
	}
	addDrawObjects3d(canvas3d, params, raster, posY, etageIndex) {
		this.stairs.forEach((stair, index) => {
			stair.addDrawObjects3d(canvas3d, params, raster, posY, etageIndex);
		});
	}
	create3DAssets() {
		this.stairs.forEach((stair, index) => {
			let stairMaterial = stair.material;

			if (stairMaterial !== null && typeof stairMaterial !== 'undefined') {
				// Trapboom
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('trapboom', stairMaterial.stinger));

				// Kickedge
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('kickedge_french_stair', stairMaterial.kickEdge));

				// Normaal paaltje
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('stair_handrail_pole', stairMaterial.handRailPost));

				// Kindvriendelijk paaltje
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('stair_childfriendly_pole', stairMaterial.childFriendlyHandRailPost));

				// Stairstep toevoegen
				if (stair.step.groupName === 'Grating') {
					// Omdat grating niet soepel tekent, en we alles met modellen uit ERP willen doen dan maar standaard model op deze manier ophalen.
					Canvas3D.CURRENT.addAsset(new ModelAsset3D('stairstep', 191));
				} else {
					Canvas3D.CURRENT.addAsset(new ModelAsset3D('stairstep', stair.step.articleId));
				}

				// Handrule
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('stair_handrule', stairMaterial.handRail));

				// Finish
				Canvas3D.CURRENT.addAsset(new ModelAsset3D('landingFinish', stairMaterial.landingFinish));
			}
		});

		// Yellownose nog niet in ERP.
		Canvas3D.CURRENT.addAsset(
			new LocalAsset3D('yellownose', null, {
				fallBackData: { depth: 55, height: 55, width: 1000 },
			}),
		);
	}

	findByProfilePosition(coordinates) {
		return this.stairs.filter((stair) => stair.onProfilePosition(coordinates) === true);
	}

	addPossiblePositions(canvas, params) {
		if (typeof this.stairConfiguratorSettings.addPossiblePositions === 'function') {
			this.stairConfiguratorSettings.addPossiblePositions(canvas, params, this);
		}
	}
	onRasterChanged(params) {
		this.stairs.forEach((stair) => {
			stair.onRasterChanged(params);
		});
	}
	calculateAmount(params) {
		this.stairs.forEach((stair) => {
			stair.calculateAmount(params);
		});
	}
	clearStairSettings() {
		this.stairConfiguratorSettings = new Stair();
	}
	createStair(object) {
		this.showConfigurator = false;

		this.stairConfiguratorSettings.oldValues = '';
		if (this.stairConfiguratorSettings.newStair === true) {
			this.stairConfiguratorSettings.width = this.stairConfiguratorSettings.stepWidth;
			this.stairConfiguratorSettings.place = object.activeTab.name; // Outside of inFloor
			this.stairConfiguratorSettings.stairOid = object.stair.stairOid;

			Configuration.CURRENT.setAccessoriesType('stairs', false);
			this.possiblePossitionsVisible = object.activeTab.name;

			Configuration.CURRENT.notification.show('stair.position', null, () => {
				Configuration.CURRENT.setAccessoriesType('');
				this.possiblePossitionsVisible = '';
			});
		} else {
			Configuration.CURRENT.setAccessoriesType('');
		}

		if (this.stairConfiguratorSettings.objectName === 'StairOutSide') {
			// Deze check is nodig voor wanneer we de endlanding uitzetten.
			// Als de trap dan op een hoek staat en niet op dezelfde positie als de trap zelf dan moeten we de trap dezelfde kant op zetten als de positie.
			if (this.stairConfiguratorSettings.endLanding.active === false && this.stairConfiguratorSettings.upComing !== this.stairConfiguratorSettings.position) {
				this.stairConfiguratorSettings.upComing = this.stairConfiguratorSettings.position;
				this.stairConfiguratorSettings.endLanding.upComing = this.stairConfiguratorSettings.position;
			}
		}

		if (this.stairConfiguratorSettings.intermediateLandings.length > 0) {
			this.stairConfiguratorSettings.intermediateLandings.sort();
			// Bij stair in floor is het niet mogelijk dat de eerste landing tegenovergesteld is.
			if (this.objectName === 'StairInFloor') {
				let firstLanding = this.stairConfiguratorSettings.intermediateLandings.get(0);
				if (firstLanding.upComing === this.stairConfiguratorSettings.upComing) {
					this.stairConfiguratorSettings.intermediateLandings.setUpComing(firstLanding, Stair.toOppositeUpComing(this.stairConfiguratorSettings.upComing));
				}
			}
			this.stairConfiguratorSettings.intermediateLandings.get().forEach((landing, index) => {
				let finish = landing.finish;
				let finishName;
				let finishHeight;
				if (finish !== null && typeof finish !== 'undefined' && this.stairConfiguratorSettings.newStair === false) {
					Store.CURRENT.deckingFinishes.getById(landing.finish, (findFinish) => {
						if (findFinish !== null && typeof findFinish !== 'undefined') {
							finish = findFinish.id;
							finishHeight = findFinish.height;
							finishName = findFinish.name;
						}
					});
					landing.finishName = finishName;
					landing.finishHeight = finishHeight;
				}
			});
		}
		this.stairConfiguratorSettings.calculate();
		this.stairConfiguratorSettings.onChange(true);

		// Na submit van edit-stair en endlanding is actief gezet en crossstair ook dan deze updaten.
		this.stairConfiguratorSettings.endLanding.update(
			this.stairConfiguratorSettings.crossStairWell,
			this.stairConfiguratorSettings.position,
			this.stairConfiguratorSettings.upComing,
			this.stairConfiguratorSettings.stepWidth,
			this.stairConfiguratorSettings.intermediateLandings,
		);
	}
	isPossible(spanX, spanY) {
		return true;
	}
	remove(item) {
		let foundIndex = -1;
		this.stairs.forEach((stair, index) => {
			if (stair.id === item.id) {
				foundIndex = index;
				if (typeof stair.removeUsedSurface === 'function') {
					stair.removeUsedSurface();
				}

				let columns = Configuration.CURRENT.columns;
				columns.removeByName(stair.id);
			}
		});
		this.stairs.splice(foundIndex, 1);

		this.onChange();
	}
	edit(item) {
		if (this.possiblePossitionsVisible !== '') {
			// om een of andere reden blijven hangen
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.notification.hide();
			this.onChange();
		}
		this.showConfigurator = true;
		this.stairConfiguratorSettings = item;
		this.stairConfiguratorSettings.newStair = false;
		let serializer = new Serializer();
		this.stairConfiguratorSettings.oldValues = serializer.stringify(this.stairConfiguratorSettings);
	}
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];
		this.stairs.forEach((stair) => {
			let stairResult = stair.collisions(boundaries, self);

			if (stairResult.result === true) {
				collisionsDetect = true;
				stairResult.errors.getAll().forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult };
	}
	moveProfilePossible(params) {
		let possible = true;
		this.stairs.forEach((stair) => {
			let possibleCheck = stair.moveProfilePossible(params);
			if (possibleCheck === false) {
				possible = false;
			}
		});
		return possible;
	}
	newStair(place, etageIndex, etageId) {
		if (this.possiblePossitionsVisible !== '') {
			// om een of andere reden blijven hangen
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.notification.hide();
			this.onChange();
		}

		let fps = Store.CURRENT.countries.getItem(Configuration.CURRENT.countryCode).fallingProtectionStandard;
		let fallingProtectionStandardRules = new Standard(fps);

		const width = Store.CURRENT.stairSteps.getWidthList().some((item) => item.value === fallingProtectionStandardRules.defaultStepWidth()) ? fallingProtectionStandardRules.defaultStepWidth() : '';

		this.showConfigurator = true;

		this.stairConfiguratorSettings = new StairDynamic(place, {
			newStair: true,
			stepWidth: width,
			width: width,
			upComing: 1,
			etageHeight:
				Configuration.CURRENT.etages.activeEtageIndex === 0 || place === 'inFloor'
					? Configuration.CURRENT.etages.activeEtage().getHeight()
					: Configuration.CURRENT.etages.getTotalHeight(Configuration.CURRENT.etages.activeEtageIndex, true),

			packetHeight: Configuration.CURRENT.etages.activeEtage().getPacketHeight(),
			type: Store.CURRENT.stairs.getDefaultStair().value,
			fallingProtectionStandard: fps,
			disabledUpcomings: [],
			etageId: etageId,
			etageIndex: etageIndex,
			crossStairWell: false,
		});

		this.stairConfiguratorSettings.createMinimalIntermediateLandings(etageIndex);

		let serializer = new Serializer();
		this.stairConfiguratorSettings.oldValues = serializer.stringify(this.stairConfiguratorSettings);
	}
	findByHandrailPosition(coordinates) {
		return this.stairs.filter((s) => s.active === true && s.onHandrailPosition(coordinates) === true);
	}
	areLandingsEqual(o1, o2) {
		return typeof o1 === 'object' && Object.keys(o1).length > 0 ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => this.areLandingsEqual(o1[p], o2[p])) : o1 === o2;
	}
	IntermediateLandingsAreEqual(foundStair, stair) {
		var listOfBool = [];
		for (var index = 0; index < foundStair.intermediateLandings.length; index++) {
			var result = this.areLandingsEqual(foundStair.intermediateLandings[index], stair.getAmountData().intermediateLandings[index]);
			listOfBool.push(result);
		}
		return listOfBool.every((element) => element === true);
	}
	getAmount() {
		let amount = { Stairs: [] };

		this.get().forEach((stair) => {
			if (stair.active) {
				var foundStairs = amount.Stairs.filter(
					(s) =>
						s.name === stair.getAmountData().name &&
						s.amountSteps === stair.getAmountData().amountSteps &&
						s.angle === stair.getAmountData().angle &&
						s.childbeam === stair.getAmountData().childbeam &&
						s.coated === stair.getAmountData().coated &&
						s.stepWidth === stair.getAmountData().stepWidth &&
						s.step === stair.getAmountData().step &&
						s.riser === stair.getAmountData().riser &&
						s.railType === stair.getAmountData().railType &&
						s.etageHeight === stair.getAmountData().etageHeight &&
						s.intermediateLandings.length === stair.getAmountData().intermediateLandings.length &&
						s.color === stair.getAmountData().color &&
						s.lengthStair === stair.getAmountData().lengthStair &&
						s.mainBeam === stair.getAmountData().mainBeam &&
						s.column === stair.getAmountData().column &&
						s.standard === stair.getAmountData().standard &&
						s.optional === stair.getAmountData().optional &&
						s.typeStair === stair.getAmountData().typeStair &&
						s.yellowNose === stair.getAmountData().yellowNose,
				);

				if (foundStairs.length === 0) {
					amount.Stairs.push(stair.getAmountData(false));
				} else {
					let result = [];
					foundStairs.forEach((s) => {
						if (s.intermediateLandings.length === 0) {
							foundStairs[0].amount++;
						} else {
							result = this.IntermediateLandingsAreEqual(s, stair);

							if (!result) {
								amount.Stairs.push(stair.getAmountData(false));
							} else {
								var list = [];

								foundStairs.forEach((element) => {
									for (var index = 0; index < element.intermediateLandings.length; index++) {
										var isEqual = this.areLandingsEqual(element.intermediateLandings[index], stair.getAmountData().intermediateLandings[index]);
										list.push(isEqual);
									}
								});

								var index = list.indexOf(true);
								if (index >= 0) {
									amount.Stairs[index].amount++;
								}
							}
						}
					});
				}
			}
		});

		return amount;
	}
	cancel(stair) {
		let serializer = new Serializer();
		this.stairConfiguratorSettings.update(serializer.parse(this.stairConfiguratorSettings.oldValues));
	}

	hasErrors() {
		let hasErrors = 0;
		// 'akker' over de errors per stair.
		this.stairs.forEach((stair, index) => {
			let objectHasErrors = stair.hasErrors;
			if (objectHasErrors === true) {
				hasErrors++;
			}
		});
		return hasErrors > 0;
	}
	getErrors() {
		let errors = new Errors();
		this.stairs.forEach((stair, index) => {
			let objectErrors = stair.getErrors();
			if (typeof objectErrors !== 'undefined' && objectErrors !== null) {
				objectErrors.getAll().forEach((error) => {
					error.source = 'Stair';
					error.sourceDescription = window.Vue.$translate('stair.list.title', { index: index + 1 });

					errors.push(error);
				});
			}
		});
		return errors;
	}

	getSelected() {
		let selected = [];

		this.stairs.forEach((stair) => {
			if (stair.selected === true) {
				selected.push(stair);
			}
		});

		return selected;
	}
}

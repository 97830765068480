import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
import {Columns} from "~/configurator/columns";
export class GussetPlateVertical3D extends Model3D {
	objectName = 'GussetPlateVertical3D';
	rotationY = 90;
	ralColor = 7016;
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.rowPositions = params.info.rowPositions;
		this.placement = params.info.placement;

		this.assetName = params.gussetPlate.name;
		this.oid = params.gussetPlate.oid;
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let gussetPlateWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
    let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

    this.z -= (gussetPlateWidth - columnWidth) / 2;
    this.y -= deckingFinishHeight;
    this.y -= this.mainBeamHeight;

		// Door geroteerd model opschuiven van deze gussetPlate
		this.z += gussetPlateWidth;

		super.draw(app);
	}
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b480af5 = () => interopDefault(import('..\\pages\\configurations\\index.vue' /* webpackChunkName: "pages/configurations/index" */))
const _9937ee06 = () => interopDefault(import('..\\pages\\configurator\\index.vue' /* webpackChunkName: "pages/configurator/index" */))
const _6669106e = () => interopDefault(import('..\\pages\\dashboard\\index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _37e9a6a8 = () => interopDefault(import('..\\pages\\invoices\\index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _64e63079 = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _66af836e = () => interopDefault(import('..\\pages\\passwordreset\\index.vue' /* webpackChunkName: "pages/passwordreset/index" */))
const _e8869ad0 = () => interopDefault(import('..\\pages\\projects\\index.vue' /* webpackChunkName: "pages/projects/index" */))
const _53a122ea = () => interopDefault(import('..\\pages\\quotations\\index.vue' /* webpackChunkName: "pages/quotations/index" */))
const _721f20ec = () => interopDefault(import('..\\pages\\request-new-password\\index.vue' /* webpackChunkName: "pages/request-new-password/index" */))
const _620ae55f = () => interopDefault(import('..\\pages\\vr-viewer\\index.vue' /* webpackChunkName: "pages/vr-viewer/index" */))
const _3dc1f8d2 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/configurations",
    component: _2b480af5,
    name: "configurations"
  }, {
    path: "/configurator",
    component: _9937ee06,
    name: "configurator"
  }, {
    path: "/dashboard",
    component: _6669106e,
    name: "dashboard"
  }, {
    path: "/invoices",
    component: _37e9a6a8,
    name: "invoices"
  }, {
    path: "/login",
    component: _64e63079,
    name: "login"
  }, {
    path: "/passwordreset",
    component: _66af836e,
    name: "passwordreset"
  }, {
    path: "/projects",
    component: _e8869ad0,
    name: "projects"
  }, {
    path: "/quotations",
    component: _53a122ea,
    name: "quotations"
  }, {
    path: "/request-new-password",
    component: _721f20ec,
    name: "request-new-password"
  }, {
    path: "/vr-viewer",
    component: _620ae55f,
    name: "vr-viewer"
  }, {
    path: "/",
    component: _3dc1f8d2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

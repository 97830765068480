import { HandRails } from '../../../configurator/handRails';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';
import { HandRailKickEdgeHolderLeft3D } from './kickedgeholder/HandRailKickEdgeHolderLeft3D';
import { HandRailKickEdgeHolderRight3D } from './kickedgeholder/HandRailKickEdgeHolderRight3D';
import { HandRailKickEdgeHolderBottom3D } from './kickedgeholder/HandRailKickEdgeHolderBottom3D';
import { HandRailKickEdgeHolderTop3D } from './kickedgeholder/HandRailKickEdgeHolderTop3D';
import { Configuration } from '../../../configurator/configuration';

export class HandRailKickEdge3D extends Model3D {
	objectName = 'HandRailKickEdge3D';
	constructor(x, y, z, params, direction, length, side) {
		super(x, y, z, params);
		this.width = params.length;
		this.oid = params.handRailMaterials.kickEdge;

		if (params.position === HandRails.TOP || params.position === HandRails.BOTTOM) {
			// Canvas3D.CURRENT.addDrawObject(new HandRailKickEdgeHolderLeft3D(x, y, z, params), Canvas3D.TYPE_HOLDER);
			// Canvas3D.CURRENT.addDrawObject(new HandRailKickEdgeHolderRight3D(x + params.length, y, z, params), Canvas3D.TYPE_HOLDER);
		} else {
			// Canvas3D.CURRENT.addDrawObject(new HandRailKickEdgeHolderTop3D(x, y, z, params), Canvas3D.TYPE_HOLDER);
			// Canvas3D.CURRENT.addDrawObject(new HandRailKickEdgeHolderBottom3D(x, y, z + params.length, params), Canvas3D.TYPE_HOLDER);
		}
	}
	draw(app) {
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');

		if (Configuration.CURRENT.colors.handRail.finish === 1 || Configuration.CURRENT.colors.handRail.finish === 2) {
			this.ralColor = 7036;
		} else {
			this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;
		}

		this.ralColor = 7036;

		if (this.position === HandRails.TOP) {
			this.z += poleWidth;
			this.x += poleWidth;
		} else if (this.position === HandRails.BOTTOM) {
			this.rotationY = 180;
			this.x += this.width;
		} else if (this.position === HandRails.LEFT) {
			this.rotationY = 90;
			this.z += this.length;
			this.x += poleWidth;
		} else if (this.position === HandRails.RIGHT) {
			this.rotationY = -90;
			this.z += poleWidth;
		}

		this.width -= poleWidth;

		super.draw(app);
	}
}

import { Rectangle } from '../draw/rectangle';

import { DrawValue } from '../draw/drawValue';

import { Configuration } from './configuration';
import { Serializer } from './serializer';
import { BuildingColumn } from './buildingColumn';
import { Errors } from './errors';
import { Canvas3D } from '../draw3d/Canvas3D';
import { Columns } from './columns';
export class BuildingColumns {
	objectName = 'BuildingColumns';
	buildingColumns = [];
	static COLORS = { notSelected: '#f2f2f2', possible: '#6ba3ff', mouseMove: '#00286d', selected: '#c2c9f0', collision: 'red', notOnEtage: '#cce6ff' };

	mouseMoveActive = null;
	mousePriority = 10;
	static drawOffset = { x: 3, y: 3 };
	mouseAreaOffset = { x: 10, y: 10 };
	showConfigurator = false;
	possiblePossitionsVisible = false;
	configuratorSettings = new BuildingColumn();

	constructor(onChange, checkCollisions, redraw) {
		this._onChange = onChange;
		this._checkCollisions = checkCollisions;
		this._redraw = redraw;
		setTimeout(() => {
			this.init();
		});
	}
	calculateAmount(params) {
		this.buildingColumns.forEach((buildingColumn) => {
			buildingColumn.calculateAmount(params);
		});
	}
	onClick(evt, object, canvas) {
		// op possible position geklikt

		// let width = parseInt(this.configuratorSettings.width);
		// let depth = parseInt(this.configuratorSettings.depth);
		if (object.objectParams.position === 'horizontal') {
			// kolom op de helft (kolomx1 + (kolomx2-kolomx1-breedte)/2) plaatsen
			this.configuratorSettings.x =
				Configuration.CURRENT.raster.getSizeX(object.objectParams.x - 1) +
				(Configuration.CURRENT.raster.getSizeX(object.objectParams.x) - Configuration.CURRENT.raster.getSizeX(object.objectParams.x - 1) - this.configuratorSettings.width) / 2;
			// standaard niet buiten tekening vallen
			// als links buiten de tekening dan op 0
			if (this.configuratorSettings.x < 0) {
				this.configuratorSettings.x = 0;
			}
			// als rechts buiten de tekening dan tot aan de rand
			if (this.configuratorSettings.x + this.configuratorSettings.width > Configuration.CURRENT.raster.getSizeX()) {
				this.configuratorSettings.x = Configuration.CURRENT.raster.getSizeX() - this.configuratorSettings.width;
			}
			// y-positie is die van het raster
			this.configuratorSettings.y = Configuration.CURRENT.raster.getSizeY(object.objectParams.y - 1);

			if (this.configuratorSettings.y > 0) {
				// niet eerste maar laatste rij dan diepte eraf
				this.configuratorSettings.y -= this.configuratorSettings.depth;
			}
		} else {
			// x-positie die van het raster
			this.configuratorSettings.x = Configuration.CURRENT.raster.getSizeX(object.objectParams.x - 1);
			if (this.configuratorSettings.x > 0) {
				// niet eerste maar laatste rij dan diepte eraf
				this.configuratorSettings.x -= this.configuratorSettings.width;
			}

			// kolom op de helft (kolomx1 + (kolomx2-kolomx1-breedte)/2) plaatsen
			this.configuratorSettings.y =
				Configuration.CURRENT.raster.getSizeY(object.objectParams.y - 1) +
				(Configuration.CURRENT.raster.getSizeY(object.objectParams.y) - Configuration.CURRENT.raster.getSizeY(object.objectParams.y - 1) - this.configuratorSettings.depth) / 2;
			// standaard niet buiten tekening vallen
			// als boven buiten de tekening dan op 0
			if (this.configuratorSettings.y < 0) {
				this.configuratorSettings.y = 0;
			}

			// als onder buiten de tekening dan tot aan de rand
			if (this.configuratorSettings.y + this.configuratorSettings.depth > Configuration.CURRENT.raster.getSizeY()) {
				this.configuratorSettings.y = Configuration.CURRENT.raster.getSizeY() - this.configuratorSettings.depth;
			}
		}
		this.create(this.configuratorSettings);
		Configuration.CURRENT.setAccessoriesType('');
		Configuration.CURRENT.redraw();
		return { stopPropagation: true };
	}
	onRasterChanged() {
		this.buildingColumns.forEach((buildingColumn) => {
			if (typeof buildingColumn.onRasterChanged === 'function') {
				buildingColumn.onRasterChanged();
			} else if (typeof buildingColumn.calculate === 'function') {
				buildingColumn.calculate();
			}
		});
	}
	// Word vanuit columns aangeroepen om de juiste hoogte van de kolom die bij de buildingColumn hoort op te halen.
	getColumnHeight(name) {
		// Substr de name, zodat we weten welke kolom we moeten selecteren.
		const side = name.substr(name.length - 1);
		// Strip name, want name = id van de building kolom + welke kolom we willen hebben.
		name = name.slice(0, -2);
		let totalHeight = 0;
		// Zoeken naar de BC op basis van de name van de column wat gelijk is aan de bc ID
		this.buildingColumns.find((buildingColumn) => {
			if (buildingColumn.id === name) {
				totalHeight = buildingColumn.getColumnHeight(side);
			}
		});
		return totalHeight;
	}

	remove(item) {
		let foundIndex = -1;
		Configuration.CURRENT.contextMenu.hide();
		this.buildingColumns.forEach((buildingColumn, index) => {
			if (typeof buildingColumn.removeUsedSurface === 'function') {
				buildingColumn.removeUsedSurface();
			}
			if (buildingColumn.id === item.id) {
				foundIndex = index;
			}
		});
		// Oude manier van kolommen voor gebouwkolom, dit kan op termijn er uit.
		Configuration.CURRENT.columns.removeByName(item.id);

		// Nieuwe manier van kolommen opslaan die bij gebouwkolom horen, dus deze ook los verwijderen.
		Configuration.CURRENT.columns.removeByName(item.id);
		Configuration.CURRENT.columns.removeByName(item.id + '_' + Columns.POSITION_TOP);
		Configuration.CURRENT.columns.removeByName(item.id + '_' + Columns.POSITION_BOTTOM);
		Configuration.CURRENT.columns.removeByName(item.id + '_' + Columns.POSITION_LEFT);
		Configuration.CURRENT.columns.removeByName(item.id + '_' + Columns.POSITION_RIGHT);

		this.buildingColumns.splice(foundIndex, 1);
		this.addUsedSurface();
		this.redraw();
		this.onChange();
	}
	edit(item) {
		if (this.possiblePossitionsVisible !== '') {
			// om een of andere reden blijven hangen
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.notification.hide();
			this.onChange();
		}
		this.showConfigurator = true;
		this.configuratorSettings = item;
		this.configuratorSettings.newBuildingColumn = false;
		let serializer = new Serializer();
		this.configuratorSettings.oldValues = serializer.stringify(this.configuratorSettings);
	}
	get(index) {
		if (typeof index !== 'undefined' && index !== null) {
			return this.buildingColumns[index];
		}
		return this.buildingColumns;
	}
	init() {
		if (typeof this.onMainBeamDirectionChange === 'function') {
			Configuration.CURRENT.profiles.onMainBeamDirectionChangeEvents.push((oldValue, newValue) => {
				this.onMainBeamDirectionChange(oldValue, newValue);
			});
		}
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}
	redraw() {
		if (typeof this._redraw === 'function') {
			this._redraw();
		}
	}
	get length() {
		return this.buildingColumns.length;
	}
	select(parameters, canvas) {
		this.buildingColumns.forEach((buildingColumn) => {
			buildingColumn.select(parameters, canvas);
		});
	}
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];

		this.buildingColumns.forEach((buildingColumn) => {
			let columnResult = buildingColumn.collisions(boundaries, self);

			if (columnResult.result === true) {
				collisionsDetect = true;
				columnResult.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult, objectName: this.objectName };
	}
	overlap(boundaries) {
		let overlap = false;

		this.buildingColumns.forEach((buildingColumn, index) => {
			if (buildingColumn.overlap(boundaries) === true) {
				overlap = true;
			}
		});

		return overlap;
	}
	collisionCheck() {
		let collisions = false;
		let errorResult = [];
		this.buildingColumns.forEach((buildingColumn, index) => {
			let collisionCheck = buildingColumn.collisionCheck();
			if (collisionCheck.result === true) {
				collisions = true;
				collisionCheck.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisions, errors: errorResult };
	}
	findByProfilePosition(coordinates, etageHeight) {
		return this.buildingColumns.filter((bc) => bc.onProfilePosition(coordinates, etageHeight) === true);
	}

	push(item) {
		item.setReferences({
			checkCollisions: this._checkCollisions.bind(this),
			edit: this.edit.bind(this),
			remove: this.remove.bind(this),
			onChange: this.onChange.bind(this),
			redraw: () => {
				this.redraw();
			},
		});
		this.buildingColumns.push(item);
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._redraw = params.redraw;
		this._checkCollisions = params.checkCollisions;
		params.edit = this.edit.bind(this);
		params.remove = this.remove.bind(this);
		params.onChange = this.onChange.bind(this);
		this.buildingColumns.forEach((buildingColumn, index) => {
			buildingColumn.setReferences(params);
		});
	}
	removeReferences() {
		this._onChange = null;
		this._checkCollisions = null;

		this.buildingColumns.forEach((buildingColumn, index) => {
			if (typeof buildingColumn.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				buildingColumn.removeReferences();
			}
		});
	}
	newBuildingColumn() {
		this.configuratorSettings = new BuildingColumn(null, Configuration.CURRENT.etages.totalHeight);

		if (this.buildingColumns.length > 0) {
			// kijk of er al een gebouwkolom is om breedte en diepte van over te nemen
			// niet meegeven in constructor omdat hij dan alles kopieert en alleen breedte en diepte nodig is. Niet de coordinaten
			let previousBuildingColumn = this.buildingColumns[this.buildingColumns.length - 1];
			this.configuratorSettings.width = previousBuildingColumn.width;
			this.configuratorSettings.depth = previousBuildingColumn.depth;
			this.configuratorSettings.height = previousBuildingColumn.height;
			this.configuratorSettings.customHeight = previousBuildingColumn.customHeight;
		}
		let serializer = new Serializer();
		this.configuratorSettings.oldValues = serializer.stringify(this.configuratorSettings);
		this.showConfigurator = true;
		Configuration.CURRENT.setAccessoriesType('buildingColumns', false);
	}
	create(object) {
		this.showConfigurator = false;
		if (object.newBuildingColumn === true) {
			if (object.x === '' && object.y === '') {
				Configuration.CURRENT.accessoriesType = 'buildingColumns';
				Configuration.CURRENT.redraw();
				return;
			}
			let buildingColumn = new BuildingColumn(this.configuratorSettings);
			this.push(buildingColumn);
		} else {
			object.update(object);
		}

		this.onChange();
	}
	cancel(object) {
		let serializer = new Serializer();
		this.configuratorSettings.update(serializer.parse(this.configuratorSettings.oldValues), false);
	}
	addUsedSurface() {
		this.buildingColumns.forEach((bc, index) => {
			if (typeof bc.addUsedSurface === 'function') {
				bc.addUsedSurface();
			}
		});
	}
	addPossiblePositions(canvas, params) {
		// ophalen actieve etage en kolommen
		const activeEtage = Configuration.CURRENT.etages.activeEtage();
		const columns = Configuration.CURRENT.columns;
		const lastRowId = activeEtage.raster.spansY.getSpans().length;
		const lastColumnId = activeEtage.raster.spansX.getSpans().length;
		// eerst de bovenrij en onderrij toevoegen
		activeEtage.raster.spansX.getSpans().forEach((spanX, indexX) => {
			// kolom in 1e en laatste rij opzoeken
			let firstRowColumn = columns.find(indexX, 0);
			let lastRowColumn = columns.find(indexX, lastRowId);
			if (firstRowColumn === null || lastRowColumn === null) {
				return; // volgende als een van beide kolommen niet gevonden
			}
			let firstRowColumnPosition = firstRowColumn.getPosition();
			let lastRowColumnPosition = lastRowColumn.getPosition();

			canvas.addDrawObject(
				new Rectangle(
					firstRowColumnPosition.endX,
					firstRowColumnPosition.startY,
					new DrawValue(spanX.value),
					new DrawValue(0, 5),
					BuildingColumns.COLORS.possible,
					BuildingColumns.COLORS.possible,
					BuildingColumns.COLORS.possible,
					true,
					this,
					{ x: indexX, y: 0, position: 'horizontal' },
				),
			);
			canvas.addDrawObject(
				new Rectangle(
					lastRowColumnPosition.endX,
					lastRowColumnPosition.startY,
					new DrawValue(spanX.value),
					new DrawValue(0, 5),
					BuildingColumns.COLORS.possible,
					BuildingColumns.COLORS.possible,
					BuildingColumns.COLORS.possible,
					true,
					this,
					{ x: indexX, y: lastRowId, position: 'horizontal' },
				),
			);
		});
		activeEtage.raster.spansY.getSpans().forEach((spanY, indexY) => {
			// kolom in 1e en laatste rasterkolom opzoeken
			let firstColumn = columns.find(0, indexY);
			let lastColumn = columns.find(lastColumnId, indexY);
			if (firstColumn === null || lastColumn === null) {
				return; // volgende als een van beide kolommen niet gevonden
			}
			let firstColumnPosition = firstColumn.getPosition();
			let lastColumnPosition = lastColumn.getPosition();

			canvas.addDrawObject(
				new Rectangle(
					firstColumnPosition.startX,
					firstColumnPosition.endY,

					new DrawValue(0, 5),
					new DrawValue(spanY.value),
					BuildingColumns.COLORS.possible,
					BuildingColumns.COLORS.possible,
					BuildingColumns.COLORS.possible,
					true,
					this,
					{ x: 0, y: indexY, position: 'vertical' },
				),
			);
			canvas.addDrawObject(
				new Rectangle(
					lastColumnPosition.startX,
					lastColumnPosition.endY,

					new DrawValue(0, 5),
					new DrawValue(spanY.value),
					BuildingColumns.COLORS.possible,
					BuildingColumns.COLORS.possible,
					BuildingColumns.COLORS.possible,
					true,
					this,
					{ x: lastColumnId, y: indexY, position: 'vertical' },
				),
			);
		});
	}
	addDrawObjects(canvas, params) {
		this.buildingColumns.forEach((buildingColumn, index) => {
			buildingColumn.addDrawObjects(canvas);
		});
	}
	addDrawObjects3d(canvas3d, raster) {
		this.buildingColumns.forEach((buildingColumn, index) => {
			buildingColumn.addDrawObjects3d(canvas3d, raster);
		});
	}
	hasErrors() {
		let hasErrors = 0;
		// 'akker' over de errors per stair.
		this.buildingColumns.forEach((buildingColumn, index) => {
			let objectHasErrors = buildingColumn.hasErrors;
			if (objectHasErrors === true) {
				hasErrors++;
			}
		});
		return hasErrors > 0;
	}
	getErrors() {
		let errors = new Errors();
		this.buildingColumns.forEach((buildingColumn, index) => {
			let objectErrors = buildingColumn.getErrors();
			if (typeof objectErrors !== 'undefined' && objectErrors !== null) {
				objectErrors.getAll().forEach((error) => {
					error.source = 'BuildingColumn';
					error.sourceDescription = window.Vue.$translate('buildingColumn.title', { index: index });
					errors.push(error);
				});
			}
		});
		return errors;
	}
	getSelected() {
		let selected = [];

		this.buildingColumns.forEach((buildingColumn) => {
			if (buildingColumn.selected === true) {
				selected.push(buildingColumn);
			}
		});

		return selected;
	}
}

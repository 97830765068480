/* eslint-disable no-new */
import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { Mathematic } from '../helpers/mathematic';
import { ObjectGroup } from '../draw/objectGroup';

import { Rectangle } from '../draw/rectangle';
import { Canvas3D } from '../draw3d/Canvas3D';

import { Stair3D } from '../draw3d/stairs/Stair3D';
import { Landing3D } from '../draw3d/stairs/landing/Landing3D';
import { Stair } from './stair';
import { Configuration } from './configuration';
import { RemoveRaster } from './removeRaster';
import { Stairs } from './stairs';
import { Profiles } from './profiles';
import { Columns } from './columns';
import { IntermediateLandings } from './intermediateLandings';

export class StairOutSide extends Stair {
	static POSITION_TOP = 1;
	static POSITION_RIGHT = 2;
	static POSITION_BOTTOM = 3;
	static POSITION_LEFT = 4;
	position = 0;
	objectName = 'StairOutSide';
	place = 'outside';
	sizeHandle = null;
	landingBoundaries = [];
	constructor(newStairConfiguration, raster) {
		super(newStairConfiguration, raster);
		if (typeof this.stairWell !== 'undefined' && this.stairWell !== null) {
			this.stairWell.active = false;
		}
		if (typeof raster !== 'undefined' && raster !== null) {
			this.position = raster.position;

			const startX = Configuration.CURRENT.raster.getSizeX(raster.x - 1);
			const startY = Configuration.CURRENT.raster.getSizeY(raster.y - 1);
			const lengthRasterX = Configuration.CURRENT.raster.spansX.get(raster.x).value;
			const lengthRasterY = Configuration.CURRENT.raster.spansY.get(raster.y).value;
			const totalLengthRasterX = Configuration.CURRENT.raster.getSizeX();
			const totalLengthRasterY = Configuration.CURRENT.raster.getSizeY();
			switch (this.position) {
				case StairOutSide.POSITION_TOP:
					if (lengthRasterX <= this.stairDrawWidth) {
						if (totalLengthRasterX < this.startX + this.stairDrawWidth) {
							// als vanaf begin raster tot eind tekening smaller is dan plaatsen aan eind van raster
							this.startX = totalLengthRasterX - this.stairDrawWidth;
						} else {
							this.startX = startX;
						}
					} else {
						this.startX = startX + Math.floor((lengthRasterX - this.stairDrawWidth) / 2);
					}
					this.startY = startY - 1; // 1 mm erbij om collision met kolomm te voorkomen

					break;
				case StairOutSide.POSITION_LEFT:
					if (lengthRasterY <= this.stairDrawWidth) {
						if (totalLengthRasterY < this.startY + this.stairDrawWidth) {
							// als vanaf begin raster tot eind tekening smaller is dan plaatsen aan eind van raster
							this.startY = totalLengthRasterY - this.stairDrawWidth;
						} else {
							this.startY = startY;
						}
					} else {
						this.startY = startY + Math.floor((lengthRasterY - this.stairDrawWidth) / 2);
					}
					this.startX = startX - 1; // 1 mm erbij om collision met kolomm te voorkomen

					break;
				case StairOutSide.POSITION_RIGHT:
					if (lengthRasterY <= this.stairDrawWidth) {
						if (totalLengthRasterY < this.startY + this.stairDrawWidth) {
							// als vanaf begin raster tot eind tekening smaller is dan plaatsen aan eind van raster
							this.startY = totalLengthRasterY - this.stairDrawWidth;
						} else {
							this.startY = startY;
						}
					} else {
						this.startY = startY + Math.floor((lengthRasterY - this.stairDrawWidth) / 2);
					}
					this.startX = startX + lengthRasterX + 1; // 1 mm erbij om collision met kolomm te voorkomen

					break;
				case StairOutSide.POSITION_BOTTOM:
					if (lengthRasterX <= this.stairDrawWidth) {
						if (totalLengthRasterX < this.startX + this.stairDrawWidth) {
							// als vanaf begin raster tot eind tekening smaller is dan plaatsen aan eind van raster
							this.startX = totalLengthRasterX - this.stairDrawWidth;
						} else {
							this.startX = startX;
						}
					} else {
						this.startX = startX + Math.floor((lengthRasterX - this.stairDrawWidth) / 2);
					}
					this.startY = startY + lengthRasterY + 1; // 1 mm erbij om collision met kolomm te voorkomen

					break;
			}
			this.stairWell.startY = this.startY;
			this.stairWell.startX = this.startX;

			this.validate();
			this.findRaster();
		}
	}
	findRaster() {
		let startX = this.startX;
		let startY = this.startY;
		switch (this.position) {
			case StairOutSide.POSITION_TOP:
				startY += 5; // 5 mm erbij om goed raster te kunnen selecteren

				break;
			case StairOutSide.POSITION_LEFT:
				startX += 5; // 5 mm erbij om goed raster te kunnen selecteren

				break;
			case StairOutSide.POSITION_RIGHT:
				startX -= 5; // 5 mm eraf om goed raster te kunnen selecteren

				break;
			case StairOutSide.POSITION_BOTTOM:
				startY -= 5; // 5 mm eraf om goed raster te kunnen selecteren

				break;
		}
		if (this.position === StairOutSide.POSITION_TOP || this.position === StairOutSide.POSITION_BOTTOM) {
			// bepaal bijbehorend raster linksboven
			const rasterLeftTop = Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY);
			this.rasters = [];
			this.insertUniqueRaster(rasterLeftTop);
			// bepaal bijbehorend raster rechtsboven
			const rasterRightTop = Configuration.CURRENT.raster.getRasterByCoordinate(startX + this.width - 5, startY); // -5 om binnen raster te vallen
			this.insertUniqueRaster(rasterRightTop);
			// bepaal bijbehorend raster rechtsboven
			const rasterLeftBottom = Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY);
			this.insertUniqueRaster(rasterLeftBottom);
			// bepaal bijbehorend raster rechtsonder
			const rasterRightBottom = Configuration.CURRENT.raster.getRasterByCoordinate(startX + this.width - 5, startY);
			this.insertUniqueRaster(rasterRightBottom);
		} else {
			const rasterLeftTop = Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY);
			this.rasters = [];
			this.insertUniqueRaster(rasterLeftTop);
			// bepaal bijbehorend raster rechtsboven
			const rasterRightTop = Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY);
			this.insertUniqueRaster(rasterRightTop);
			// bepaal bijbehorend raster rechtsboven
			const rasterLeftBottom = Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY + this.width - 5);
			this.insertUniqueRaster(rasterLeftBottom);
			// bepaal bijbehorend raster rechtsonder
			const rasterRightBottom = Configuration.CURRENT.raster.getRasterByCoordinate(startX, startY + this.width - 5);

			this.insertUniqueRaster(rasterRightBottom);
		}
		if (this.rasters.length === 0) {
			this.rasters.push(this.startRaster);
		}
	}
	onMouseDrag(evt, object, canvas, params) {
		let moveX = evt.delta.x / canvas.scaleFactor; // verplaatsing links-rechts (links - rechts +)
		let moveY = evt.delta.y / canvas.scaleFactor; // verplaatsing onder-boven (boven - onder +)
		const minMaxCoordinates = this.minMax(object);

		let minX = 0;
		let minY = 0;
		let maxX = Configuration.CURRENT.raster.spansX.getSize();
		let maxY = Configuration.CURRENT.raster.spansY.getSize();
		if (this.rasters.length > 0) {
			let xRaster = this.rasters[0].x;
			let yRaster = this.rasters[0].y;
			switch (this.position) {
				case StairOutSide.POSITION_TOP:
					let findRasterLeft = -1;

					if (xRaster > 0) {
						let activeFound = false;
						for (let i = xRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster - 1)) === true) {
								findRasterLeft = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterLeft = i;
								activeFound = true;
							}
						}

						let maxRasterX = Configuration.CURRENT.raster.spansX.length;
						let findRasterRight = maxRasterX;

						activeFound = false;

						for (let i = xRaster + 1; i <= maxRasterX && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster - 1)) === true) {
								findRasterRight = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterRight = i;
								activeFound = true;
							}
						}

						minX = Configuration.CURRENT.raster.spansX.getSize(findRasterLeft);
						maxX = Configuration.CURRENT.raster.spansX.getSize(findRasterRight - 1);
					}

					break;
				case StairOutSide.POSITION_BOTTOM:
					findRasterLeft = -1;

					if (xRaster > 0) {
						let activeFound = false;
						for (let i = xRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster + 1)) === true) {
								findRasterLeft = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterLeft = i;
								activeFound = true;
							}
						}
					}

					let maxRasterX = Configuration.CURRENT.raster.spansX.length;
					let findRasterRight = maxRasterX;
					if (xRaster < maxRasterX) {
						let activeFound = false;

						for (let i = xRaster + 1; i <= maxRasterX && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster + 1)) === true) {
								findRasterRight = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(i, yRaster)) === false) {
								findRasterRight = i;
								activeFound = true;
							}
						}
					}
					minX = Configuration.CURRENT.raster.spansX.getSize(findRasterLeft);
					maxX = Configuration.CURRENT.raster.spansX.getSize(findRasterRight - 1);

					break;
				case StairOutSide.POSITION_LEFT:
					let findRasterTop = -1;

					if (yRaster > 0) {
						let activeFound = false;
						for (let i = yRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster - 1, i)) === true) {
								findRasterTop = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterTop = i;
								activeFound = true;
							}
						}
					}

					let maxRasterY = Configuration.CURRENT.raster.spansY.length;
					let findRasterBottom = maxRasterY;
					if (yRaster < maxRasterY) {
						let activeFound = false;

						for (let i = yRaster + 1; i <= maxRasterY && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster - 1, i)) === true) {
								findRasterBottom = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterBottom = i;
								activeFound = true;
							}
						}
					}
					minY = Configuration.CURRENT.raster.spansY.getSize(findRasterTop);
					maxY = Configuration.CURRENT.raster.spansY.getSize(findRasterBottom - 1);

					break;

				case StairOutSide.POSITION_RIGHT:
					findRasterTop = -1;

					if (yRaster > 0) {
						let activeFound = false;
						for (let i = yRaster - 1; i >= 0 && activeFound === false; i--) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster + 1, i)) === true) {
								findRasterTop = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterTop = i;
								activeFound = true;
							}
						}
					}

					maxRasterY = Configuration.CURRENT.raster.spansY.length;
					findRasterBottom = maxRasterY;
					if (yRaster < maxRasterY) {
						let activeFound = false;

						for (let i = yRaster + 1; i <= maxRasterY && activeFound === false; i++) {
							// zoek links naar actief
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster + 1, i)) === true) {
								findRasterBottom = i;
								activeFound = true;
							}
							if (Configuration.CURRENT.etages.activeEtage().isActiveRaster(new RemoveRaster(xRaster, i)) === false) {
								findRasterBottom = i;
								activeFound = true;
							}
						}
					}
					minY = Configuration.CURRENT.raster.spansY.getSize(findRasterTop);
					maxY = Configuration.CURRENT.raster.spansY.getSize(findRasterBottom - 1);

					break;
			}
		}
		if (this.position === StairOutSide.POSITION_LEFT || this.position === StairOutSide.POSITION_RIGHT) {
			if (minMaxCoordinates.min.y + moveY < minY) {
				// als y kleiner dan 0 dan tot aan 0

				moveY = minY - minMaxCoordinates.min.y;
				evt.delta.y = moveY * canvas.scaleFactor;
			}

			if (minMaxCoordinates.max.y + moveY > maxY) {
				// als y groter dan totale lengte dan tot aan totale lengte

				moveY = maxY - minMaxCoordinates.max.y;
				evt.delta.y = moveY * canvas.scaleFactor;
			}
			this.sizeHandle.start += moveY;
		} else {
			if (minMaxCoordinates.min.x + moveX < minX) {
				// als x kleiner dan 0 dan tot aan 0
				moveX = minX - minMaxCoordinates.min.x;
				evt.delta.x = moveX * canvas.scaleFactor;
			}

			if (minMaxCoordinates.max.x + moveX > maxX) {
				// als x groter dan totale lengte dan tot aan totale lengte

				moveX = maxX - minMaxCoordinates.max.x;
				evt.delta.x = moveX * canvas.scaleFactor;
			}
			this.sizeHandle.start += moveX;
		}

		// Bij stairoutside dus wel gewoon hele object bewegen omdat we daar toch geen trimming hoeven te berekenen etc.
		object.drawObjects.forEach((drawObject) => {
			if (this.position === StairOutSide.POSITION_LEFT || this.position === StairOutSide.POSITION_RIGHT) {
				drawObject.y.value += moveY; // naar boven/onder verplaatsen
				if (drawObject.objectParams.type === 'stairRight' || drawObject.objectParams.type === 'stairLeft') {
					drawObject.eindY.value += moveY;
				}
			} else {
				drawObject.x.value += moveX; // naar boven/onder verplaatsen
				if (drawObject.objectParams.type === 'stairRight' || drawObject.objectParams.type === 'stairLeft') {
					drawObject.eindX.value += moveX;
				}
			}
		});

		// na lus op collissions checken
		let collisions = false;
		let collisionCheck = this.checkCollisions(this.boundaries, this);

		// Deze check is nodig, wanneer er een hot reload in development is en er is een stair geselecteerd is kan deze in de drag functie blijven hangen.
		if (typeof collisionCheck !== 'undefined' && collisionCheck !== null) {
			if (collisionCheck.result === true) {
				collisions = true;
			}
			this.setCollisionDrawObject(object.drawObjects, collisions);

			this.setObjectSizeHandle(canvas, true);
		}
	}
	setCollisionDrawObject(drawObjects, collisions) {
		drawObjects.forEach((drawObject) => {
			// na collisions-check nogmaals objecten doorlopen om kleur goed te zetten.
			if (collisions === true) {
				drawObject.lineColor = Stairs.COLORS.stairCollisions;
				if (drawObject.objectParams.type !== 'stair') {
					// stairWell is leegvak. Moet zo blijven andere moeten rood worden
					drawObject.fillColor = Stairs.COLORS.stairCollisions;
				}
			} else {
				drawObject.lineColor = drawObject.object.selected === true ? drawObject.objectParams.selected : drawObject.objectParams.color;
				if (drawObject.objectParams.type !== 'stair') {
					drawObject.fillColor = drawObject.object.selected === true ? drawObject.objectParams.selected : drawObject.objectParams.color;
				}
			}
		});
	}
	removeLandingsFromEtage(etageId) {
		this.intermediateLandings.intermediateLandings.forEach((il, index) => {
			if (il.etageId === etageId) {
				this.intermediateLandings.intermediateLandings.splice(index, 1);
			}
		});
	}

	setEtageHeight(value, etageIndex) {
		this.etageHeight = value;
		this.etageIndex = etageIndex;
		this.createMinimalIntermediateLandings(etageIndex);
		this.validate();
	}
	// Doorverwijzen naar intermediatelandings
	recalculateLandingHeights() {
		if (this.crossStairWell === true) {
			this.intermediateLandings.recalculateLandingHeights(this);
		}
	}
	createMinimalIntermediateLandings(etageIndex) {
		// Haalt alleen etages op onder zijn huidige etage, en ook huidige.
		let etages = Configuration.CURRENT.etages.getEtagesUnderIndex(this.etageIndex, true);
		let extraHeight = 0;
		etages.forEach((etage, index) => {
			// bereken het aantal tussenbordessen : hoogte etage delen door maxhoogte landing
			// uitrekenen hoeveel landings er nodig zijn op de huidge etage
			let amountofLandings = Math.ceil(etage.height / this._fallingProtectionStandardRules.getMaxHeightLanding(etage.height, this.angle)) - 1;

			// Landings ophalen die zelf zijn toegevoegd
			let amountofLandingsAddedCustom = this.intermediateLandings.intermediateLandings.filter(
				(il) => il.etageHeightLanding === false && il.fallingProtectionStandardAdded === false && il.etageId === etage.id,
			);

			// Landings ophalen die automatisch zijn gegenereerd
			let amountofLandingsAddedAutomatic = this.intermediateLandings.intermediateLandings.filter(
				(il) => il.fallingProtectionStandardAdded === true && il.etageId === etage.id && il.etageHeightLanding === false,
			);

			// Verwijderen wanneer er teveel landings zijn. bijvoorbeeld bij kleiner maken etagehoogte.
			// We gooien dan alleen de landings weg die automatisch zijn toegevoegd toen dat wel nodig was.
			if (amountofLandingsAddedCustom.length + amountofLandingsAddedAutomatic.length - amountofLandings > 0) {
				for (let i = 0; i < amountofLandingsAddedAutomatic.length; i++) {
					this.intermediateLandings.intermediateLandings.forEach((landing) => {
						if (landing.id === amountofLandingsAddedAutomatic[i].id && landing.fallingProtectionStandardAdded === true) {
							this.intermediateLandings.remove(landing);
						}
					});
				}
			}

			// Zoek de tussenbordessen op die bij deze etage horen maar die niet op de hoogte van de etage zitten.
			let currentLandings = this.intermediateLandings.intermediateLandings.filter((il) => il.etageHeightLanding === false && il.etageId === etage.id);

			// bereken de hoogtes van de bordessen die er tussen komen: etageHeight delen amountOfLandings + 1
			let totalSteps = Math.ceil(etage.height / 180); // 30
			let distanceSteps = Math.ceil(etage.height / totalSteps); // 174 ofzo

			let spaceAboveAndUnder = etage.height / (amountofLandings + 1); // 2000
			let heightBordes = Math.ceil(spaceAboveAndUnder / distanceSteps); // 13,095

			let landingHeight = heightBordes * distanceSteps;

			if (amountofLandings === 0) {
				landingHeight = landingHeight / 2;
			}

			// landing start op de hoogte van de vorige landing of van de grond
			let currentLandingHeight = extraHeight;

			// kijken of er nog steeds te weinig landings zijn.
			if (currentLandings.length < amountofLandings) {
				for (let i = currentLandings.length; i < amountofLandings; i++) {
					this.addLandingOnSpecificHeight(currentLandingHeight + landingHeight, false, etage.id, true);
					currentLandingHeight += landingHeight;
				}
			}

			let etageLanding = this.intermediateLandings.intermediateLandings.filter((il) => il.etageHeightLanding === true && il.etageId === etage.id); // zoek eerst het tussenbordes op die bij deze etage hoort. Die moet dan de hoogte van de etage krijgen
			if (etageLanding.length > 0) {
				// bordes van de etage gevonden dan hoogte updaten naar correcte hoogte
				etageLanding[0].height = extraHeight + etage.height;
			} else if (index + 1 !== etages.length) {
				// Als het niet de laatste etage is.
				// nog geen hoogte van de etage gevonden en het is ook niet de laatste etage dan op die hoogte een etage toevoegen.
				// bij de laatste etage hoeft dit niet. Daar kan eventueel een eindbordes worden toegevoegd
				this.addLandingOnSpecificHeight(etage.height + extraHeight, true, etage.id, true); // ook meegeven etageId + vastleggen
			}

			currentLandings.forEach((landing, index) => {
				landing.height = currentLandingHeight + landingHeight;
				// onthouden "nieuwe" hoogte van deze landing
				currentLandingHeight += landingHeight;
			});

			extraHeight += etage.height;
		});
		// Na automatisch toevoegen alle landings dan updaten.
		this.intermediateLandings.updateAllLandings(this);
		this.intermediateLandings.sort();
	}

	setUpComing(value) {
		super.setUpComing(value);
	}
	addLandingOnSpecificHeight(height, etageHeightLanding, etageId, fallingProtectionStandard = false) {
		let width = 0;
		let depth = 0;
		let finish = null;
		let ctc = null;
		let upComing = this.upComing;
		let landingType = null;

		if (typeof this._fallingProtectionStandardRules === 'object') {
			width = this._fallingProtectionStandardRules.getMinWidthLanding(this.stepWidth);
			depth = this._fallingProtectionStandardRules.getMinDepthLanding(this.stepWidth);
		}
		let lastLanding = null;
		if (this.crossStairWell === true) {
			lastLanding = this.intermediateLandings.getLast(height);
		} else {
			lastLanding = this.intermediateLandings.getLast();
		}

		// Dan kijken we naar de vorige landing als die er is.
		// Zo ja, dan nemen we de upcoming en data van de vorige landing over. (Hij wijst dan dezelfde kant op).
		if (lastLanding !== null && typeof lastLanding !== 'undefined') {
			upComing = lastLanding.upComing;
			landingType = lastLanding.landingType;
			finish = lastLanding.finish;
			ctc = lastLanding.ctc;

			// Normaal neemt hij de vorige landing upComing over,
			// Maar bij corssstairwell dan moeten juist het tegenovergestelde gebruiken van de vorige landing.
			if (this.crossStairWell) {
				upComing = Stair.toOppositeUpComing(upComing);
				landingType = IntermediateLandings.oneeightyDegrees;
			}
		}
		// Wanneer geen vorige landing. Dus eerste direct automatisch toegevoegd.
		else {
			// Wanneer crosStairwell dan krijgen de landings minimale breedte en diepte.

			if (this.crossStairWell === true) {
				width = this.stepWidth * 2 + 100;
				landingType = IntermediateLandings.oneeightyDegrees;
				// Wanneer op specifieke hoogte geen vorige is dan pakken we de opposite van de stairUpcoming
				upComing = Stair.toOppositeUpComing(upComing);
			} else {
				// Pakken altijd dezelfde kant als stair upComing dus straigthAhead.
				landingType = IntermediateLandings.straightAhead;
			}
		}

		// Omdat we er ineens 1 toevoegen tussenin moeten we de landings na deze landing updaten.
		this.intermediateLandings.addLanding(height, width, depth, upComing, fallingProtectionStandard, etageHeightLanding, etageId, landingType, finish, ctc);
	}
	minMax(object) {
		Configuration.CURRENT.showEditModus = false;
		let min = { x: 0, y: 0 };
		let max = { x: 0, y: 0 };
		let objectFound = false;
		if (typeof object.drawObjects !== 'undefined') {
			object.drawObjects.forEach((drawObject) => {
				if ((drawObject.objectParams.type === 'stair' || drawObject.objectParams.type === 'stairLanding') && objectFound === false) {
					// return om foreach af te breken lukt niet. daarom met objectFound eerste stair of eindbordes opzoeken

					objectFound = true;
					min.x = drawObject.x.value;
					min.y = drawObject.y.value;
					max.x = drawObject.x.value + drawObject.width.value;
					max.y = drawObject.y.value + drawObject.height.value;
				}
			});
		}

		return { min: min, max: max, object: 'stairOutside' };
	}
	onMouseUp(evt, object, canvas, params) {
		let minMaxCoordinates = this.minMax(object);
		if (this.position === Stair.POSITION_LEFT || this.position === Stair.POSITION_RIGHT) {
			if (this.endLanding.active === true) {
				// Bij links gaat iets vreemds mis, zonder deze check doet hij iets anders dan gewenst.
				if (this.position === Stair.POSITION_LEFT) {
					this.startY = minMaxCoordinates.min.y + (this.endLanding.width - this.stepWidth) / 2;
				} else {
					this.startY = minMaxCoordinates.min.y;
				}
			} else {
				this.startY = minMaxCoordinates.min.y;
			}
		} else if (this.position === Stair.POSITION_TOP || this.position === Stair.POSITION_BOTTOM) {
			if (this.endLanding.active === true) {
				this.startX = minMaxCoordinates.min.x + (this.endLanding.width - this.stepWidth) / 2;
			} else {
				this.startX = minMaxCoordinates.min.x;
			}
		}

		this.findRaster();
		this.onChange();
		return { stopPropagation: true };
	}
	onMouseMovePossiblePosition(evt, object, canvas) {
		object.lineColor = Stairs.COLORS.possiblePositionOutSideSelected;
		return { stopPropagation: true };
	}
	onMouseLeavePossiblePosition(evt, object, canvas) {
		object.lineColor = Stairs.COLORS.possiblePositionOutSide;
		return { stopPropagation: true };
	}
	onClickPossiblePosition(evt, object, canvas, params, stairSettings, stairs) {
		stairs.possiblePossitionsVisible = '';
		Configuration.CURRENT.notification.hide();
		stairSettings.newStair = false;

		// Opslaan aan welke kant van de vloer de trap komt te staan.
		this.position = object.objectParams.position;

		// Wanneer endlanding niet actief is dan updaten we ook de positie van de upcoming naar het geklikte gebied.
		if (stairSettings.endLanding.active === false) {
			this.setUpComing(object.objectParams.position);
			this.endLanding.setUpComing(object.objectParams.position);
		} else if (stairSettings.endLanding.active && stairSettings.endLanding.upComing === Stair.toOppositeUpComing(object.objectParams.position)) {
			// Wanneer endlanding actief en de geklikte postitie is het tegenovergestelde van de endlanding upcoming,
			// dan updaten we de endlanding upcoming naar het de gekozen positie.
			this.endLanding.setUpComing(object.objectParams.position);
		}

		// Updaten Endlanding breedte bij crossstairwell.
		this.endLanding.update(stairSettings.crossStairWell, object.objectParams.position, stairSettings.upComing, stairSettings.stepWidth, stairSettings.IntermediateLandings);

		// Wanneer geplaatst moet de upcoming keuze voor de endlanding die tegenovergesteld is van gekozen positie disabled zijn.
		this.endLanding.disabledUpcomings = [Stair.toOppositeUpComing(object.objectParams.position)];

		// Updaten van landings
		this.intermediateLandings.updateAllLandings(this);

		stairs.push(stairSettings, object.objectParams);
		Configuration.CURRENT.setAccessoriesType('');
		Configuration.CURRENT.etages.activeEtage().stairs.clearStairSettings();
		stairs.onChange(true);
	}

	getEdgePosition() {
		let startX = this.startX;
		let startY = this.startY;
		let endX = this.startX;
		let endY = this.startY;

		switch (this.position) {
			case StairOutSide.POSITION_TOP:
				startY += 1 - Configuration.CURRENT.overhang.size;
				if (this.endLanding.active === true) {
					startX = this.endLanding.boundary.topLeft.x;
					endX = this.endLanding.boundary.topLeft.x + this.endLanding.boundary.topRight.x - this.endLanding.boundary.topLeft.x;
				} else {
					endX += this.width;
				}
				break;
			case StairOutSide.POSITION_LEFT:
				startX += 1 - Configuration.CURRENT.overhang.size;
				if (this.endLanding.active === true) {
					startY = this.endLanding.boundary.topLeft.y;
					endY = this.endLanding.boundary.topLeft.y + this.endLanding.boundary.bottomLeft.y - this.endLanding.boundary.topLeft.y;
				} else if (this.landings.length >= 1 && this.landings.some((landing) => landing.landingType === IntermediateLandings.oneeightyDegrees)) {
          // wanneer er een 180 graden landing aanwezig is, altijd de eerste boundary pakken (is namelijk de trap die tegen de vloer aanstaat)
          // // in de code wordt dus voor boundaries + 1 gedaan
          startX = this.boundaries[0].topRight.x + 1;
          startY = this.boundaries[0].topRight.y;
          endX = this.boundaries[0].bottomRight.x + 1;
          endY = this.boundaries[0].bottomRight.y;
        } else {
					endY += this.width;
				}
				break;
			case StairOutSide.POSITION_RIGHT:
				startX += Configuration.CURRENT.overhang.size - 1;
				if (this.endLanding.active === true) {
					startY = this.endLanding.boundary.topLeft.y;
					endY = this.endLanding.boundary.topLeft.y + this.endLanding.boundary.bottomLeft.y - this.endLanding.boundary.topLeft.y;
				} else {
					endY += this.width;
				}
				break;
			case StairOutSide.POSITION_BOTTOM:
				startY += Configuration.CURRENT.overhang.size - 1;
				if (this.endLanding.active === true) {
					startX = this.endLanding.boundary.topLeft.x;
					endX = this.endLanding.boundary.topLeft.x + this.endLanding.boundary.topRight.x - this.endLanding.boundary.topLeft.x;
				} else if (this.landings.length >= 1 && this.landings.some((landing) => landing.landingType === IntermediateLandings.oneeightyDegrees)) {
          // wanneer er een 180 graden landing aanwezig is, altijd de eerste boundary pakken (is namelijk de trap die tegen de vloer aanstaat)
          startX = this.boundaries[0].topLeft.x;
          // in de code wordt dus voor boundaries + 1 gedaan
          startY = this.boundaries[0].topLeft.y - 1;
          endX = this.boundaries[0].topRight.x;
          endY = this.boundaries[0].topRight.y;
        } else {
					endX += this.width;
				}
				break;
		}
		return { startX: startX, startY: startY, endX: endX, endY: endY };
	}
	onHandrailPosition(handRailPosition) {
		let edgePosition = this.getEdgePosition();
		if (edgePosition.startX === handRailPosition.startX.value) {
			// Verticale handrail en op de juiste positie
			return (
				(edgePosition.startY >= handRailPosition.startY.value && edgePosition.startY <= handRailPosition.endY.value) ||
				(edgePosition.endY >= handRailPosition.startY.value && edgePosition.endY <= handRailPosition.endY.value)
			);
		} else if (edgePosition.startY === handRailPosition.startY.value) {
			// Horizontale handrail en op de juiste hoogte
			return (
				(edgePosition.startX >= handRailPosition.startX.value && edgePosition.startX <= handRailPosition.endX.value) ||
				(edgePosition.endX >= handRailPosition.startX.value && edgePosition.endX <= handRailPosition.endX.value)
			);
		}
	}
	onProfilePosition(profilePosition) {
		// Always return false, because stairoutside has no collision with beams anyway
		return false;
	}
	changeEndLandingActive(value) {
		// Alleen updaten als nodig
		if (value !== this.endLanding.active) {
			this.endLanding.active = value;
			// Bij crossstairwell altijd endlanding nodig, als deze dan uitgezet word dan crossstairwell ook uit zetten.
			if (this.crossStairWell === true && value === false) {
				this.crossStairWell = false;
				//? Landings die grotere breedte hebben dan weer kleiner maken?
			}
		}
	}
	setObjectSizeHandle(canvas, drag = false) {
		if (this.sizeHandle === null) {
			return;
		}

		let objectSizeHandle = canvas.sizeHandles.get('object');
		if (objectSizeHandle !== null && typeof objectSizeHandle !== 'undefined') {
			if (this.position === StairOutSide.POSITION_LEFT || this.position === StairOutSide.POSITION_RIGHT) {
				if (drag) {
					objectSizeHandle.updateDrawObjects(
						[],
						[Math.round(this.sizeHandle.start), Math.round(this.sizeHandle.width), Math.round(this.sizeHandle.lengthRaster - this.sizeHandle.start - this.sizeHandle.width)],
					);
				} else {
					const lengthX = Configuration.CURRENT.raster.spansX.getSize();
					const lengthY = Configuration.CURRENT.raster.spansY.getSize();
					this.setSizeHandleObject(canvas, this.getHorizontalDimensions(lengthX), this.getVerticalDimensions(lengthY));
				}
			} else {
				if (drag) {
					objectSizeHandle.updateDrawObjects(
						[Math.round(this.sizeHandle.start), Math.round(this.sizeHandle.width), Math.round(this.sizeHandle.lengthRaster - this.sizeHandle.start - this.sizeHandle.width)],
						[],
					);
				} else {
					const lengthX = Configuration.CURRENT.raster.spansX.getSize();
					const lengthY = Configuration.CURRENT.raster.spansY.getSize();
					this.setSizeHandleObject(canvas, this.getHorizontalDimensions(lengthX), this.getVerticalDimensions(lengthY));
				}
			}
		}
	}

	getAbsoluteDepth() {
		if (this.position === StairOutSide.POSITION_RIGHT || this.position === StairOutSide.POSITION_LEFT) {
			if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
				if (this.endLanding.active) {
					return this.stairWell.depth;
				}
				return this.stairWell.width;
			} else {
				return this.stairWell.depth;
			}
		} else if (this.position === StairOutSide.POSITION_TOP || this.position === StairOutSide.POSITION_BOTTOM) {
			if (this.upComing === Stair.UPCOMING_LEFT || this.upComing === Stair.UPCOMING_RIGHT) {
				if (this.endLanding.active) {
					return this.stairWell.width;
				}
				return this.stairWell.depth;
			} else {
				return this.stairWell.width;
			}
		}
	}

	setSizeHandleObject(canvas, dimensionsHorizontal, dimensionsVertical) {
		canvas.sizeHandles.get('object').setByArray(dimensionsHorizontal, dimensionsVertical);
		let events = { onChangedHorizontal: this.onSizeHandleChangedHorizontal.bind(this), onChangedVertical: this.onSizeHandleChangedVertical.bind(this) };
		canvas.sizeHandles.get('object').registerEvents(events);
		// op deze manier heeft de sizeHandle zijn parent en kan de functie sizeHandleIsResizable(dimension) aangeroepen worden
		canvas.sizeHandles.get('object').parent = this;
	}
	onSizeHandleChangedHorizontal(evt, object, canvas, params) {
		let etageWidth = params.etages.get(params.etages.activeIndex).floor.width;
		if (this.position === StairOutSide.POSITION_TOP || this.position === StairOutSide.POSITION_BOTTOM) {
			if (params.raster === 0) {
				if (params.newLengthCurrentRaster < 0) {
					this.startX = 0;
					this.onChange();
					return;
				}

				if (params.newLengthCurrentRaster > etageWidth || params.newLengthCurrentRaster + this.getAbsoluteDepth() > etageWidth) {
					this.startX = etageWidth - this.getAbsoluteDepth();
					this.onChange();
					return;
				}

				if (this.upComing === StairOutSide.UPCOMING_LEFT) {
					this.startX = params.newLengthCurrentRaster + this.stairDrawWidth;
					this.onChange();
					return;
				}

				if (this.upComing === StairOutSide.UPCOMING_RIGHT) {
					this.startX = params.newLengthCurrentRaster;
					this.onChange();
					return;
				}

				this.startX = params.newLengthCurrentRaster;
			} else if (params.raster === 2 && !this.endLanding.active) {
				if (params.newLengthCurrentRaster + this.getAbsoluteDepth() > etageWidth) {
					this.startX = 0;
					this.onChange();
					return;
				}
				this.startX += object.width.value - params.newLengthCurrentRaster;
			} else if (this.endLanding.active && params.raster >= 2) {
				if (this.upComing === StairOutSide.UPCOMING_LEFT) {
					this.startX = Configuration.CURRENT.raster.spansX.getSize() - params.newLengthCurrentRaster - this.endLanding.depth;
				}

				if (this.upComing === StairOutSide.UPCOMING_RIGHT) {
					this.startX += object.width.value - params.newLengthCurrentRaster;
				}

				this.onChange();
				return;
			} else {
				this.startX += object.width.value - params.newLengthCurrentRaster;
			}
		}
		this.onChange();
	}
	onSizeHandleChangedVertical(evt, object, canvas, params) {
		let etageDepth = params.etages.get(params.etages.activeIndex).floor.length;
		if (this.position === StairOutSide.POSITION_LEFT || this.position === StairOutSide.POSITION_RIGHT) {
			if (params.raster === 0) {
				// Wanneer de waarde kleiner dan 0 in word gevoerd dan komt de stairoutside buiten de vloer te staan, daarom forceren dat dat 0 is.
				if (params.newLengthCurrentRaster < 0) {
					this.startY = 0;
					this.onChange();
					return;
				}

				// Wanneer de ingevoerde waarde groter is dan de vloer diepte dan zou de trap buiten de vloer vallen. Zet hem dan op het maximale mogelijk.
				if (params.newLengthCurrentRaster > etageDepth || params.newLengthCurrentRaster + this.getAbsoluteDepth() > etageDepth) {
					this.startY = etageDepth - this.getAbsoluteDepth();
					this.onChange();
					return;
				}

				if (this.upComing === StairOutSide.UPCOMING_TOP) {
					this.startY = params.newLengthCurrentRaster + this.stairDrawWidth;
					this.onChange();
					return;
				}

				this.startY = params.newLengthCurrentRaster;
			} else if (params.raster === 2 && !this.endLanding.active) {
				if (params.newLengthCurrentRaster + this.getAbsoluteDepth() > etageDepth) {
					this.startY = 0;
					this.onChange();
					return;
				}
				this.startY += object.width.value - params.newLengthCurrentRaster;
			} else if (params.raster >= 2 && this.endLanding.active) {
				if (this.upComing === StairOutSide.UPCOMING_TOP || this.upComing === StairOutSide.UPCOMING_BOTTOM) {
					this.startY += object.width.value - params.newLengthCurrentRaster;
				}
			}
		}
		this.onChange();
	}

	addDrawObjects(canvas) {
		this.landingBoundaries = [];
		if (this.active === false) {
			return;
		}
		// stairOutside aan het eind pas sizehandle ivm bepalen breedte
		this.boundaries = [];

		const stairGroup = new ObjectGroup(Profiles.COLOR.mainBeam, null, null, true, this, {});
		let params = {};
		params.rasterY = null;
		params.rasterX = null;
		params.hasErrors = this.hasErrors;

		if (this.rasters.length > 0) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				params.rasterY = this.rasters[0].y;
			} else {
				params.rasterX = this.rasters[0].x;
			}
		}

		// stairdepth gebruiken voor de linkse en rechtse lijn. Niet voor de tail. Die blijft altijd over de hele diepte van detrap
		let stairDepth = Mathematic.calculateDepth(this.etageHeight, this.angle);

		// hier nog sorteren
		if (this.intermediateLandings.get().length > 0) {
			stairDepth = this.intermediateLandings.getDepth(0, this.etageHeight, this.angle);
		}

		if (this.upComing === Stairs.POSITION_TOP || this.upComing === Stairs.POSITION_BOTTOM) {
			this.stairDrawHeight = stairDepth;
			this.stairDrawWidth = this.stepWidth;
		} else if (this.upComing === Stairs.POSITION_LEFT || this.upComing === Stairs.POSITION_RIGHT) {
			this.stairDrawWidth = stairDepth;
			this.stairDrawHeight = this.stepWidth;
		}

		// Eerste stuk stair van de gehele stair de boundaries ophalen.
		let boundary = {
			topLeft: { x: this.startX, y: this.startY },
			topRight: { x: this.startX + this.stairDrawWidth, y: this.startY },
			bottomLeft: { x: this.startX, y: this.startY + this.stairDrawHeight },
			bottomRight: { x: this.startX + this.stairDrawWidth, y: this.startY + this.stairDrawHeight },
		};

		if (this.endLanding.active === true) {
			params.boundary = boundary;
			params.position = this.position;
			params.upComing = this.upComing;
			params.stepWidth = this.width;
			params.hasErrors = this.hasErrors;

			let drawObjects = this.endLanding.addDrawObjects(canvas, params);
			boundary = params.boundary;
			drawObjects.forEach((drawObject) => {
				stairGroup.push(drawObject);
			});

			if (this.position === StairOutSide.POSITION_TOP || this.position === StairOutSide.POSITION_BOTTOM) {
				this.sizeHandle = {
					start: this.endLanding.boundary.topLeft.x,
					width: this.upComing === Stair.UPCOMING_LEFT || this.upComing === Stair.UPCOMING_RIGHT ? this.endLanding.depth : this.endLanding.width,
					lengthRaster: Configuration.CURRENT.raster.spansX.getSize(),
				};
			} else {
				this.sizeHandle = {
					start: this.endLanding.boundary.topLeft.y,
					width: this.upComing === Stair.UPCOMING_LEFT || this.upComing === Stair.UPCOMING_RIGHT ? this.endLanding.width : this.endLanding.depth,
					lengthRaster: Configuration.CURRENT.raster.spansY.getSize(),
				};
			}
		} else if (this.position === StairOutSide.POSITION_TOP || this.position === StairOutSide.POSITION_BOTTOM) {
			this.sizeHandle = { start: this.startX, width: this.stepWidth, lengthRaster: Configuration.CURRENT.raster.spansX.getSize() };
		} else {
			this.sizeHandle = { start: this.startY, width: this.stepWidth, lengthRaster: Configuration.CURRENT.raster.spansY.getSize() };
		}

		let nextLanding = this.intermediateLandings.get(0);
		let prevLanding = null;
		let firstLanding180 = nextLanding !== null && typeof nextLanding !== 'undefined' ? nextLanding.landingType === IntermediateLandings.oneeightyDegrees : false;
		// Stuk stair tekenen en de boundary hier van terugkrijgen, vanuit dat eindpunt gaan we dan de landings en stukken stair tekenen.
		boundary = this.drawObjectsStair(stairGroup, Stair.toOppositeUpComing(this.upComing), boundary, stairDepth, true, null, nextLanding, prevLanding, firstLanding180, true);
		this.landingBoundaries.push(boundary);

		let lastUpcoming = Stair.toOppositeUpComing(this.upComing);
		this.intermediateLandings.intermediateLandings.forEach((landing, index) => {
			// Boundary van de stair
			params.boundary = boundary;
			params.upComing = lastUpcoming;
			params.stepWidth = this.width;
			params.hasErrors = this.hasErrors;
			nextLanding = this.intermediateLandings.get(index + 1);
			prevLanding = this.intermediateLandings.get(index - 1);

			// Het grijze vak / landing tekenen.
			let drawObjects = landing.addDrawObjects(canvas, params);
			boundary = params.boundary;
			this.boundaries.push(boundary);
			drawObjects.forEach((drawObject) => {
				stairGroup.push(drawObject);
			});

			stairDepth = this.intermediateLandings.getDepth(index + 1, this.etageHeight, this.angle);
			// Na het grijze vak / landing de next stair en boundary opslaan.
			boundary = this.drawObjectsStair(
				stairGroup,
				Stair.toOppositeUpComing(landing.upComing),
				boundary,
				stairDepth,
				false,
				landing,
				nextLanding,
				prevLanding,
				landing.landingType === IntermediateLandings.oneeightyDegrees,
			);
			this.landingBoundaries.push(boundary);

			lastUpcoming = Stair.toOppositeUpComing(landing.upComing);
		});

		this.drawObject = stairGroup.id;
		// stairOutside aan het eind pas sizehandle ivm bepalen breedte
		if (canvas !== null) {
			if (this.selected === true) {
				this.setObjectSizeHandle(canvas, false);
			}
		}

		return { regenerate: false, stair: stairGroup };
	}
	getHorizontalDimensions(lengthX) {
		// bereken de horizontale dimensioning op, alleen relevant voor top en bottom de meerdere dimensions (start, width/depth, rest), anders moet hij hele raster lengthX geven
		// zodat de dimensioning niet buiten de vloer komt te staan
		let dimensions = [];
		if (this.position === Stair.POSITION_TOP || this.position === Stair.POSITION_BOTTOM) {
			if (this.upComing === Stair.UPCOMING_LEFT || (this.upComing === Stair.UPCOMING_RIGHT && this.endLanding.active === true)) {
				Configuration.CURRENT.canvas.sizeHandles.sizeHandles[0].startPosition = 0;
				// Bij Outside links of rechts dan moet er een endlanding actief zijn.
				// Dan voor sizehandle die waardes gebruiken.
				dimensions = [Math.round(this.endLanding.boundary.topLeft.x), Math.round(this.endLanding.depth), Math.round(lengthX - this.endLanding.boundary.topLeft.x - this.endLanding.depth)];
			} else if ((this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) && this.endLanding.active === true) {
				dimensions = [Math.round(this.endLanding.boundary.topLeft.x), Math.round(this.endLanding.width), Math.round(lengthX - this.endLanding.boundary.topLeft.x - this.endLanding.width)];
				// Anders gebruiken we begin van eerste stair plus stepWidth.
			} else if (this.endLanding.active === false) {
				Configuration.CURRENT.canvas.sizeHandles.sizeHandles[0].startPosition = 0;
				dimensions = [Math.round(this.startX), Math.round(this.stepWidth), Math.round(lengthX - this.startX - this.stepWidth)];
			}
		}
		return dimensions;
	}
	getVerticalDimensions(lengthY) {
		// bereken de verticale dimensioning op, alleen relevant voor left en right de meerdere dimensions (start, width/depth, rest), anders moet hij hele raster lengthY geven
		// zodat de dimensioning niet buiten de vloer komt te staan
		let dimensions = [];
		if (this.position === Stair.POSITION_LEFT || this.position === Stair.POSITION_RIGHT) {
			if ((this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) && this.endLanding.active === true) {
				// Bij positie links of rechts en upcoming boven of onder is endlanding altijd actief dus dan de waardes daarvan gebruiken.
				dimensions = [Math.round(this.endLanding.boundary.topLeft.y), Math.round(this.endLanding.depth), Math.round(lengthY - this.endLanding.boundary.topLeft.y - this.endLanding.depth)];
			} else if ((this.upComing === Stair.UPCOMING_RIGHT || this.upComing === Stair.UPCOMING_LEFT) && this.endLanding.active === true) {
				dimensions = [Math.round(this.endLanding.boundary.topLeft.y), Math.round(this.endLanding.width), Math.round(lengthY - this.endLanding.boundary.topLeft.y - this.endLanding.width)];
			} else if (this.endLanding.active === false) {
				// Anders gebruiken we begin van eerste stair plus stepWidth.
				Configuration.CURRENT.canvas.sizeHandles.sizeHandles[0].startPosition = 0;
				dimensions = [Math.round(this.startY), Math.round(this.stepWidth), Math.round(lengthY - this.startY - this.stepWidth)];
			}
		}
		return dimensions;
	}
	drawObjectsStair(stairGroup, upComingInFloor, boundary, stairDepth, inFloor, landing, nextLanding, prevLanding, oneeightyDegreesLanding, firstStair = false) {
		if (typeof inFloor === 'undefined' || inFloor === null) {
			inFloor = false;
		}
		let stairRight = null;
		let stairLeft = null;
		let stairTail = null;
		let startX = 0;
		let startY = 0;

		switch (upComingInFloor) {
			case Stair.UPCOMING_TOP:
				if (landing !== null || this.endLanding.active === true) {
					startX = boundary.bottomLeft.x;
					startY = boundary.bottomLeft.y;
				} else {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
				}
				// Als de huidige 180 graden is lijnen we het stukje trap LINKS uit
				if (oneeightyDegreesLanding) {
					//als 180 graden dan aan begin van bordes
					startX = boundary.topRight.x - this.width;
				} else if (prevLanding !== null || (firstStair === true && this.endLanding.active === true)) {
					// Checken of de prevLanding bestaat, als dat niet het geval is dan is het altijd het eerste stukje stair.
					// Dan uitlijnen midden bordes / boundarys
					startX = boundary.topRight.x + (boundary.topLeft.x - boundary.topRight.x - this.width) / 2;
					if (nextLanding !== null && typeof nextLanding !== 'undefined') {
						if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
							// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
							startX = boundary.topRight.x - this.width;
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0),
					new DrawValue(startX),
					new DrawValue(startY + stairDepth), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);

				stairLeft = new Line(
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0),
					new DrawValue(startX + this.width),
					new DrawValue(startY + stairDepth), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairLeft',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);

				boundary = {
					topLeft: { x: startX, y: startY },
					topRight: { x: startX + this.width, y: startY },
					bottomLeft: { x: startX, y: startY + stairDepth },
					bottomRight: { x: startX + this.width, y: startY + stairDepth },
				};

				break;
			case Stair.UPCOMING_BOTTOM:
				startX = boundary.topLeft.x;
				startY = boundary.topLeft.y;

				if (oneeightyDegreesLanding) {
					//als 180 graden dan aan begin van bordes
					startX = boundary.topLeft.x;
				} else if (prevLanding !== null || (firstStair === true && this.endLanding.active === true)) {
					// Checken of de prevLanding bestaat, als dat niet het geval is dan is het altijd het eerste stukje stair.
					// Dan uitlijnen midden bordes / boundarys
					startX = boundary.topLeft.x + (boundary.topRight.x - boundary.topLeft.x - this.width) / 2;
					if (nextLanding !== null && typeof nextLanding !== 'undefined') {
						if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
							// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
							startX = boundary.topLeft.x;
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX),
					new DrawValue(startY - stairDepth),
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairLeft = new Line(
					new DrawValue(startX + this.width),
					new DrawValue(startY - stairDepth),
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairLeft',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);

				boundary = {
					topLeft: { x: startX, y: startY - stairDepth },
					topRight: { x: startX + this.width, y: startY - stairDepth },
					bottomLeft: { x: startX, y: startY },
					bottomRight: { x: startX + this.width, y: startY },
				};

				break;
			case Stair.UPCOMING_RIGHT:
				startX = boundary.topLeft.x;
				startY = boundary.topLeft.y;

				if (oneeightyDegreesLanding) {
					// startY += landing.depth / 2;
					startY = boundary.bottomLeft.y - this.width;
				} else if (prevLanding !== null || (firstStair === true && this.endLanding.active === true)) {
					// Checken of de prevLanding bestaat, als dat niet het geval is dan is het altijd het eerste stukje stair.
					startY = boundary.topLeft.y + (boundary.bottomLeft.y - boundary.topLeft.y - this.width) / 2;
					if (nextLanding !== null && typeof nextLanding !== 'undefined') {
						if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
							// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
							startY = boundary.bottomLeft.y - this.stepWidth;
						}
					}
				}

				stairLeft = new Line(
					new DrawValue(startX - stairDepth),
					new DrawValue(startY, Columns.COLUMN_SIZE / 2),
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairLeft', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairRight = new Line(
					new DrawValue(startX - stairDepth),
					new DrawValue(startY + this.width, -Columns.COLUMN_SIZE / 2),
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairRight',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);

				boundary = {
					topLeft: { x: startX - stairDepth, y: startY },
					topRight: { x: startX, y: startY },
					bottomLeft: { x: startX - stairDepth, y: startY + this.width },
					bottomRight: { x: startX, y: startY + this.width },
				};

				break;
			case Stair.UPCOMING_LEFT:
				// Bij upcoming Left (dus eigenlijk rechterkant op)
				// Als endlanding dan actief is dan moeten we de trap tekeken met de endlanding breedte erbij, dat is dan dus topRight.x
				// Ook als het een trap na een landing is dan pakken we de topRight, aangezien de trap naar rechts loopt.
				if (landing !== null || this.endLanding.active === true) {
					startX = boundary.topRight.x;
					startY = boundary.topRight.y;
				} else {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
				}

				// Wanneer het de eerste stair is, dus vanaf boven gezien. En de Endlanding is actief.
				// Dan verticaal de trap centreren.
				// Eerste trap en er is een endlanding, en de volgende landing is 180
				if (firstStair === true && this.endLanding.active === true && oneeightyDegreesLanding) {
					startY = boundary.topLeft.y;
				} else if (firstStair === true && this.endLanding.active === true) {
					startY = boundary.topLeft.y + (boundary.bottomLeft.y - boundary.topLeft.y - this.width) / 2;
				} else {
					if (oneeightyDegreesLanding) {
						startY = boundary.topLeft.y;
					} else if (prevLanding !== null || (firstStair === true && this.endLanding.active === true)) {
						// Checken of de prevLanding bestaat, als dat niet het geval is dan is het altijd het eerste stukje stair.
						startY = boundary.topLeft.y + (boundary.bottomLeft.y - boundary.topLeft.y - this.width) / 2;
						if (nextLanding !== null && typeof nextLanding !== 'undefined') {
							if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
								// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
								startY = boundary.topLeft.y;
							}
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2),
					new DrawValue(startX + stairDepth),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairLeft = new Line(
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2),
					new DrawValue(startX + stairDepth),
					new DrawValue(startY + this.width, inFloor === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairLeft',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);

				boundary = {
					topLeft: { x: startX, y: startY },
					topRight: { x: startX + stairDepth, y: startY },
					bottomLeft: { x: startX, y: startY + this.width },
					bottomRight: { x: startX + stairDepth, y: startY + this.width },
				};
				break;
		}
		if (stairRight !== null) {
			stairRight.opacity = 0.5;
			stairRight.colorFromParent = false;
			stairGroup.push(stairRight);
		}
		if (stairLeft !== null) {
			stairLeft.opacity = 0.5;
			stairLeft.colorFromParent = false;
			stairGroup.push(stairLeft);
		}
		if (stairTail !== null) {
			stairTail.opacity = 0.2;
			stairTail.colorFromParent = false;
			stairGroup.push(stairTail);
		}
		let stair = new Rectangle(
			new DrawValue(boundary.topLeft.x),
			new DrawValue(boundary.topLeft.y), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
			new DrawValue(boundary.bottomRight.x - boundary.topLeft.x),
			new DrawValue(boundary.bottomRight.y - boundary.topLeft.y),
			this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
			'transparent',
			null,
			true,
			this,
			{
				type: 'stair',
				color: Stairs.COLORS.stairTail,
				selected: Stairs.COLORS.selected,
			},
		);
		stair.colorFromParent = false;
		stair.border = true;
		stair.opacity = 0.5;

		stairGroup.push(stair);
		this.boundaries.push(boundary);
		return boundary;
	}
	addPossiblePositions(canvas, params, stairs) {
		let possiblePositionFound = false;
		let left = 0;

		params.raster.spansX.getSpans().forEach((spanX, indexX) => {
			let top = 0;
			params.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (params.actieveEtage.isActiveRaster(new RemoveRaster(indexX, indexY)) && stairs.containsStair({ x: indexX, y: indexY }) === false) {
					// check of nog niet trap tegen de vloer bevat
					let topActive = params.actieveEtage.isActiveRaster(new RemoveRaster(indexX, indexY - 1));
					let bottomActive = params.actieveEtage.isActiveRaster(new RemoveRaster(indexX, indexY + 1));
					let leftActive = params.actieveEtage.isActiveRaster(new RemoveRaster(indexX - 1, indexY));
					let rightActive = params.actieveEtage.isActiveRaster(new RemoveRaster(indexX + 1, indexY));

					if (topActive === false) {
						possiblePositionFound = true;
						let line = new Line(
							new DrawValue(left),
							new DrawValue(top),
							new DrawValue(left + spanX.value),
							new DrawValue(top),
							new DrawValue(0, 3),
							Stairs.COLORS.lineDash,
							Stairs.COLORS.possiblePositionOutSide,
							null,
							null,
							true,
							stairs,
							{
								x: indexX,
								y: indexY,
								position: StairOutSide.POSITION_TOP,
							},
						);
						canvas.addDrawObject(line);
					}
					if (bottomActive === false) {
						let line = new Line(
							new DrawValue(left),
							new DrawValue(top + spanY.value),
							new DrawValue(left + spanX.value),
							new DrawValue(top + spanY.value),
							new DrawValue(0, 3),
							Stairs.COLORS.lineDash,
							Stairs.COLORS.possiblePositionOutSide,
							null,
							null,
							true,
							stairs,
							{
								x: indexX,
								y: indexY,
								position: StairOutSide.POSITION_BOTTOM,
							},
						);
						canvas.addDrawObject(line);
					}
					if (leftActive === false) {
						let line = new Line(
							new DrawValue(left),
							new DrawValue(top),
							new DrawValue(left),
							new DrawValue(top + spanY.value),
							new DrawValue(0, 3),
							Stairs.COLORS.lineDash,
							Stairs.COLORS.possiblePositionOutSide,
							null,
							null,
							true,
							stairs,
							{
								x: indexX,
								y: indexY,
								position: StairOutSide.POSITION_LEFT,
							},
						);
						canvas.addDrawObject(line);
					}
					if (rightActive === false) {
						let line = new Line(
							new DrawValue(left + spanX.value),
							new DrawValue(top),
							new DrawValue(left + spanX.value),
							new DrawValue(top + spanY.value),
							new DrawValue(0, 3),
							Stairs.COLORS.lineDash,
							Stairs.COLORS.possiblePositionOutSide,
							null,
							null,
							true,
							stairs,
							{
								x: indexX,
								y: indexY,
								position: StairOutSide.POSITION_RIGHT,
							},
						);
						canvas.addDrawObject(line);
					}
				}
				top += spanY.value;
			});
			left += spanX.value;
		});
		if (possiblePositionFound === false) {
			Configuration.CURRENT.notification.hide();
			Configuration.CURRENT.notification.show('Geen mogelijkheden gevonden', 1000);
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.setAccessoriesType('');
		}
	}
	static isPossible(raster, position, etageIndex) {
		let active = true;
		if (Configuration.CURRENT.etages.etages[etageIndex].isActiveRaster(new RemoveRaster(raster.x, raster.y)) === false) {
			active = false;
		} else {
			switch (position) {
				case StairOutSide.POSITION_TOP:
					if (Configuration.CURRENT.etages.etages[etageIndex].isActiveRaster(new RemoveRaster(raster.x, raster.y - 1)) === true) {
						active = false;
					}

					break;
				case StairOutSide.POSITION_BOTTOM:
					if (Configuration.CURRENT.etages.etages[etageIndex].isActiveRaster(new RemoveRaster(raster.x, raster.y + 1)) === true) {
						active = false;
					}

					break;
				case StairOutSide.POSITION_LEFT:
					if (Configuration.CURRENT.etages.etages[etageIndex].isActiveRaster(new RemoveRaster(raster.x - 1, raster.y)) === true) {
						active = false;
					}

					break;
				case StairOutSide.POSITION_RIGHT:
					if (Configuration.CURRENT.etages.etages[etageIndex].isActiveRaster(new RemoveRaster(raster.x + 1, raster.y)) === true) {
						active = false;
					}

					break;
			}
		}
		return active;
	}
	rastersActive() {
		let active = true;
		this.rasters.forEach((raster) => {
			if (StairOutSide.isPossible(raster, this.position, this.etageIndex) === false) {
				active = false;
			}
		});
		return active;
	}
	getAmountData() {
		let data = super.getAmountData();
		if (this.endLanding.active === true) {
			data.endLanding = this.endLanding.getAmountData();
			data.endLanding.height = this.etageHeight;
			data.endLanding.position = this.position;
			data.endLanding.direction = this.endLanding.upcoming;
		}
		return data;
	}

	addDrawObjects3d(canvas3d, etage, raster, posY = 0, etageIndex) {
		posY = Configuration.CURRENT.etages.getTotalHeight(etageIndex, true);
		if (this.active) {
			let stairHigherthanFirstFloor = posY > Configuration.CURRENT.etages.etages[0].height;
			let firstStairStartPosition = { x: this.startX, y: this.startY };
			let firstStairLandingWidth = this.stepWidth;
			let firstStairLandingDepth = this.stepWidth;
			// Wanneer endlanding actief is zetten we de stair ervan in het midden, dan hebben we de breedte nodig van de landing om hem te centeren.
			// Als de endlanding niet actief is dan moeten we de eerstvolgende landing hebben en de stair daarvoor in het midden plaatsen.

			// yPos standaard 0, er van uitgaande dat het een trap direct tot de vloer is.
			// Dan word de calculatie van de de hoogte in Stair3D goed gedaan.
			// Als er een landing in de stair zit, dan pakken we dus de hoogte van die landing om de verticale hoogte te berekenen.
			let yPos = 0;
			let firstLanding = this.intermediateLandings.get(0);
			if (firstLanding !== null && typeof firstLanding !== 'undefined') {
				yPos = firstLanding.height;
			}

			if (this.endLanding.active) {
				let endlandingStartPosition = { x: this.endLanding.boundary.topLeft.x, y: this.endLanding.boundary.topLeft.y };
				new Landing3D(endlandingStartPosition.x, endlandingStartPosition.y, {
					drawHeight: posY, // De hoogte waarop de endlanding tekent.
					upComing: this.endLanding.upComing, // Welke kant de upComing van de Endlanding opwijst.
					upComingPrev: null, // Bij een endlanding is er maar 1 kant geen handrail, dus dan deze waarde null.
					width: this.getLandingWidth3D(this.endLanding, true),
					depth: this.getLandingDepth3D(this.endLanding, true),
					landingData: this.endLanding,
					raster: raster,
					stairData: this, // Meegeven voor algemene data
					endLanding: true, // Aangeven dat het een endlanding is, dan doen we in de landing object zelf objecten toewijzen die daarbij horen.
				});

				// Wanneer endlanding actief is dan alles centeren rondom die endlanding.
				firstStairStartPosition = { x: this.endLanding.boundary.topLeft.x, y: this.endLanding.boundary.topLeft.y };
				firstStairLandingWidth = this.getLandingWidth3D(this.endLanding, true);
				firstStairLandingDepth = this.getLandingDepth3D(this.endLanding, true);
				if (this.intermediateLandings.length === 0) {
					firstLanding = this.endLanding;
				}
			} else if (this.intermediateLandings.length > 0) {
				// Wanneer de endLanding niet actief is dan stair centeren aan de hand van de eerste landing postie.
				if (this.upComing === Stair.UPCOMING_BOTTOM) {
					firstStairStartPosition.x = this.intermediateLandings.get(0).boundary.topLeft.x;
				} else if (this.upComing === Stair.UPCOMING_RIGHT) {
					firstStairStartPosition.y = this.intermediateLandings.get(0).boundary.topLeft.y;
				} else if (this.upComing === Stair.UPCOMING_LEFT) {
					firstStairStartPosition.y = this.intermediateLandings.get(0).boundary.topLeft.y;
				} else if (this.upComing === Stair.UPCOMING_TOP) {
					firstStairStartPosition.x = this.intermediateLandings.get(0).boundary.topLeft.x;
				}

				firstStairLandingWidth = this.getLandingWidth3D(this.intermediateLandings.get(0), false);
				firstStairLandingDepth = this.getLandingDepth3D(this.intermediateLandings.get(0), false);
			}

			// Vanaf endLanding tot eind waar hij komt tekenen, dan is de boundary waar zijn box in 3d is zegmaar.
			new Stair3D(firstStairStartPosition.x, firstStairStartPosition.y, {
				stairData: this,
				yPos: yPos,
				yPosEnd: posY, // Eindpunt, dus eigen landing hoogte oftewel vanaf boven naarbeneden begin punt in hoogte.
				landingData: firstLanding,
				overhang: Configuration.CURRENT.overhang.size,
				upComing: this.upComing,
				multipleFloorStair: stairHigherthanFirstFloor,
				width: firstStairLandingWidth,
				depth: firstStairLandingDepth,
				firstStair: true,
				endLandingActive: this.endLanding.active,
				stepsAmount: this.intermediateLandings.length !== 0 ? Math.ceil((this.etageHeight - firstLanding.height) / this._fallingProtectionStandardRules.getMaxRise(this.angle)) + 1 : this.amountSteps,
			});

			// tot waar hij tekent, in dit geval altijd tot de eerstgevonden landing. // Bovenste punt, zal dus etagehoogte zijn vanaf de etage van waar hij tekent.
			this.intermediateLandings.intermediateLandings.forEach((landing, index) => {
				// Vorige landing ophalen.
				let prevLanding = this.intermediateLandings.get(index - 1);
				let upComingPrev;
				let landingStartPosition = { x: landing.boundary.topLeft.x, y: landing.boundary.topLeft.y };
				// Welk stuk handrail we niet hoeven te tekenen aan de hand van vorige landing upComing.
				if (prevLanding !== null && typeof prevLanding !== 'undefined') {
					upComingPrev = Stairs.getOppositeUpComing(prevLanding.upComing);
				} else {
					// ALs die er niet is, dan is het de trap tot de vloer die erin komt, dan kijken we dus naar de eigen upComing.
					upComingPrev = Stairs.getOppositeUpComing(this.upComing);
				}
				// Checken of er een endLanding actief is, als dat zo is moeten we de boundaries gebruiken van de vorige trap
				// Die hebben we niet dus halen we de middelste positie op vanaf de endlanding gezien.
				// landingStartPosition = this.centerLandingsBasedOnEndLanding(landing.boundary.topLeft.x, landing.boundary.topLeft.y);

				// Toevoegen van de landing
				new Landing3D(landingStartPosition.x, landingStartPosition.y, {
					drawHeight: landing.height,
					upComing: landing.upComing,
					upComingPrev: upComingPrev,
					width: this.getLandingWidth3D(landing, false),
					depth: this.getLandingDepth3D(landing, false),
					landingData: landing,
					raster: raster,
					stairData: this,
					endLanding: false,
				});

				// Toevoegen van de stair na de huidige landing
				let nextLanding = this.intermediateLandings.get(index + 1);
				if (nextLanding === null || typeof nextLanding === 'undefined') {
					nextLanding = {};
					nextLanding.height = 0;
				}

				// let stairStartPosition = this.centerLandingsBasedOnEndLanding(landing.boundary.topLeft.x, landing.boundary.topLeft.y);
				let stairStartPosition = { x: landing.boundary.topLeft.x, y: landing.boundary.topLeft.y };

				canvas3d.addDrawObject(
					new Stair3D(stairStartPosition.x, stairStartPosition.y, {
						stairData: this,
						yPos: nextLanding.height,
						yPosEnd: landing.height,
						landingData: landing,
						prevLandingData: prevLanding,
						overhang: Configuration.CURRENT.overhang.size,
						upComing: landing.upComing,
						width: this.getLandingWidth3D(landing, false),
						depth: this.getLandingDepth3D(landing, false),
						multipleFloorStair: stairHigherthanFirstFloor,
						stairId: this.id,
						firstStair: false,
						endLandingActive: this.endLanding.active,
						stepsAmount: landing.amountSteps,
					}),
					Canvas3D.TYPE_STAIR,
				);
			});
		}
	}
	updateStairPositionOnRasterChanged(params) {
		if (params.changedParams.beamDirection === 'x' && params.changedParams.rasterIndex <= this.rasters[0].x && (this.position === Stair.POSITION_LEFT || this.position === Stair.POSITION_RIGHT)) {
			// Wanneer raster lengte word aangepast updaten van de stair.
			if (this.position === 2) {
				this.startX = Configuration.CURRENT.raster.getSizeX(this.rasters[0].x);
			} else {
				this.startX = Configuration.CURRENT.raster.getSizeX(this.rasters[0].x - 1);
			}
		}
		if (params.changedParams.beamDirection === 'y' && params.changedParams.rasterIndex <= this.rasters[0].y && (this.position === Stair.POSITION_TOP || this.position === Stair.POSITION_BOTTOM)) {
			if (this.position === 1) {
				this.startY = Configuration.CURRENT.raster.getSizeY(this.rasters[0].y - 1);
			} else {
				this.startY = Configuration.CURRENT.raster.getSizeY(this.rasters[0].y);
			}
		}
	}
}

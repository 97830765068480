import * as pc from 'playcanvas';
import { Model3D } from './Model3D';

export class DefaultFloor3D extends Model3D {
	objectName = 'DefaultFloor3D';
	fallBackName = 'defaultfloor';

	draw(app, drawCubeMap = false) {
		this.y += this.height / 2;
		this.x += this.width / 2;
		this.z += this.depth / 2;

		this.width += 10;
		this.depth += 10;

		// Starten met een pc entity
		let defaultFloor = new pc.Entity('defaultfloor');

		// afmetingen van de box
		defaultFloor.setLocalScale(this.width, this.height, this.depth);

		// positie van box
		defaultFloor.setPosition(this.x, this.y, this.z);

		defaultFloor.addComponent('light', {
			type: 'directional',
			range: 35,
			intensity: 0.85,
			innerConeAngle: 95,
			falloffMode: pc.LIGHTFALLOFF_LINEAR,
			affectDynamic: true,
			castsShadows: true,
		});

		defaultFloor.addComponent('collision', { type: 'box', halfExtends: new pc.Vec3(this.width, this.height, this.depth) });
		defaultFloor.addComponent('rigidbody', { type: pc.BODYTYPE_STATIC, restitution: 0.5 });

		defaultFloor.addComponent('model', { type: 'plane' });

		let texture = app.assets.find('ground.jpg', 'texture');

		if (texture !== null && typeof texture !== 'undefined') {
			let material = new pc.StandardMaterial();
			material.diffuseMap = app.assets.find('ground.jpg', 'texture').resource;

			material.diffuseMapOffset.set(0, 0.25);
			let transform = new pc.Vec4(6, 6, 6, 6);
			defaultFloor.model.meshInstances[0].setParameter('texture_diffuseMapTransform', transform.data);
			material.update();
			if (material !== null && typeof material !== 'undefined') {
				defaultFloor.model.material = material;
				// defaultFloor.model.meshInstances[0].material = material;
			}
		}

		// toevoegen aan het canvas
		app.root.addChild(defaultFloor);

		// drawCubeMap = true;
		if (drawCubeMap === null || typeof drawCubeMap === 'undefined' || drawCubeMap !== false) {
			// Create cubmap for the sky!
			let cubemap = new pc.Texture(app.graphicsDevice, { cubemap: true, rgbm: true }); // we're using HDR textures
			let assets = [app.assets.find('top.jpg'), app.assets.find('bottom.jpg'), app.assets.find('right.jpg'), app.assets.find('left.jpg'), app.assets.find('front.jpg'), app.assets.find('back.jpg')];
			this.loadCubeMapTextures(assets, app, function (textures) {
				cubemap.setSource(
					textures.map(function (texture) {
						return texture.resource.getSource();
					}),
				);
			});

			// set as the skybox
			app.scene.skybox = cubemap;
		}
	}
	loadCubeMapTextures(assets, app, callback) {
		let i = 0;
		let count = 0;

		// called for each asset loaded
		let progress = function () {
			count++;
			if (count === assets.length) {
				done();
			}
		};

		// called when all assets are loaded
		let done = function () {
			callback(assets);
		};

		// load each asset
		for (i = 0; i < assets.length; i++) {
			assets[i].ready(progress);
			app.assets.load(assets[i]);
		}
	}
}

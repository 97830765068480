import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { Configuration } from './configuration';
import { BraceColumn } from './braceColumn';

import { Columns } from './columns';
import { Errors } from './errors';
import { Canvas3D } from '../draw3d/Canvas3D';

export class BraceColumns {
	objectName = 'BraceColumns';
	objectName3d = 'BraceColumns3D';
	static LEFT = 1;
	static TOP = 2;
	static RIGHT = 3;
	static BOTTOM = 4;
	static MOUNTING_MATERIAL_HEIGHT = 122;
	static PROFILE_FREE_SIZE = 3;
	static ANGLE = 70;
	static COLORS = { braceColumn: '#999', possible: '#6ba3ff', added: '#10821a', mouseMove: '#00286d', addedMouseMove: '#0f6d01' };
	amountHorizontal = 0;
	amountVertical = 0;
	mouseMoveActive = null;
	mousePriority = 10;
	foundErrors = new Errors();
	mouseAreaOffset = { x: 0, y: 0 };
	braceColumns = [];

	static drawOffset = { x: 0, y: 0 };

	static get size() {
		let heightColumn =
			Configuration.CURRENT.columns.getHeight(0, false) -
			BraceColumns.MOUNTING_MATERIAL_HEIGHT -
			BraceColumns.PROFILE_FREE_SIZE -
			Configuration.CURRENT.columns.basePlate.height -
			Configuration.CURRENT.columns.bracket.height;
		return parseFloat((heightColumn / Math.tan((BraceColumns.ANGLE * Math.PI) / 180)).toFixed(2));
	}

	get hasErrors() {
		return this.foundErrors.length > 0;
	}
	getErrors() {
		return this.foundErrors;
	}

	constructor(onChange) {
		this._onChange = onChange;
	}
	setReferences(params) {
		this._onChange = params.onChange;

		this.braceColumns.forEach((braceColumn) => {
			if (typeof braceColumn.setReferences === 'function') {
				braceColumn.setReferences(params);
			}
		});
	}
	removeReferences() {
		this._onChange = null;

		this.braceColumns.forEach((braceColumn) => {
			if (typeof braceColumn.removeReferences === 'function') {
				braceColumn.removeReferences();
			}
		});
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}

	count() {
		let count = 0;
		this.braceColumns.forEach((bc, index) => {
			if (bc.braceColumnActive) {
				count++;
			}
		});
		return count;
	}
	length() {
		let heightColumn =
			Configuration.CURRENT.columns.getHeight(0, false) -
			BraceColumns.MOUNTING_MATERIAL_HEIGHT -
			BraceColumns.PROFILE_FREE_SIZE -
			Configuration.CURRENT.columns.basePlate.height -
			Configuration.CURRENT.columns.bracket.height;

		return (heightColumn / Math.sin((BraceColumns.ANGLE * Math.PI) / 180)).toFixed(2);
	}
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let resultErrors = [];
		this.braceColumns.forEach((braceColumn) => {
			let braceColumnResult = braceColumn.collisions(boundaries, self);

			if (braceColumnResult.result === true) {
				collisionsDetect = true;
				braceColumnResult.errors.forEach((error) => {
					resultErrors.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: resultErrors, objectName: this.objectName };
	}
	get(index) {
		if (typeof index !== 'undefined' && index !== null) {
			return this.braceColumns[index];
		}
		return this.braceColumns;
	}
	// hier de mogelijke posities mee verplaatsen. In braceColumn de gekozen posities
	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		if ((raster.x > -1 && raster.x === drawObject.objectParams.column.x) || (raster.y > -1 && raster.y === drawObject.objectParams.column.y)) {
			drawObject.x.value += delta.x;
			drawObject.y.value += delta.y;
			drawObject.eindX.value += delta.x;
			drawObject.eindY.value += delta.y;
		}
	}
	onMouseMove(evt, object, canvas, params) {
		if (this.findIndex(object.objectParams.column, object.objectParams.direction) > -1) {
			object.lineColor = BraceColumns.COLORS.addedMouseMove;
		} else {
			object.lineColor = BraceColumns.COLORS.mouseMove;
		}
		this.mouseMoveActive = new BraceColumn(object.objectParams.column, object.objectParams.direction);
		return { stopPropagation: true };
	}
	onMouseUp(evt, object, canvas, params) {
		return { stopPropagation: true };
	}
	onMouseDrag(evt, object, canvas, params) {
		return { stopPropagation: true };
	}
	onMouseDown(evt, object, canvas, params) {
		// return { stopPropagation: true };
	}
	onMouseLeave(evt, object, canvas, params) {
		if (this.findIndex(object.objectParams.column, object.objectParams.direction) > -1) {
			object.lineColor = BraceColumns.COLORS.added;
		} else {
			object.lineColor = BraceColumns.COLORS.possible;
		}
		this.mouseMoveActive = null;
		return { stopPropagation: true };
	}
	onClick(evt, object, canvas, params) {
		this.togglePush(object.objectParams.column, object.objectParams.direction);
		this.onChange();

		return { stopPropagation: true };
	}
	findIndex(column, direction) {
		let findIndex = -1;
		this.braceColumns
			.filter((b) => b.braceColumnActive === true)
			.forEach((braceColumn, index) => {
				if (braceColumn.equals(column, direction) === true) {
					findIndex = index;
				}
			});
		return findIndex;
	}
	togglePush(startColumn, endColumn) {
		const index = this.findIndex(startColumn, endColumn);
		if (index === -1) {
			this.push(startColumn, endColumn);
		} else {
			this.braceColumns.splice(index, 1);
		}
	}
	push(startColumn, endColumn) {
		this.braceColumns.push(new BraceColumn(startColumn, endColumn));
	}

	addPossiblePositions(canvas, params) {
		const columns = Configuration.CURRENT.columns.getColumns();
		const activeEtage = params.actieveEtage;
		columns.forEach((column, index) => {
			if (column.isActive(params.actieveEtageIndex) === true) {
				const top = Configuration.CURRENT.columns.find(column.x, column.y - 1);
				const bottom = Configuration.CURRENT.columns.find(column.x, column.y + 1);
				const left = Configuration.CURRENT.columns.find(column.x - 1, column.y);
				const right = Configuration.CURRENT.columns.find(column.x + 1, column.y);
				let color = BraceColumns.COLORS.possible;
				if (this.findIndex(column, BraceColumns.LEFT) === -1) {
					if (left === null || (activeEtage.bracings.findIndex(left, column) === -1 && (left === null || column.getPosition().startX.value - left.getPosition().endX.value > BraceColumns.size))) {
						// tekenen
						color = BraceColumns.COLORS.possible;

						if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, BraceColumns.LEFT)) {
							color = BraceColumns.COLORS.mouseMove;
						}

						canvas.addDrawObject(this.createBraceColumn(column, BraceColumns.LEFT, color));
					}
				} else {
					// teken bestaand
					color = BraceColumns.COLORS.added;
					if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, BraceColumns.LEFT)) {
						color = BraceColumns.COLORS.addedMouseMove;
					}
					canvas.addDrawObject(this.createBraceColumn(column, BraceColumns.LEFT, color));
				}
				if (this.findIndex(column, BraceColumns.RIGHT) === -1) {
					if ((right === null || activeEtage.bracings.findIndex(column, right) === -1) && (right === null || right.getPosition().startX.value - column.getPosition().endX.value > BraceColumns.size)) {
						// tekenen
						color = BraceColumns.COLORS.possible;
						if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, BraceColumns.RIGHT)) {
							color = BraceColumns.COLORS.mouseMove;
						}
						canvas.addDrawObject(this.createBraceColumn(column, BraceColumns.RIGHT, color));
					}
				} else {
					// teken bestaand

					color = BraceColumns.COLORS.added;
					if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, BraceColumns.RIGHT)) {
						color = BraceColumns.COLORS.addedMouseMove;
					}

					canvas.addDrawObject(this.createBraceColumn(column, BraceColumns.RIGHT, color));
				}
				if (this.findIndex(column, BraceColumns.TOP) === -1) {
					if ((top === null || activeEtage.bracings.findIndex(top, column) === -1) && (top === null || column.getPosition().startY.value - top.getPosition().endY.value > BraceColumns.size)) {
						// tekenen

						color = BraceColumns.COLORS.possible;
						if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, BraceColumns.TOP)) {
							color = BraceColumns.COLORS.mouseMove;
						}
						canvas.addDrawObject(this.createBraceColumn(column, BraceColumns.TOP, color));
					}
				} else {
					// teken bestaand
					color = BraceColumns.COLORS.added;
					if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, BraceColumns.TOP)) {
						color = BraceColumns.COLORS.addedMouseMove;
					}
					canvas.addDrawObject(this.createBraceColumn(column, BraceColumns.TOP, color));
				}
				if (this.findIndex(column, BraceColumns.BOTTOM) === -1) {
					if (
						(bottom === null || activeEtage.bracings.findIndex(column, bottom) === -1) &&
						(bottom === null || bottom.getPosition().startY.value - column.getPosition().endY.value > BraceColumns.size)
					) {
						// tekenen
						color = BraceColumns.COLORS.possible;
						if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, BraceColumns.BOTTOM)) {
							color = BraceColumns.COLORS.mouseMove;
						}
						canvas.addDrawObject(this.createBraceColumn(column, BraceColumns.BOTTOM, color));
					}
				} else {
					// teken bestaand
					color = BraceColumns.COLORS.added;
					if (this.mouseMoveActive !== null && this.mouseMoveActive.equals(column, BraceColumns.BOTTOM)) {
						color = BraceColumns.COLORS.addedMouseMove;
					}
					canvas.addDrawObject(this.createBraceColumn(column, BraceColumns.BOTTOM, color));
				}
			}
		});
	}
	getAmount() {
		let floorLength = Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].getRasterWidthAndLength().y; // is de y
		let floorWidth = Configuration.CURRENT.etages.etages[Configuration.CURRENT.etages.activeEtageIndex].getRasterWidthAndLength().x; // is de x

		let halfX = floorWidth / 2.0; // halfX en halfY zijn nodig om in 4 kwadranten te kunnen indelen
		let halfY = floorLength / 2.0;
		let amount = { BraceColumns: [] };
		this.amountHorizontal = 0;
		this.amountVertical = 0;
		this.amountLeftTop = 0;
		this.amountRightTop = 0;
		this.amountLeftBottom = 0;
		this.amountRightBottom = 0;
		this.braceColumns
			.filter((b) => b.braceColumnActive === true)
			.forEach((braceColumn) => {
				let column = Configuration.CURRENT.columns.getPosition(braceColumn.x, braceColumn.y);
				if (column.x <= halfX && column.y <= halfY) {
					// Linksboven

					this.amountLeftTop++;
				}
				if (column.x >= halfX && column.y <= halfY) {
					// Rechtsboven

					this.amountRightTop++;
				}
				if (column.x <= halfX && column.y >= halfY) {
					// Linksonder

					this.amountLeftBottom++;
				}
				if (column.x >= halfX && column.y >= halfY) {
					// Linksonder

					this.amountRightBottom++;
				}
				if (braceColumn.direction === 1 || braceColumn.direction === 3) {
					// Horizontaal
					this.amountHorizontal += 1;
				}
				if (braceColumn.direction === 2 || braceColumn.direction === 4) {
					// Verticaal
					this.amountVertical += 1;
				}

				amount.BraceColumns.push({
					name: 'BraceColumn',
					length: braceColumn.length,
					amountHorizontal: this.amountHorizontal,
					amountVertical: this.amountVertical,
				});
			});
		return amount;
	}
	createBraceColumn(column, direction, color, object) {
		if (typeof object === 'undefined' || object === null) {
			object = this;
		}
		const positionStart = column.getPosition();
		const positionEnd = column.getPosition();
		switch (direction) {
			case BraceColumns.LEFT:
				positionStart.startX.value -= BraceColumns.size;
				positionStart.startX.offsetScaled = positionStart.startX.offsetScaled - BraceColumns.drawOffset.x;
				positionStart.startY.offsetScaled += Columns.COLUMN_SIZE / 2;

				positionEnd.startX.offsetScaled -= BraceColumns.drawOffset.x;
				positionEnd.startY.offsetScaled += Columns.COLUMN_SIZE / 2;
				return new Line(positionStart.startX, positionStart.startY, positionEnd.startX, positionEnd.startY, new DrawValue(Columns.COLUMN_SIZE, 0, true), null, color, null, null, true, object, {
					column: column,
					direction: direction,
					mouseAreaOffset: {
						x: 0,
						y: this.mouseAreaOffset.y,
					},
				});
			case BraceColumns.RIGHT:
				positionStart.endX.offsetScaled += BraceColumns.drawOffset.x;
				positionStart.endY.offsetScaled -= Columns.COLUMN_SIZE / 2;
				positionEnd.endX.value += BraceColumns.size;
				positionEnd.endX.offsetScaled = positionStart.endX.offsetScaled + BraceColumns.drawOffset.x;
				positionEnd.endY.offsetScaled -= Columns.COLUMN_SIZE / 2;
				return new Line(positionStart.endX, positionStart.endY, positionEnd.endX, positionEnd.endY, new DrawValue(Columns.COLUMN_SIZE, 0, true), null, color, null, null, true, object, {
					column: column,
					direction: direction,
					mouseAreaOffset: {
						x: 0,
						y: this.mouseAreaOffset.y,
					},
				});

			case BraceColumns.TOP:
				positionStart.startY.value -= BraceColumns.size;
				positionStart.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
				positionStart.startY.offsetScaled = positionStart.startY.offsetScaled - BraceColumns.drawOffset.y;

				positionEnd.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
				positionEnd.startY.offsetScaled -= BraceColumns.drawOffset.y;
				return new Line(positionStart.startX, positionStart.startY, positionEnd.startX, positionEnd.startY, new DrawValue(Columns.COLUMN_SIZE, 0, true), null, color, null, null, true, object, {
					column: column,
					direction: direction,
					mouseAreaOffset: {
						x: this.mouseAreaOffset.x,
						y: 0,
					},
				});
			case BraceColumns.BOTTOM:
				positionStart.endX.offsetScaled -= Columns.COLUMN_SIZE / 2;
				positionStart.endY.offsetScaled += BraceColumns.drawOffset.y;

				positionEnd.endY.value += BraceColumns.size;
				positionEnd.endX.offsetScaled -= Columns.COLUMN_SIZE / 2;
				positionEnd.endY.offsetScaled += positionStart.startY.offsetScaled + BraceColumns.drawOffset.y;

				return new Line(positionStart.endX, positionStart.endY, positionEnd.endX, positionEnd.endY, new DrawValue(Columns.COLUMN_SIZE, 0, true), null, color, null, null, true, object, {
					column: column,
					direction: direction,
					mouseAreaOffset: {
						x: this.mouseAreaOffset.x,
						y: 0,
					},
				});
		}
	}
	addDrawObjects(canvas, params) {
		this.braceColumns.forEach((braceColumn, index) => {
			braceColumn.addDrawObjects(canvas, params, this.createBraceColumn.bind(this)); // Meegeven om code om bracecolumn te maken maar 1x te hoeven maken/wijzigen
		});
	}
	addDrawObjects3d(canvas3d, etage, raster, posY) {
		this.braceColumns.forEach((braceColumn, index) => {
			braceColumn.addDrawObjects3d(canvas3d, etage, raster, posY);
		});
	}
	calculateAmount(params) {
		this.calculate(params.raster, params.etages);
	}
	calculate(raster, etages) {
		this.braceColumns.forEach((braceColumn) => {
			braceColumn.calculate();
		});
	}
}

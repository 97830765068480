import * as pc from 'playcanvas';
import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';

export class Profiles3D {
	objectName = 'Profiles3D';
	offsetFloor = 0.025;
	rotationX = 0;
	rotationY = 0;
	rotationZ = 0;

	width = 1;
	depth = 1;
	height = 1;

	ralColor = 7016;
	assetName = '';
	isStairWellProfile = false;
	heartLineDistance = 0;

	constructor(x, y, length, params) {
		this.x = x;
		this.y = params.drawHeight;
		this.z = y;

		Object.keys(params).forEach((param) => {
			this[param] = params[param];
		});

		this.width = length;
		if (Configuration.CURRENT.colors.profiles.finish === 1 || Configuration.CURRENT.colors.profiles.finish === 2) {
			this.ralColor = 7036;
		} else {
			this.ralColor = Configuration.CURRENT.colors.profiles.ralColor;
		}

		this.beamLengths = Configuration.CURRENT.profiles.getBeamLengths(params.oid);
		this.beamName = Configuration.CURRENT.profiles.getBeamName(params.oid).replaceAll('/', '_');
		Canvas3D.CURRENT.addDrawObject(this, Canvas3D.TYPE_PROFILE);
	}
	findModel(app) {
		// Vinden van model
		// TODO: Model ophalen hand van OID ipv assetname.
		let jsonModel = app.assets.find(this.assetName + '.json', 'model');
		if (typeof jsonModel !== 'undefined' && jsonModel !== null) {
			// Model check en toewijzen json aan model.
			if (jsonModel.resource !== null && typeof jsonModel.resource !== 'undefined') {
				return jsonModel.resource.clone();
				// model.model.model = jsonModel.resource.clone();
			}
		} else {
			// Als het model niet gevonden kan worden gaan we het fallback gebruiken.
			let jsonModelFallback = app.assets.find(this.fallBackName + '.json', 'model');
			if (typeof jsonModelFallback !== 'undefined' && jsonModelFallback !== null) {
				if (jsonModelFallback.resource !== null && typeof jsonModelFallback.resource !== 'undefined') {
					return jsonModelFallback.resource.clone();
					// model.model.model = jsonModelFallback.resource.clone();
				}
			} else {
				// Als ook de fallback niet gevonden kan worden :(
				console.log(
					'%cERROR PROFILE NOT FOUND!',
					'color: white; background-color: red;',
					'assetName:',
					this.assetName,
					'\n',
					'fallbackname:',
					this.fallBackName,
					'\n',
					'assets:',
					app.assets._assets,
					'\n',
					'current item:',
					this,
					'\n',
					'assetRegisteryLength',
					app.assets._assets.length,
				);
			}
		}
	}
	rotateModel(profileEntity) {
		if (typeof this.rotationX !== 'undefined' && typeof this.rotationY !== 'undefined' && typeof this.rotationZ !== 'undefined') {
			profileEntity.rotateLocal(this.rotationX, this.rotationY, this.rotationZ);
		}
	}
	applyTexture(app, profileEntity) {
		// Apply texture to model.
		if (this.texture !== null && typeof this.texture !== 'undefined') {
			let textureFile = app.assets.find(this.texture.name, 'texture');
			if (textureFile !== null && typeof textureFile !== 'undefined') {
				let material = new pc.StandardMaterial();
				material.diffuseMap = textureFile.resource;
				if (material !== null && typeof material !== 'undefined') {
					profileEntity.model.meshInstances[0].material = material;
				}
			}
		}
		// Apply color to model
		else if (this.ralColor !== null && typeof this.ralColor !== 'undefined') {
			let colorAsset = app.assets.find(this.ralColor + '.json', 'material');
			if (colorAsset !== null && typeof colorAsset !== 'undefined' && colorAsset.resources.length > 0) {
				profileEntity.model.meshInstances[0].material = colorAsset.resources[0];
			}
		}
	}
	getBeamAmount(rasterLength, lengths) {
		// lengtes zijn gesorteerd van groot naar klein
		let counts = [];
		// start lengte is rastrelengte
		let length = rasterLength;
		// loopen over lengte
		lengths.forEach((l) => {
			// is er nog restlengte over en is dit deelbaar door de lengte dan de hele aantallen opslaan en lengte de restlengte
			if (length > 0 && Math.floor(length / l) > 0) {
				counts.push({ length: l, count: Math.floor(length / l) });
				length = length - Math.floor(length / l) * l;
			}
		});

		return counts;
	}
	createProfile(app, position, entityName = null, objectName) {
		// Checken of een child met de naam profile_350 al bestaat, deze clonen en dan toevoegen
		let sameChild = app.root.children.find((e) => e.name === entityName);
		let parentEntity = app.root.children.find((e) => e.name === this.parentEntityName);

		// TODO: Voor nu oude manier gebruiken.
		sameChild = null;
		parentEntity = null;

		if (typeof sameChild !== 'undefined' && sameChild !== null && parentEntity !== null && typeof parentEntity !== 'undefined') {
			let cloned = sameChild.clone();
			cloned.setLocalScale(1, this.height, this.depth);
			cloned.rotateLocal(this.rotationX, this.rotationY, this.rotationZ);
			cloned.setPosition(position.x, position.y + this.offsetFloor, position.z);
			this.applyTexture(app, cloned);
			parentEntity.addChild(cloned);
		} else {
			// Wanneer parent niet gevonden kan worden op de oude manier doen.
			let profileEntity = new pc.Entity(this.objectName);
			profileEntity.addComponent('model');
			let jsonModel = this.findModel(app, this.assetName, this.beamLengths);
			profileEntity.model.model = jsonModel;
			profileEntity.setLocalScale(1, this.height, this.depth);
			profileEntity.setPosition(position.x, position.y + this.offsetFloor, position.z);
			this.rotateModel(profileEntity);
			this.applyTexture(app, profileEntity);
			app.root.addChild(profileEntity);
		}
	}
	getAssetName() {
		return this.beamName + '_';
	}
}

import { Mathematic } from '../helpers/mathematic';
import { Functions } from '../helpers/functions';
import { Store } from '../data/store';
import { RemoveRaster } from './removeRaster';
import { Configuration } from './configuration';
import { Stairs } from './stairs';

import { Profiles } from './profiles';

import { IntermediateLandings } from './intermediateLandings';
import { StairWell } from './stairWell';

import { Errors } from './errors';

import { EndLanding } from './endLanding';
import { CustomError } from './CustomError';
import { Standard } from './standards/standard';

export class Stair {
	// In upcoming.vue voorlopig getallen gebruikt omdat import class nog niet goed ging
	static UPCOMING_TOP = 1;
	static UPCOMING_RIGHT = 2;
	static UPCOMING_BOTTOM = 3;
	static UPCOMING_LEFT = 4;

	static POSITION_TOP = 1;
	static POSITION_RIGHT = 2;
	static POSITION_BOTTOM = 3;
	static POSITION_LEFT = 4;

	static UPCOMING_POSITIONS = [
		{ index: Stair.UPCOMING_TOP, className: 'top', image: require('~/assets/arrow-top.png'), imageClassName: 'centerVertical' },
		{ index: Stair.UPCOMING_RIGHT, className: 'right', image: require('~/assets/arrow-right.png'), imageClassName: 'centerHorizontal' },
		{ index: Stair.UPCOMING_BOTTOM, className: 'bottom', image: require('~/assets/arrow-bottom.png'), imageClassName: 'centerVertical' },
		{ index: Stair.UPCOMING_LEFT, className: 'left', image: require('~/assets/arrow-left.png'), imageClassName: 'centerHorizontal' },
	];

	static toUpComingInFloor(upComing) {
		let result = upComing + 2;
		if (result > 4) {
			result -= 4;
		}
		return result;
	}

	static toUpcomingLanding(upComing) {
		let result = upComing + 2;
		if (result > 4) {
			result -= 4;
		}
		return result;
	}

	static toOppositeUpComing(upComing) {
		let result = upComing + 2;
		if (result > 4) {
			result -= 4;
		}
		return result;
	}

	objectName = 'Stair';
	mousePriority = 20;
	setStepRetry = 0;
	maxSetStepRetry = 30; // 3 seconden wachten tot gegevens van step geladen zijn
	_angle = 0;
	angleDefault = true;
	amountSteps = 0;
	rise = 0;
	selected = false;
	_optional = false;
  material = {};
  stairOid = 0;

	get optional() {
		return this._optional;
	}

	set optional(value) {
		this._optional = value;
		this.onChange();
	}

	endLanding = new EndLanding(
		0,
		100,
		100,
		this.upComing,
		[],
		(value) => {
			this.setUpComing(value);
		},
		(value) => {
			this.disableUpcomings = value;
		},
	);
	get angle() {
		return this._angle;
	}
	set angle(value) {
		this._angle = value;
		this.angleDefault = this._angle === this.getAngleDefault() || this._angle === 0; // this._angle===0 niet helemaal terecht maar in sommige gevallen is hij 0 en moet hij default krijgen
	}
	get minAngle() {
		if (typeof this._fallingProtectionStandardRules === 'object') {
			return this._fallingProtectionStandardRules.getMinAngle(this.typeStep, this.stepWidth);
		}
		return 0;
	}

	get maxAngle() {
		if (typeof this._fallingProtectionStandardRules === 'object') {
			return this._fallingProtectionStandardRules.getMaxAngle(this.typeStep, this.stepWidth);
		}
		return 90;
	}
	getAngleDefault() {
		if (typeof this._fallingProtectionStandardRules === 'object') {
			return this._fallingProtectionStandardRules.getDefaultAngle(this.typeStep, this.stepWidth);
		}
	}
	setAngleDefault() {
		if (typeof this._fallingProtectionStandardRules === 'object') {
			this._angle = this.getAngleDefault();
		}
		this.angleDefault = true;
	}
	id = '';
	width = 0;
	type = 0;
	riser = false;
	yellowNose = false;
	coated = false;
	stairWell = new StairWell();
	_crossStairWell = false;
	_typeStep = '';

	get crossStairWell() {
		return this._crossStairWell;
	}

	set crossStairWell(value) {
		this._crossStairWell = value;
		if (value === true) {
			this.intermediateLandings.minWidthDepth(this.stepWidth * 2 + 100, 1000);
			this.intermediateLandings.updateAllLandings(this);
			this.endLanding.active = true;
		}
	}

	get typeStep() {
		return this._typeStep;
	}

	set typeStep(value) {
		this._typeStep = value;
		if (this.angleDefault === true) {
			this.setAngleDefault();
		}
	}
	_stepWidth = 0;
	get stepWidth() {
		return this._stepWidth;
	}
	set stepWidth(value) {
		this._stepWidth = value;
		if (this.angleDefault === true) {
			this.setAngleDefault();
		}
	}
	rasters = [];
	_upComing = 1;
	get upComing() {
		return this._upComing;
	}
	set upComing(value) {
		this._upComing = value;
	}

	etageHeight = 0; // bij indevloer is dit de hoogte van de etage zelf. Bij buitendevloer is dit de totale hoogte vanaf de grond.
	packetHeight = 0;
	startX = 0;
	startY = 0;
	step = {
		id: -1,
		caption: '',
		name: '',
		value: '',
		width: '',
		depth: '',
		minDegrees: '',
		maxDegrees: '',
		centerToCenter: '',
		defaultChoice: '',
		riserPossible: false,
		coatingPossible: false,
		yellowNosePossible: false,
	};

	stairDepth = 0;

	_fallingProtectionStandard = '';
	get fallingProtectionStandard() {
		return this._fallingProtectionStandard;
	}
	set fallingProtectionStandard(value) {
		this._fallingProtectionStandard = value;
		this._fallingProtectionStandardRules = new Standard(value);
		if (this.angleDefault === true) {
			this.angle = this._fallingProtectionStandardRules.getDefaultAngle();
		}

		this.typeStep = this.defaultStepType();
		this.stepWidth = this.defaultStepWidth();
		this.coated = this._fallingProtectionStandardRules.defaultCoated();
		this.riser = this._fallingProtectionStandardRules.defaultRiser();
		this.yellowNose = this._fallingProtectionStandardRules.defaultYellowNose();
	}

	place = 'outside';
	intermediateLandings = new IntermediateLandings();
	active = true;
	_disabledUpcomings = [];
	get disabledUpcomings() {
		return this._disabledUpcomings;
	}
	set disabledUpcomings(value) {
		this._disabledUpcomings = value;

		if (typeof this.endLanding !== 'undefined' && this.endLanding !== null) {
			this.endLanding._disabledUpcomings = value;
		}
	}

	get upComingPositions() {
		let upComings = [];

		// Hier logica aanhouden van upcoming positie voor de endlanding en stair positie.
		// Niet elke direction van stair kan op elke kan aan een raster.
		Stair.UPCOMING_POSITIONS.forEach((upComing) => {
			upComings.push(JSON.parse(JSON.stringify(upComing)));
			if (this.disabledUpcomings.includes(upComing.index)) {
				upComings[upComings.length - 1].enabled = false;
			} else {
				upComings[upComings.length - 1].enabled = true;
			}
		});

		return upComings;
	}
	get landings() {
		// voorlopig met getter opgelost omdat zonder trap geselecteerd de intermediate-landing.vue niet begrijpt dat er nog een functie is onder een subobject
		return this.intermediateLandings.getAll();
	}

	startRaster = { x: -1, y: -1 }; // bijhouden voor een eventuele fout in findraster. Dan blijft startRaster behouden zodat raveling wel getekend kan worden
	// objectsYMove = ['stairWell', 'trimmingTop', 'trimmingBottom', 'stairLeft', 'stairRight', 'stairTail', 'stairLanding']; // objecten die verplaatst worden als over de y-as wordt verplaatst
	// objectsXMove = ['stairWell', 'trimmingLeft', 'trimmingRight', 'stairLeft', 'stairRight', 'stairTail', 'stairLanding']; // objecten die verplaatst worden als over de x-as wordt verplaatst
	objectsYMinMaxHorizontal = ['stairWell', 'trimmingTop', 'trimmingBottom']; // objecten die verplaatst worden als over de y-as wordt verplaatst
	objectsYMinMaxVertical = ['stairWell', 'trimmingRight', 'trimmingLeft']; // objecten die verplaatst worden als over de y-as wordt verplaatst
	errors = new Errors();
	get hasErrors() {
		return this.errors.length > 0;
	}
	getErrors() {
		return this.errors;
	}
	boundaries = [];
	contextMenu = [
		{ icon: 'edit', action: this.edit.bind(this), active: true },
		{ icon: 'delete', action: this.remove.bind(this), active: true },
		{ icon: 'rotate_left', action: this.rotateLeft.bind(this), active: true },
		{ icon: 'rotate_right', action: this.rotateRight.bind(this), active: true },
	];
	getContextMenu() {
		// Wanneer plek buiten de vloer is en er is geen endlanding actief, dan kan er ook niet gedraait worden.
		if (this.place.toLocaleLowerCase() === 'outside' && this.endLanding.active === false) {
			this.contextMenu[2].active = false;
			this.contextMenu[3].active = false;
		} else {
			this.contextMenu[2].active = true;
			this.contextMenu[3].active = true;
		}
		return this.contextMenu;
	}

	onMouseMove(evt, object, canvas) {
		canvas.canvas.style.cursor = 'move';
		return { stopPropagation: true };
	}
	onMouseLeave(evt, object, canvas) {
		canvas.canvas.style.cursor = 'default';
		return { stopPropagation: true };
	}
	insertUniqueRaster(raster) {
		if (raster.x > -1 && raster.y > -1) {
			const rasterFound = this.rasters.find((rst) => rst.x === raster.x && rst.y === raster.y);
			if (typeof rasterFound === 'undefined') {
				this.rasters.push(raster);
			}
		}
	}

	addIntermediateLanding(fallingProtectionStandardAdded = false) {
		let lastLanding = this.intermediateLandings.getLast();
		// Standaard upcoming van toevoegen nieuwe landing in de edit-stair is tegenovergestelde van de trap upcoming.
		// TODO: Kan eventueel fout gaan bij de eerste landing als je niet weet wat de upComing van de stair is als deze nog niet geplaatst is.
		let upComing = Stair.toOppositeUpComing(this.upComing);
		let width = 0;
		let depth = 0;
		let height = this.etageHeight;
		let maxHeight = height;
		let landingType = this.crossStairWell ? IntermediateLandings.oneeightyDegrees : IntermediateLandings.straightAhead;

		let finish = null;
		let ctc = null;

		// Dan kijken we naar de vorige landing als die er is.
		// Zo ja, dan nemen we de upcoming en data van de vorige landing over. (Hij wijst dan dezelfde kant op).
		if (lastLanding !== null && typeof lastLanding !== 'undefined') {
			upComing = lastLanding.upComing;
			height = lastLanding.height;
			width = lastLanding.width;
			depth = lastLanding.depth;
			finish = lastLanding.finish;
			ctc = lastLanding.ctc;

			// Normaal neemt hij de vorige landing upComing over,
			// Maar bij corssstairwell dan moeten juist het tegenovergestelde gebruiken van de vorige landing
			if (this.crossStairWell) {
				upComing = Stair.toOppositeUpComing(upComing);
			}
		}
		// Wanneer geen vorige landing.
		else {
			// Wanneer crosStairwell dan krijgen de landings minimale breedte en diepte.
			if (this.crossStairWell === true) {
				width = this.stepWidth * 2 + 100;
				depth = this._fallingProtectionStandardRules.getMinDepthLanding(this.stepWidth);

				// Als er een upcoming word toegevoegd en het is de eerste, dan dezelfde upcoming pakken als die van de endlanding.
				// Bij eerste landing en het is stairwell dan is het tegenovergestelde van de upComing.
				upComing = Stair.toOppositeUpComing(this.endLanding.upComing);
				landingType = IntermediateLandings.oneeightyDegrees;
				if (this.objectName !== 'StairOutSide') {
					// Logica voor infloor precies omgekeerd, dus dan gewoon overnemen van stair/endlanding upComing.
					upComing = Stair.toOppositeUpComing(this.upComing);
				}
			} else if (typeof this._fallingProtectionStandardRules === 'object') {
				width = this._fallingProtectionStandardRules.getMinWidthLanding(this.stepWidth);
				depth = this._fallingProtectionStandardRules.getMinDepthLanding(this.stepWidth);
				maxHeight = this._fallingProtectionStandardRules.getMaxHeightLanding(this.etageHeight, this.angle);
				if (this.endLanding.active === true) {
					// Wanneer endlanding actief is en er is geen vorige intermediate landing, dan dezelfde kant op als de endlanding.
					// Dan ook updaten van het type landing.
					upComing = this.endLanding.upComing;
					landingType = IntermediateLandings.straightAhead;
				}
			}
		}

		let landingHeight = height / 2;
		if (height - landingHeight > maxHeight) {
			landingHeight = height - maxHeight;
		}

		let etageId = Configuration.CURRENT.etages.getByGivenHeight(landingHeight);
		this.intermediateLandings.addLanding(Math.round(landingHeight), width, depth, upComing, fallingProtectionStandardAdded, false, etageId, landingType, finish, ctc);

		// Wanneer crossstair dan moeten we de landings opnieuw verdelen zodat de hoogtes tussen elkaar even groot zijn.
		if (this.crossStairWell === true) {
			this.intermediateLandings.recalculateLandingHeights(this);
		}

		this.validate();
	}

	changeEndLandingActive(value) {}
	setUpComing(value) {
		this.upComing = value;
	}

	calculateAmount(params) {}
	onMouseDown(evt, object, canvas) {
		return { stopPropagation: true };
	}

	setObjectSizeHandle(canvas) {}
	onClickPossiblePosition(evt, object, canvas, params, stairSettings, stairs) {}
	moveProfilePossible(params) {
		return true;
	}
	edit() {
		Configuration.CURRENT.contextMenu.hide();
		if (typeof this._edit === 'function') {
			this._edit(this);
		}
	}
	remove() {
		Configuration.CURRENT.contextMenu.hide();
		if (typeof this._remove === 'function') {
			this._remove(this);
		}
	}
	rotateLeft() {
		this.upComing -= 1;
		if (this.upComing <= 0) {
			this.upComing = 4;
		}

		// Wanneer endlanding actief dan deze mee updaten, endlanding upcoming = stair upcoming.
		if (this.endLanding.active) {
			this.endLanding.rotateLeft();
			this.endLanding.update(this.crossStairWell, this.position, this.endLanding.upComing, this.stepWidth, this.intermediateLandings);
		}

		this.intermediateLandings.get().forEach((landing) => {
			landing.rotateLeft();
		});
		// Wanneer nieuwe upcoming gelijk is aan tegenovergestelde van de stairpositie dan een keer extra roteren.
		if (Stair.toOppositeUpComing(this.upComing) === this.position) {
			this.rotateLeft();
		}
		this.onChange();
	}
	rotateRight() {
		this.upComing += 1;
		if (this.upComing > 4) {
			this.upComing -= 4;
		}
		// Wanneer endlanding actief dan deze mee updaten, endlanding upcoming = stair upcoming.
		if (this.endLanding.active) {
			this.endLanding.rotateRight();
			this.endLanding.update(this.crossStairWell, this.position, this.endLanding.upComing, this.stepWidth, this.intermediateLandings);
		}
		this.intermediateLandings.get().forEach((landing) => {
			landing.rotateRight();
		});
		// Wanneer nieuwe upcoming gelijk is aan tegenovergestelde van de stairpositie dan een keer extra roteren.
		if (Stair.toOppositeUpComing(this.upComing) === this.position) {
			this.rotateRight();
		}
		this.onChange();
	}
	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		if (this.rasters.length > 0 && ((raster.x > -1 && raster.x === this.rasters[0].x) || (raster.y > -1 && raster.y === this.rasters[0].y))) {
			drawObject.drawObjects.forEach((drawObjectItem) => {
				if (
					(drawObjectItem.objectParams.type === 'trimmingRight' || drawObjectItem.objectParams.type === 'trimmingLeft') &&
					delta.x === 0 &&
					Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL
				) {
					// en horizontaal
					drawObjectItem.x.value += delta.x;
					drawObjectItem.y.value += delta.y;
					drawObjectItem.height.value -= delta.y;
					drawObjectItem.width.value -= delta.x;
				}
				if (
					(drawObjectItem.objectParams.type === 'trimmingTop' || drawObjectItem.objectParams.type === 'trimmingBottom') &&
					delta.y === 0 &&
					Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL
				) {
					// en verticaal
					drawObjectItem.x.value += delta.x;
					drawObjectItem.y.value += delta.y;
					drawObjectItem.height.value -= delta.y;
					drawObjectItem.width.value -= delta.x;
				}
			});
		}

		if (this.rasters.length > 0 && ((raster.x > -1 && raster.x - 1 === this.rasters[0].x) || (raster.y > -1 && raster.y - 1 === this.rasters[0].y))) {
			drawObject.drawObjects.forEach((drawObjectItem) => {
				if (
					(drawObjectItem.objectParams.type === 'trimmingRight' || drawObjectItem.objectParams.type === 'trimmingLeft') &&
					delta.x === 0 &&
					Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL
				) {
					// en horizontaal
					drawObjectItem.height.value += delta.y;
					drawObjectItem.width.value += delta.x;
				}
				if (
					(drawObjectItem.objectParams.type === 'trimmingTop' || drawObjectItem.objectParams.type === 'trimmingBottom') &&
					delta.y === 0 &&
					Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL
				) {
					// en verticaal
					drawObjectItem.height.value += delta.y;
					drawObjectItem.width.value += delta.x;
				}
			});
		}
	}
	findRaster() {
		// bepaal bijbehorend raster linksboven
		const rasterLeftTop = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX, this.startY);
		this.rasters = [];
		this.insertUniqueRaster(rasterLeftTop);

		// bepaal bijbehorend raster rechtsboven
		const rasterRightTop = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX + this.stairDrawWidth, this.startY);
		this.insertUniqueRaster(rasterRightTop);

		// bepaal bijbehorend raster rechtsboven
		const rasterLeftBottom = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX, this.startY + this.stairDrawHeight);
		this.insertUniqueRaster(rasterLeftBottom);
		// bepaal bijbehorend raster rechtsonder
		const rasterRightBottom = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX + this.stairDrawWidth, this.startY + this.stairDrawHeight);
		this.insertUniqueRaster(rasterRightBottom);
		if (this.rasters.length === 0) {
			this.rasters.push(this.startRaster);
		}
	}

	collisions(boundaries, self) {
		let errors = new Errors();
		if (this.id === self.id || this.active === false) {
			// zichzelf dus geen collision
			return { result: false, errors: errors, objectName: this.objectName };
		}
		let overlap = false;

		boundaries.forEach((boundary) => {
			this.boundaries.forEach((boundarySelf) => {
				if (Mathematic.overlapRectangles(boundary.topLeft, boundary.bottomRight, boundarySelf.topLeft, boundarySelf.bottomRight) === true) {
					overlap = true;
				}
			});
		});

		if (overlap === true) {
			errors.push(new CustomError(window.Vue.$translate('collision.stair'), Errors.ERRORTYPE.collision, this.objectName));
		}

		return { result: errors.length > 0, errors: errors, objectName: this.objectName };
	}
	onRasterChanged(params) {
		if (this.rasters.length > 0) {
			// als rasters bepaald zijn
			this.active = this.rastersActive();
		}

		// Opnieuw ophalen van juiste etagehoogte,
		// Dit kan veranderd zijn door:
		// Hoogte etage aanpassen, Ander type hoofdbalk, verandering rastergroote waardoor balken anders zijn geworden.
		let newEtageHeight = 0;
		if (this.place === 'outside') {
			Configuration.CURRENT.etages.etages.forEach((etage, index) => {
				newEtageHeight += etage.getHeight(true);
				if (index === this.etageIndex) {
					this.etageHeight = newEtageHeight;
				}
			});
		}

		this.validate();
		if (this.currentMainBeamDirection !== Configuration.CURRENT.profiles.mainBeamDirection) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				let start = Configuration.CURRENT.raster.spansY.getSize(this.rasters[0].y - 1);
				let height = Configuration.CURRENT.raster.spansY.get(this.rasters[0].y).value;
				if (this.startY + this.stairDrawHeight >= start + height) {
					this.startY = start + (height - this.stairDrawHeight) / 2;
					this.findRaster();
					this.validate();
				}
			} else {
				let start = Configuration.CURRENT.raster.spansX.getSize(this.rasters[0].x - 1);
				let width = Configuration.CURRENT.raster.spansX.get(this.rasters[0].x).value;
				if (this.startX + this.stairDrawWidth >= start + width) {
					this.findRaster();
					this.validate();
				}
			}

			this.currentMainBeamDirection = Configuration.CURRENT.profiles.mainBeamDirection;
		}
		if (typeof params.changedParams !== 'undefined' && params.changedParams !== null) {
			if (typeof this.updateStairPositionOnRasterChanged !== 'undefined' && this.updateStairPositionOnRasterChanged !== null) {
				this.updateStairPositionOnRasterChanged(params);
			}
		}
		// Valideren en checken op collisions
		this.validate();
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this._checkCollisions = params.checkCollisions;
		this._edit = params.edit;
		this._remove = params.remove;
		params.rasters = this.rasters;
	}
	removeReferences() {
		this._onChange = null;
		this._checkCollisions = null;
		this._edit = null;
		this._remove = null;
	}
	onChange() {
		this.calculate();
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}
	collisionCheck() {
		let collisionCheck = this.checkCollisions(this.boundaries, this);
		let hasNowErrors = this.hasErrors;
		// Wanneer het een ouside stair is en het raster is niet actief dan is er geen error.
		if (this.place === 'outside' && this.rastersActive() === false) {
			collisionCheck = {
				result: false,
				errors: [],
			};
		} else if (this.rastersActive() === false) {
			this.errors.add(new CustomError(window.Vue.$translate('stair.inInActiveRaster'), 2, 'stairInFloor', null));
		}
		if (collisionCheck.result) {
			this.errors.clear(Errors.ERRORTYPE.collision);
			collisionCheck.errors.forEach((error) => {
				this.errors.add(error);
			});
		} else {
			this.errors.clear(Errors.ERRORTYPE.collision);
		}
		if (hasNowErrors !== this.hasErrors) {
			let drawObject = Configuration.CURRENT.canvas.drawObjects.get(this.drawObject);
			if (drawObject !== null) {
				this.setCollisionDrawObject(drawObject.drawObjects, this.hasErrors);
			}
		}

		return collisionCheck;
	}
	checkCollisions(boundaries, self) {
		if (typeof this._checkCollisions === 'function') {
			return this._checkCollisions(boundaries, self);
		}
	}
	select(parameters, canvas) {
		if (this.id === parameters.id) {
			this.selected = !this.selected;
		} else {
			// Wanneer nieuwe object niet dit object is dan is het een deselect.
			this.selected = false;
		}
	}
	onClick(evt, object, canvas) {
		Configuration.CURRENT.select({ id: this.id }, canvas);
		return { stopPropagation: true, object: this };
	}

	setCollisionDrawObject(drawObjects, collisions) {
		drawObjects.forEach((drawObject) => {
			// na collisions-check nogmaals objecten doorlopen om kleur goed te zetten.
			if (collisions === true) {
				drawObject.lineColor = Stairs.COLORS.stairCollisions;
				if (drawObject.objectParams.type !== 'stairWell') {
					// stairWell is leegvak. Moet zo blijven andere moeten rood worden
					drawObject.fillColor = Stairs.COLORS.stairCollisions;
				}
			} else {
				drawObject.lineColor = drawObject.object.selected ? drawObject.objectParams.selected : drawObject.objectParams.color;
				drawObject.fillColor = drawObject.object.selected ? drawObject.objectParams.selected : drawObject.objectParams.color;
			}
		});
	}

	_stairDrawWidth = 0;

	get stairDrawWidth() {
		return this.stairWell.width;
	}
	set stairDrawWidth(value) {
		this._stairDrawWidth = value;
	}

	get stairDrawHeight() {
		return this.stairWell.depth;
	}
	getAmountData(compare = true) {
		let landingData = [];
		let height = this.etageHeight;
		let nextLanding = this.intermediateLandings.get(0);
		if (typeof nextLanding !== 'undefined' && nextLanding !== null) {
			height -= nextLanding.height;
		}
		let totalLengthStair = parseFloat((height / Math.sin((this.angle * Math.PI) / 180)).toFixed(2));
		this.intermediateLandings.get().forEach((landing, index) => {
			if (landing.active === true) {
				let currentLanding = landing.getAmountData(compare);
				landingData.push(currentLanding);

				let landingHeight = currentLanding.height;
				let nextLanding = this.intermediateLandings.get(index + 1);
				if (typeof nextLanding !== 'undefined' && nextLanding !== null) {
					landingHeight -= nextLanding.height;
				}
				totalLengthStair += parseFloat((landingHeight / Math.sin((this.angle * Math.PI) / 180)).toFixed(2));
			}
		});
		let standardId = 0;
		let standard = Store.CURRENT.fallingProtectionStandards.getStandard(this.fallingProtectionStandard);
		if (standard !== null) {
			standardId = standard.id;
		}
		return {
			id: this.id,
			name: this.objectName,
			angle: this.angle,
			riser: this.riser,
			yellowNose: this.yellowNose,
			coated: this.coated,
			step: this.step.id,
			etageHeight: this.etageHeight,
			endLanding: this.endLanding.active ? this.endLanding.getAmountData(compare) : null,
			height: height,
			color: Configuration.CURRENT.colors.stairs.ralColor,
			finish: Configuration.CURRENT.colors.stairs.finish,
			mainBeam: Configuration.CURRENT.profiles.mainBeam.salesProductId,
			childBeam: Configuration.CURRENT.profiles.childBeam.salesProductId,
			column: Configuration.CURRENT.columns.column.salesProductId,
			typeStair: this.type,
			railType: Configuration.CURRENT.handRailType,
			lengthStair: totalLengthStair,
			intermediateLandings: landingData,
			standard: standardId,
			stepWidth: this.stepWidth,
			amountSteps: this.amountSteps,
			optional: this.optional,
			crossStairWell: this.crossStairWell,
			amount: 1,
		};
	}
	update(stair) {
		let stairData = Store.CURRENT.stairs.getItem(stair.stairOid);

		this.newStair = stair.newStair;
		this.stairPlace = stair.stairPlace;
		this.fallingProtectionStandard = stair.fallingProtectionStandard;
		this.currentMainBeamDirection = Configuration.CURRENT.profiles.mainBeamDirection;
		this.etageId = stair.etageId;
		this.etageIndex = stair.etageIndex;
		this.crossStairWell = stair.crossStairWell;
		if (typeof stair.angle === 'undefined' || stair.angle === null) {
			this.setAngleDefault();
		} else {
			this.angle = stair.angle;
		}
		this.width = stair.width;
		this.stepWidth = stair.stepWidth;

		this.upComing = stair.upComing;
		this.type = stair.type;
		this.optional = stair.optional;
    this.stairOid = stair.stairOid;
		if (typeof stair.step === 'undefined' || stair.step === null) {
			stair.typeStep = this.defaultStepType();
		} else {
			this.typeStep = stair.typeStep;
		}

		this.etageHeight = stair.etageHeight;
		this.packetHeight = stair.packetHeight;

		this.place = stair.place;
		this.disabledUpcomings = stair.disabledUpcomings;
		this.material = stairData.material;

		this.intermediateLandings.clear();
		if (typeof stair.intermediateLandings !== 'undefined') {
			stair.intermediateLandings.get().forEach((landing, index) => {
				this.intermediateLandings.addLanding(
					landing.height,
					landing.width,
					landing.depth,
					landing.upComing,
					landing.fallingProtectionStandardAdded,
					landing.etageHeightLanding,
					landing.etageId,
					landing.landingType,
					landing.finish,
					landing.ctc,
				);
			});
		}

		if (typeof stair.endLanding === 'undefined' || stair.endlanding === null) {
			let width = 0;
			let depth = 0;

			if (typeof this._fallingProtectionStandardRules === 'object') {
				width = this._fallingProtectionStandardRules.getMinWidthLanding(this.stepWidth);
				depth = this._fallingProtectionStandardRules.getMinDepthLanding(this.stepWidth);
			}
			this.endLanding = new EndLanding(
				0,
				width,
				depth,
				this.upComing,
				this.disabledUpcomings,
				(value) => {
					this.setUpComing(value);
				},
				(value) => {
					this.disableUpcomings = value;
				},
				null,
				null,
				stair.etageId,
				stair.position === this.upComing || stair.position === Stair.toOppositeUpComing(this.upComing) ? IntermediateLandings.straightAhead : IntermediateLandings.ninetyDegrees,
			);
			this.endLanding.active = false;
			// Updaten Endlanding breedte bij crossstairwell.
			this.endLanding.update(stair.crossStairWell, stair.position, stair.upComing, stair.stepWidth, this.intermediateLandings);
		} else {
			this.endLanding = stair.endLanding;

			this.endLanding._setUpcoming = (value) => {
				this.setUpComing(value);
			};
			this.endLanding._setDisabledUpcomings = (value) => {
				this.disableUpcomings = value;
			};

			// Updaten Endlanding breedte bij crossstairwell.
			this.endLanding.update(stair.crossStairWell, stair.position, stair.upComing, stair.stepWidth, this.intermediateLandings);

			this.endLanding.landingType =
				this.position === this.upComing || this.position === Stair.toOppositeUpComing(this.upComing) ? IntermediateLandings.straightAhead : IntermediateLandings.ninetyDegrees;
		}
		this.validate();
	}
	constructor(newStairConfiguration) {
		this.id = Functions.uuidv4();
		if (typeof newStairConfiguration !== 'undefined' && newStairConfiguration !== null) {
			this.update(newStairConfiguration);
		}
	}
	validate(object) {
		//Wanneer trap niet actief door Outside waarbij het raster aanstaat, of Infloor tussen een niet actief raster staat.
		if (!this.active) {
			return;
		}
		this.calculate();
		let validateErrors = [];
		if (typeof this._fallingProtectionStandardRules === 'object') {
			validateErrors = this._fallingProtectionStandardRules.validate(this);
		}
		this.errors.clear(Errors.ERRORTYPE.validate);
		validateErrors.forEach((error) => {
			this.errors.add(error, Errors.ERRORTYPE.validate);
		});
	}
	defaultStepType() {
		if (typeof this._fallingProtectionStandardRules === 'object') {
			return this._fallingProtectionStandardRules.defaultStepType();
		}
		return '';
	}
	defaultStepWidth() {
		if (typeof this._fallingProtectionStandardRules === 'object') {
			return this._fallingProtectionStandardRules.defaultStepWidth();
		}
		return '';
	}
	onMouseMovePossiblePosition(evt, object, canvas) {}
	onMouseLeavePossiblePosition(evt, object, canvas) {}
	afterReconstruct() {
		if (typeof this.endLanding !== 'undefined' && this.endLanding !== null) {
			this.endLanding._setUpcoming = (value) => {
				this.setUpComing(value);
			};
			this.endLanding._setDisabledUpcomings = (value) => {
				this.disableUpcomings = value;
			};
		}
		this.contextMenu[0].action = this.edit.bind(this);
		this.contextMenu[1].action = this.remove.bind(this);
		this.contextMenu[2].action = this.rotateLeft.bind(this);
		this.contextMenu[3].action = this.rotateRight.bind(this);
		this._fallingProtectionStandardRules = new Standard(this._fallingProtectionStandard);
		this.calculate();

		// voorheen werd er gecheckt op type van de stair, wanneer de gebruiker andere company selecteerd
    // komt type niet meer overeen met item(s) uit de lijst. Vandaar dat er nu gekeken wordt naar stairOid
    let stairData = Store.CURRENT.stairs.getItem(this.type, true);

    // wanneer het ophalen dmv. type niet undefined is en de stairOid 0 is (oude en al bestaande stair)
    // Gebruiker is in deze situatie gewijzigd van company (dus deze item bestaat niet in de lijst)
    if (typeof stairData.material !== 'undefined' && this.stairOid === 0) {
      this.material = stairData.material;
    } else if (this.stairOid !== 0) {
      // wanneer de stairOid inmiddels gezet is, wordt deze opgeslagen.
      stairData = Store.CURRENT.stairs.getItem(this.stairOid);
      this.material = stairData.material;
    } else {
      // wanneer geen type en geen stairOid dan wordt de eerste uit de lijst geselecteerd en update uitgevoerd om vervolgens type en stairOid te setten.
      let newStair = Store.CURRENT.stairs._list.list[0];
      this.type = newStair.value;
      this.stairOid = newStair.stairOid;
      this.update(this)
    }
	}
	rastersActive() {
		let active = true;
		this.rasters.forEach((raster) => {
			if (Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(raster.x, raster.y)) === false) {
				active = false;
			}
		});
		return active;
	}
	addDrawObjects(canvas, params) {}

	drawObjectsStair(stairGroup, upComingInFloor, boundary, stairDepth, inFloor, landing, nextLanding) {}

	calculate() {
		this.stairWell.calculate(this.startX, this.startY, this.etageHeight, this.packetHeight, this.width, this.angle, this.upComing, this.intermediateLandings, this.place);

		if (typeof this._fallingProtectionStandardRules === 'object') {
			let height = this.etageHeight;
			let nextLanding = this.intermediateLandings.get(0);
			if (nextLanding !== null) {
				height -= nextLanding.height;
			}

			this.amountSteps = Math.ceil(height / this._fallingProtectionStandardRules.getMaxRise(this.angle)) + 1;

			this.rise = Math.round((height / this.amountSteps) * 100) / 100;
			this.amountSteps += this.intermediateLandings.amountSteps(this._fallingProtectionStandardRules.getMaxRise(this.angle));
		}
		if (typeof this._fallingProtectionStandardRules === 'object') {
			let width = this._fallingProtectionStandardRules.getMinWidthLanding(this.stepWidth);
			let depth = this._fallingProtectionStandardRules.getMinDepthLanding(this.stepWidth);
			this.intermediateLandings.minWidthDepth(width, depth);
		}

		this.setStep();
		this.setStepRetry = 0;
	}
	setStep() {
		this.step = JSON.parse(JSON.stringify(Store.CURRENT.stairSteps.getItem(this.typeStep, this.stepWidth, this.angle)));
		if (this.step.id === -1) {
			this.step.name = window.Vue.$translate('stair.error.stepNotFound');
		}
		if (this.step.riserPossible === false) {
			this.riser = false;
		}
		if (this.step.coatingPossible === false) {
			this.coated = false;
		}
		if (this.step.yellowNosePossible === false) {
			this.yellowNose = false;
		}
	}
	inRaster(raster) {
		return false;
	}

	addDrawObjects3d(canvas3d, params) {}
	create3DAssets(canvas3d) {
		console.log('CREATE 3D ASSETS STAIR');
	}

	getLandingWidth3D(landing = null, isEndLanding = false) {
		if (landing === null) {
			return 0;
		}
		// Wanneer het de eindlanding betreft dan is de width en depth al geshift
		if (isEndLanding) {
			if (landing.upComing === Stair.UPCOMING_TOP || landing.upComing === Stair.UPCOMING_BOTTOM) {
				return landing.width;
			}
			if (landing.upComing === Stair.UPCOMING_LEFT || landing.upComing === Stair.UPCOMING_RIGHT) {
				return landing.depth;
			}
		}
		// Wanneer het een tussenlanding is zijn de breedtes en dieptes niet geswichted
		else {
			if (landing.upComing === Stair.UPCOMING_TOP || landing.upComing === Stair.UPCOMING_BOTTOM) {
				return landing.width;
			}
			if (landing.upComing === Stair.UPCOMING_LEFT || landing.upComing === Stair.UPCOMING_RIGHT) {
				return landing.depth;
			}
		}
	}
	getLandingDepth3D(landing = null, isEndLanding = false) {
		if (landing === null) {
			return 0;
		}
		// Wanneer het de eindlanding betreft dan is de width en depth al geshift
		if (isEndLanding) {
			if (landing.upComing === Stair.UPCOMING_TOP || landing.upComing === Stair.UPCOMING_BOTTOM) {
				return landing.depth;
			}
			if (landing.upComing === Stair.UPCOMING_LEFT || landing.upComing === Stair.UPCOMING_RIGHT) {
				return landing.width;
			}
		}
		// Wanneer het een tussenlanding is zijn de breedtes en dieptes niet geswichted
		else {
			if (landing.upComing === Stair.UPCOMING_TOP || landing.upComing === Stair.UPCOMING_BOTTOM) {
				return landing.depth;
			}
			if (landing.upComing === Stair.UPCOMING_LEFT || landing.upComing === Stair.UPCOMING_RIGHT) {
				return landing.width;
			}
		}
	}
}

import { Box3D } from '../Box3D';
import { Canvas3D } from '../Canvas3D';
import { Configuration } from '../../configurator/configuration';
import { jsonFinish } from '../../configurator/hicad/jsonFinish';
export class Grating3D {
	objectName = 'Grating3D';
	oid = 0;
	constructor(x, y, params) {
		// alleen wanneer deze van een stair afkomstig is
		if (typeof params.stairData !== 'undefined') {
			this.oid = params.stairData.material.gratingLandingFinish;
		}

		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		params.drawHeight -= deckingFinishHeight;
		let gratingWidth = Configuration.CURRENT.finish.selected.height;
		let amountOfStripsV = params.width / gratingWidth;
		let amountOfStripsH = params.depth / gratingWidth;
		let xDirection = x;
		let yDirection = y;

		for (let i = 1; i <= amountOfStripsV; i++) {
			Canvas3D.CURRENT.addDrawObject(
				new Box3D(xDirection, y, {
					height: gratingWidth,
					width: 3.3,
					depth: params.depth,
					drawY: params.drawHeight,
					ralColor: 7016,
					layers: params.layers,
					invisible: false,
				}),
				Canvas3D.TYPE_FINISH,
			);
			xDirection = x + gratingWidth * i;
		}

		// last vertical
		amountOfStripsV++;
		xDirection = x + gratingWidth * amountOfStripsV - gratingWidth;
		Canvas3D.CURRENT.addDrawObject(
			new Box3D(xDirection, y, {
				height: gratingWidth,
				width: 3.3,
				depth: params.depth,
				drawY: params.drawHeight,
				ralColor: 7016,
				layers: params.layers,
				invisible: false,
			}),
			Canvas3D.TYPE_FINISH,
		);

		for (let i = 1; i <= amountOfStripsH; i++) {
			Canvas3D.CURRENT.addDrawObject(
				new Box3D(x, yDirection, {
					height: gratingWidth,
					width: params.width,
					depth: 3.3,
					drawY: params.drawHeight,
					ralColor: 7016,
					layers: params.layers,
					invisible: false,
				}),
				Canvas3D.TYPE_FINISH,
			);
			yDirection = y + gratingWidth * i;
		}

		// last horizontal
		amountOfStripsH++;
		yDirection = y + gratingWidth * amountOfStripsH - gratingWidth;
		Canvas3D.CURRENT.addDrawObject(
			new Box3D(x, yDirection, {
				height: gratingWidth,
				width: params.width,
				depth: 3.3,
				drawY: params.drawHeight,
				ralColor: 7016,
				layers: params.layers,
				invisible: false,
			}),
			Canvas3D.TYPE_FINISH,
		);
	}

	convert() {
		return new jsonFinish(this, jsonFinish.TYPE_GRATING);
	}
}

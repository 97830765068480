export class HicadDrawObject {
	x = 0.0;
	y = 0.0;
	z = 0.0;
	rotationX = 0.0;
	rotationY = 0.0;
	rotationZ = 0.0;
	oid = 0;
	ralColor = '7016';
	hicadPosition = {
		x: 0.0,
		y: 0.0,
		z: 0.0,
	};
	scale = {
		x: 0.0,
		y: 0.0,
		z: 0.0,
	};
	objectName = '';

	constructor(object) {
		Object.keys(this).forEach((item) => {
			Object.keys(object).forEach((obj) => {
				if (item === obj) {
					this[item] = object[obj];
				}
			});
		});

		this.hicadPosition = {
			x: object.x * 1000,
			y: object.z * 1000,
			z: object.y * 1000,
		};

		this.scale = {
			x: object.width,
			y: object.height,
			z: object.depth,
		};
	}
}

import {Model3D} from '../Model3D';
import {JsonColumnProtector} from '@/configurator/hicad/jsonColumnProtector';
import {Store} from '../../data/store';
import {Configuration} from "../../configurator/configuration";
import {Profiles} from "@/configurator/profiles";
import {Columns} from "../../configurator/columns";
import {Canvas3D} from '../Canvas3D';

export class ColumnProtector3D extends Model3D {
  objectName = 'ColumnProtector3D';
  ralColor = 1003;

  constructor(x, y, z, params) {
    super(x, y, z, params);

    this.assetName = params.info.id;
    // id was undefined, is aangepast naar position, omdat de functie getOidByPosition is.
    this.oid = Store.CURRENT.columnProtectorsModels.getByColumnProtectorOidByPosition(params.info.position);

    this.rowPositions = params.info.column.rowPositions;
    this.protectorData = params.info;
  }

  draw(app) {
    let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
    // Goed zetten van de protector op basis van de rowPosition van de kolom.
    if (typeof this.rowPositions !== 'undefined' && this.rowPositions !== null) {
      if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
        // Alle linker baseplates (verticaal) 1/3e naar rechts
        if (this.rowPositions.includes(Columns.POSITION_LEFT)) {
          this.x += columnWidth / 3;
        }
        // Alle rechter baseplates (verticaal) 1/3e naar links
        if (this.rowPositions.includes(Columns.POSITION_RIGHT)) {
          this.x -= columnWidth / 3;
        }
      } else if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
        // Onderste rij baseplates 1/3e naar boven
        if (this.rowPositions.includes(Columns.POSITION_BOTTOM)) {
          this.z -= columnWidth / 3;
        }
        // De bovenste rij baseplate 1/3e naar beneden.
        if (this.rowPositions.includes(Columns.POSITION_TOP)) {
          this.z += columnWidth / 3;
        }
      }
    }
    super.draw(app);
  }

  convert() {
    // converteer ColumnProtector3D naar JsonColumnProtector voor webviewer en hicad
    return new JsonColumnProtector(this);
  }
}

// import { Canvas3D } from '../../Canvas3D';

import { Stair } from '../../../../configurator/stair';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class YellowNose3D extends Model3D {
	objectName = 'YellowNose3D';
	assetName = 'yellownose';
	constructor(x, y, params) {
		super(x, 0, y, params);
	}
	draw(app) {
		let stepWidth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.step.articleId, 'width');
		let yellowNoseDepth = Canvas3D.CURRENT.getModelData('yellownose', 'depth');
		let yellowNoseHeight = Canvas3D.CURRENT.getModelData('yellownose', 'height');

		this.width = this.stairData.step.width;

		if (this.shared.upComing === Stair.UPCOMING_LEFT) {
			this.x -= stepWidth;
			this.x += yellowNoseDepth / 3;
		} else if (this.shared.upComing === Stair.UPCOMING_BOTTOM) {
			this.z += stepWidth;
			this.z -= yellowNoseDepth / 3;
		} else if (this.shared.upComing === Stair.UPCOMING_TOP) {
			this.z -= stepWidth;
			this.z += yellowNoseDepth / 3;
		} else if (this.shared.upComing === Stair.UPCOMING_RIGHT) {
			this.x += stepWidth;
			this.x -= yellowNoseDepth / 3;
		}
		this.y += yellowNoseHeight / 3;

		this.x = this.x * 1000;
		this.z = this.z * 1000;
		this.y = this.y * 1000;

		// super.draw(app);
	}
}

import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
import { HandrailMiddlePlateHolderCorner3D } from './handrailplate/handrailplateholder/HandrailMiddlePlateHolderCorner3D';
import { HandrailMiddlePlateHolderLeft3D } from './handrailplate/handrailplateholder/HandrailMiddlePlateHolderLeft3D';
import { HandrailMiddlePlateHolderRight3D } from './handrailplate/handrailplateholder/HandrailMiddlePlateHolderRight3D';
import { HandRailPoleCapPlate3D } from './HandRailPoleCapPlate3D';
import { HandRailPoleCapPlateMiddle3D } from './HandRailPoleCapPlateMiddle3D';

export class HandRailPole3D extends Model3D {
	objectName = 'HandRailPole3D';
	width = 1;
	depth = 1;
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.oid = params.handRailMaterials.post;

		if (params.cornerPole) {
			Canvas3D.CURRENT.addDrawObject(new HandRailPoleCapPlate3D(x, y, z, params), Canvas3D.TYPE_PLATE);

			// Houder van de middenplaat aan de buitenpaaltjes
			// Niet bij kindvriendelijk.
			if (params.childFriendly === false) {
				// Canvas3D.CURRENT.addDrawObject(new HandrailMiddlePlateHolderCorner3D(x, y, z, params), Canvas3D.TYPE_PLATE);
			}
		} else {
			// Bovenplaatje en houders voor de middelpaaltjes
			// Canvas3D.CURRENT.addDrawObject(new HandRailPoleCapPlateMiddle3D(x, y, z, params), Canvas3D.TYPE_PLATE);

			// Niet bij kindvriendelijk.
			if (params.childFriendly === false) {
				// Houder van de middenplaat links / boven
				// Canvas3D.CURRENT.addDrawObject(new HandrailMiddlePlateHolderLeft3D(x, y, z, params), Canvas3D.TYPE_PLATE);
				// Houder van de middenplaat rechts / onder
				// Canvas3D.CURRENT.addDrawObject(new HandrailMiddlePlateHolderRight3D(x, y, z, params), Canvas3D.TYPE_PLATE);
			}

			// Aan elk paaltje een baseplate toevoegen
			// Canvas3D.CURRENT.addDrawObject(new HandRailPoleBasePlate3D(x, y, params));
		}
	}
	draw(app) {
		this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;
		// Standaard is paaltje 1 meter.
		this.height = 1000;
		if (this.cornerPole) {
			this.height += 100; // Paaltjes op de hoeken 1 meter 10
		} else {
			this.height += 50; // Paaltjes niet op de hoekjes is 1.05, daarboven komt een plaatje van 5 cm.
		}
		super.draw(app);
	}
}

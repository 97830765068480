import * as pc from 'playcanvas';
let FirstPersonMovement = pc.createScript('firstPersonMovement');

FirstPersonMovement.attributes.add('camera', {
	type: 'entity',
	description: 'Optional, assign a camera entity, otherwise one is created',
});

FirstPersonMovement.attributes.add('power', {
	type: 'number',
	default: 1,
	description: 'Adjusts the speed of player movement',
});

FirstPersonMovement.attributes.add('lookSpeed', {
	type: 'number',
	default: 0.2,
	description: 'Adjusts the sensitivity of looking',
});

// initialize code called once per entity
FirstPersonMovement.prototype.initialize = function () {
	this.force = new pc.Vec3();
	this.eulers = new pc.Vec3();

	let app = this.app;

	// Listen for mouse move events
	app.mouse.on('mousemove', this._onMouseMove, this);

	// when the mouse is clicked hide the cursor
	app.mouse.on(
		'mousedown',
		function () {
			app.mouse.enablePointerLock();
		},
		this,
	);

	// Check for required components
	if (!this.entity.collision) {
		console.error("First Person Movement script needs to have a 'collision' component");
	}

	if (!this.entity.rigidbody || this.entity.rigidbody.type !== pc.BODYTYPE_DYNAMIC) {
		console.error("First Person Movement script needs to have a DYNAMIC 'rigidbody' component");
	}
};

// update code called every frame
FirstPersonMovement.prototype.update = function (dt) {
	// If a camera isn't assigned from the Editor, create one
	if (!this.camera) {
		this._createCamera();
	}

	let force = this.force;
	let app = this.app;

	// Get camera directions to determine movement directions
	let forward = this.camera.forward;
	let right = this.camera.right;

	// // movement
	let x = 0;
	let z = 0;

	let speed = 75;

	if (app.keyboard.isPressed(pc.KEY_SHIFT)) {
		speed = 25;
	}

	if (app.keyboard.isPressed(pc.KEY_A) || app.keyboard.isPressed(pc.KEY_Q)) {
		// Check for key presses
		this.camera.localPosition.x -= right.x / speed;
		this.camera.localPosition.z -= right.z / speed;
	}

	if (app.keyboard.isPressed(pc.KEY_D)) {
		this.camera.localPosition.x += right.x / speed;
		this.camera.localPosition.z += right.z / speed;
	}

	if (app.keyboard.isPressed(pc.KEY_W)) {
		this.camera.localPosition.x += forward.x / speed;
		this.camera.localPosition.z += forward.z / speed;
	}

	if (app.keyboard.isPressed(pc.KEY_S)) {
		this.camera.localPosition.x -= forward.x / speed;
		this.camera.localPosition.z -= forward.z / speed;
	}

	// use direction from keypresses to apply a force to the character
	if (x !== 0 && z !== 0) {
		force.set(x, 0, z).normalize().scale(this.power);
		this.entity.rigidbody.applyForce(force);
	}

	// update camera angle from mouse events
	this.camera.setLocalEulerAngles(this.eulers.y, this.eulers.x, 0);
};

FirstPersonMovement.prototype._onMouseMove = function (e) {
	// If pointer is disabled
	// If the left mouse button is down update the camera from mouse movement
	if (pc.Mouse.isPointerLocked() || e.buttons[0]) {
		this.eulers.x -= this.lookSpeed * e.dx;
		this.eulers.y -= this.lookSpeed * e.dy;
	}
};

FirstPersonMovement.prototype._createCamera = function () {
	// If user hasn't assigned a camera, create a new one
	this.camera = new pc.Entity();
	this.camera.setName('First Person Camera');
	this.camera.addComponent('camera');
	this.entity.addChild(this.camera);
	this.camera.translateLocal(0, 0.5, 0);
};

import { Data } from './data';
import { Store } from './store';
import { Languages } from './languages';
import { HandRail } from './handRail';

export class HandRails extends Data {
	objectName = 'HandRails';
	static CURRENT = null;
	url = 'HandRails';

	// Vanuit data word deze functie aangeroepen om de list te setten.
	fetchListItem(item) {
		let returnItem = new HandRail();
		returnItem.setStore(this.store);
		returnItem.fetch(item);
		return returnItem;
	}
	getDefaultItem() {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// zoek trap die als defaultkeuze is aangevinkt
			let defaultItem = this._list.list.filter(function (item) {
				return item.defaultChoice === true;
			});
			if (defaultItem.length > 0) {
				// als defaultStair gevonden
				// return { caption: '', value: '' };
				return defaultItem[0];
			}
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object

		return { oid: -1, handrailOid: -1, description: '' };
	}
	getItemByArticleOid(articleOid){

		// omdat de hele lijst met list opgehaald wordt hier geen aparte routine of gebruik van get om een item op de server op te halen.
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let found = { oid: '', description: '' };

			this._list.list.forEach((item) => {
				if (item.handrailOid === articleOid) {
					found = item;
				}
			});

			return found;
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return { id: '-1', description: '' };
	}
	getItem(id) {
		// omdat de hele lijst met list opgehaald wordt hier geen aparte routine of gebruik van get om een item op de server op te halen.
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let found = { caption: '', value: '' };

			this._list.list.forEach((item) => {
				if (item.id === id) {
					found = item;
				}
			});

			return found;
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return { id: '-1', description: '' };
	}

	isChildFriendly(id) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let handrail = this._list.list.find(h => h.value === id);

      if (typeof handrail !== 'undefined' && typeof handrail.childFriendly !== 'undefined') {
        return handrail.childFriendly;
      }
		}

		return false;
	}
	getMaterials(id = null, byOid = false) {
		// Wanneer geen id opgegeven alle materialen teruggeven.
		if (id === null) {
			let allMaterials = [];
			this._list.list.forEach((item) => {
				allMaterials.push(item.material);
			});

			return allMaterials;
		}
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let item = this._list.list.find((item) => item.oid === id);

      if (!byOid) {
        item = this._list.list.find((item) => item.value === id);
      }

			// Wanneer hij voorkomt in de lijst.
			if (item !== null && typeof item !== 'undefined') {
				return item.material;
			}
			// Anders alles returnen.
			return this.getMaterials();
		}
	}

  inList(oid) {
    if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
      return this._list.list.filter(hr => hr.oid === oid).length > 0;
    }
    return false;
  }

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		data.language = Languages.CURRENT.currentLanguage;
		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}

import { Configuration } from '../../configurator/configuration';
import { jsonPalletGate } from '../../configurator/hicad/jsonPalletGate';
import { Store } from '../../data/store';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';

export class PalletGate3D extends Model3D {
	complexModel = true;
	constructor(x, y, params) {
		super(x, params.yPosition, y, params);
		this.oid = Store.CURRENT.palletGates.find(this.info.type, this.info.width, this.info.height).palletgateId;
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		this.y -= deckingFinishHeight;

		if (this.info.type === 'Self closing gate') {
			this.ralColor = Configuration.CURRENT.colors.palletGates.ralColor;
		} else if (this.info.type === 'chain gate') {
			this.ralColor = Configuration.CURRENT.colors.palletGates.ralColor;
		} else {
			this.ralColor = Configuration.CURRENT.colors.tiltGates.ralColor;
		}

		super.draw(app);
	}
	convert() {
		return new jsonPalletGate(this);
	}
}

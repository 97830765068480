import { HandRails } from '../../configurator/handRails';
import { Stair } from '../../configurator/stair';
import { Store } from '../../data/store';
import { Canvas3D } from '../Canvas3D';
import { HandRailChildPole3D } from './HandRailChildPole3D';
import { HandRailChildPoleHolder3D } from './HandRailChildPoleHolder3D';
import { HandRailPlateMiddle3D } from './handrailplate/HandRailPlateMiddle3D';
import { HandRailPlateTop3D } from './handrailplate/HandRailPlateTop3D';
import { HandRailPole3D } from './HandRailPole3D';
import { HandRailKickEdge3D } from './kickedge/HandRailKickEdge3D';
import { Configuration } from '@/configurator/configuration';

export class HandRail3D {
	objectName = 'HandRail3D';
	constructor(x, y, z, params, position, length, flippedKickEdge = false) {
		let totalPoles = 0;
		let distance = 0;
		let xPosition;
		let zPosition;
		let childPoleX;
		let childPoleZ;

		// wanneer er van company geswitched is, kan het voorkomen dat het oid niet meer voorkomt in de lijst.
		// anders de eerste uit de lijst pakken
		let byHandRailOid = true;
		if (!Store.CURRENT.handRails.inList(params.handRailType)) {
			Configuration.CURRENT.handrailType = Store.CURRENT.handRails.getDefaultItem().value;
			params.handRailType = Configuration.CURRENT.handrailType;
			byHandRailOid = false;
		}

		this.handRailMaterials = Store.CURRENT.handRails.getMaterials(params.handRailType, byHandRailOid);

		// this.handRailMaterials = Store.CURRENT.handRails.getMaterials(Configuration.CURRENT.handRailType);
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');
		let childPoleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.childFriendlyPost, 'width');

		params.cornerPole = false;
		params.middlePoleLength = 1050;
		params.cornerPoleLength = 1100;
		params.handRailMaterials = this.handRailMaterials;

		// Rechterkant dan altijd 1 pole naar binnen tekenen.
		if (position === HandRails.RIGHT) {
			x -= poleWidth;
		}

		// Positie onderkant is altijd tot onderste punt, dus dan 1 paaltje terug naar boven tekenen.
		if (position === HandRails.BOTTOM) {
			z -= poleWidth;
		}

		// Tekenen handrail eindigt altijd op laatste punt, maar in 3D teken je dan buiten de vloer. dus altijd 1 pole terug tekenen.
		length -= poleWidth;

		totalPoles = this.calculatePaaltes(length / 1000);
		distance = length / totalPoles;

		// Hoogte die het kindvriendelijke paaltje van de grond moet staan.
		let childPoleDistanceFromGround = 90;

		// Aantal kinderpaaltjes tussen normale paaltjes in.
		let amountofChildPoles = Math.floor(distance / 100) + (totalPoles > 1 ? 1 : 0);

		// Ruimte tussen nomale pole en eerste of laatste childpole.
		let childPoleDistance = distance - 70;

		// Standaard hoogte van childpole
		let childPoleHeight = 825;

		// Standaard hoogte vanaf de grond tot childpoleholder onder
		let childPoleHolderHeightFromGround = 30;

		let childPoleHolderHeight = Canvas3D.CURRENT.getModelData('childfriendly_pole_holder', 'height');

		// Parameters hier toekennen voor blokken profielnorm configurator.
		this.x = x;
		this.y = y;
		this.z = z;
		this.distance = distance;

		Object.keys(params).forEach((param) => {
			this[param] = params[param];
		});

		let childPoleHolderParams = {
			width: distance,
			position: position,
			handRailMaterials: this.handRailMaterials,
		};

		// Kickedge over de gehele lengte.
		if (params.childFriendly === false) {
			Canvas3D.CURRENT.addDrawObject(
				new HandRailKickEdge3D(x, y, z, { length: length, position: flippedKickEdge ? Stair.toOppositeUpComing(position) : position, handRailMaterials: this.handRailMaterials }),
				Canvas3D.TYPE_KICKEGDE,
			);
		}

		for (let i = totalPoles; i >= 0; i--) {
			params.cornerPole = false;
			xPosition = this.getXPosition(x, distance, i, position);
			zPosition = this.getZPosition(z, distance, i, position);

			if (i === 0 || i === totalPoles) {
				// Aangeven dat het eerste en laatste paaltje een corner is.
				// Daar komt dan een toplate op en deze zijn beide iets hoger.
				params.cornerPole = true;
				// Dit is nodig om te vertellen of het de eerste is.
				// De houders voor dit paaltje plaatsen we op basis van deze waarde
				if (i === 0) {
					params.pole = 1;
				}
				// Dit is nodig om te vertellen of het de laatste is.
				// De houders voor dit paaltje plaatsen we op basis van deze waarde
				if (i === totalPoles) {
					params.pole = 2;
				}
			}

			// Paaltjes toevoegen
			Canvas3D.CURRENT.addDrawObject(new HandRailPole3D(xPosition, y, zPosition, params), Canvas3D.TYPE_POLE);

			// Voorkomen dat na het laatste paaltje ook nog plates en kindvriendelijke paaltjes worden getekend.
			if (i < totalPoles) {
				// Childfriendly paaltjes tekenen.
				if (params.childFriendly === true) {
					Canvas3D.CURRENT.addDrawObject(new HandRailChildPoleHolder3D(xPosition, y + childPoleHolderHeightFromGround, zPosition, childPoleHolderParams), Canvas3D.TYPE_POLE);
					Canvas3D.CURRENT.addDrawObject(
						new HandRailChildPoleHolder3D(xPosition, y + childPoleHeight + childPoleHolderHeight + childPoleHolderHeightFromGround, zPosition, childPoleHolderParams),
						Canvas3D.TYPE_POLE,
					);

					if (position === HandRails.BOTTOM || position === HandRails.TOP) {
						for (let childI = 0; childI < amountofChildPoles; childI++) {
							childPoleX = xPosition + childPoleWidth * (amountofChildPoles > 1 ? 1.5 : 1) + poleWidth + (childPoleDistance / amountofChildPoles) * childI;
							childPoleZ = zPosition + (position === HandRails.BOTTOM ? poleWidth - childPoleWidth : 0);
							Canvas3D.CURRENT.addDrawObject(new HandRailChildPole3D(childPoleX, y + childPoleDistanceFromGround, childPoleZ, params), Canvas3D.TYPE_POLE);
						}
					} else if (position === HandRails.LEFT || position === HandRails.RIGHT) {
						for (let childI = 0; childI < amountofChildPoles; childI++) {
							childPoleZ = zPosition + childPoleWidth * (amountofChildPoles > 1 ? 1.5 : 2) + poleWidth + (childPoleDistance / amountofChildPoles) * childI;
							childPoleX = xPosition + (position === HandRails.RIGHT ? poleWidth - childPoleWidth : 0);
							Canvas3D.CURRENT.addDrawObject(new HandRailChildPole3D(childPoleX, y + childPoleDistanceFromGround, childPoleZ, params), Canvas3D.TYPE_POLE);
						}
					}
				} else {
					// Leuning op de helft van de paalhoogte
					Canvas3D.CURRENT.addDrawObject(
						new HandRailPlateMiddle3D(xPosition, y, zPosition, {
							length: distance,
							position: position,
							middlePoleLength: params.middlePoleLength,
							cornerPoleLength: params.cornerPoleLength,
							handRailMaterials: this.handRailMaterials,
						}),
						Canvas3D.TYPE_KNEERULE,
					);
				}
			}
		}

		// Lat toevoegen, logica zit in drawobject zelf.
		Canvas3D.CURRENT.addDrawObject(
			new HandRailPlateTop3D(x, y, z, {
				length: length,
				position: position,
				middlePoleLength: params.middlePoleLength,
				handRailMaterials: this.handRailMaterials,
			}),
			Canvas3D.TYPE_HANDRULE,
		);
	}
	calculatePaaltes(length) {
		return Math.ceil(length / 1.5) + (length * 1000 > 1500 ? 1 : 0);
	}
	getXPosition(x, distance, i, position) {
		if (position === HandRails.BOTTOM || position === HandRails.TOP) {
			x += i * distance;
		}
		return x;
	}
	getZPosition(z, distance, i, position) {
		if (position === HandRails.LEFT || position === HandRails.RIGHT) {
			z += i * distance;
		}
		return z;
	}
}

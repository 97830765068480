import { Stair } from '../../../../configurator/stair';
// import { StairOutSide } from '../../../configurator/stairOutSide';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class StairHandRailPole3D extends Model3D {
	objectName = 'stairHandRailPole3D';
	constructor(x, y, yPos, params) {
		super(x, 0, y, params);
		this.yPos = yPos;
		this.oid = params.stairData.material.handRailPost;
	}
	draw(app) {
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
		this.y += this.yPos;

		switch (this.shared.upComing) {
			case Stair.UPCOMING_TOP:
				this.y += this.shared.yPos;
				if (this.trapboomPosition === 'right') {
					this.x -= this.stairData.step.width;
					// this.x -= poleWidth;
				} else {
					this.x -= poleWidth;
				}
				break;

			case Stair.UPCOMING_LEFT:
				this.y += this.shared.yPos;
				if (this.trapboomPosition === 'right') {
					this.z += this.stairData.step.width;
					this.z -= poleWidth;
				}
				break;

			case Stair.UPCOMING_RIGHT:
				this.y += this.shared.yPos;
				if (this.trapboomPosition === 'right') {
					this.z += this.stairData.step.width;
					this.z -= poleWidth;
				}
				break;

			case Stair.UPCOMING_BOTTOM:
				this.y += this.shared.yPos;
				if (this.trapboomPosition === 'right') {
					this.x += this.stairData.step.width;
					this.x -= poleWidth;
				}
				break;
		}

		// Tussen poles bij landingen, altijd na de landing.
		if (this.stairData.place === 'inFloor') {
			if (this.shared.upComing === Stair.UPCOMING_TOP) {
				this.z -= this.shared.deltaX;
			} else if (this.shared.upComing === Stair.UPCOMING_LEFT) {
				this.x -= this.shared.deltaX;
			} else if (this.shared.upComing === Stair.UPCOMING_RIGHT) {
				this.x += this.shared.deltaX;
			} else if (this.shared.upComing === Stair.UPCOMING_BOTTOM) {
				this.z += this.shared.deltaX;
			}
		}

		super.draw(app);
	}
}

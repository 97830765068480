import { Columns } from '../../configurator/columns';
import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
export class GussetPlateHorizontal3D extends Model3D {
	objectName = 'GussetPlateHorizontal3D';
	ralColor = 7016;
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.rowPositions = params.info.rowPositions;
		this.placement = params.info.placement;

		this.assetName = params.gussetPlate.name;
		this.oid = params.gussetPlate.oid;
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		let gussetPlateWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');

    this.x -= (gussetPlateWidth - columnWidth) / 2;

    this.y -= deckingFinishHeight;
    this.y -= this.mainBeamHeight;

		super.draw(app);
	}
}

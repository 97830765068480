import { Data } from './data';

export class Company extends Data {
	objectName = 'Company';
	id = -1;
	name = '';
	code = '';
	phoneNumber = '';
	webSite = '';
	email = '';
	deliveryDays = 0;
	postAddress = -1;
	visitAddress = -1;
	companyAddresses = [];
	visitReports = [];
	notes = [];
	contacts = [];
	nameAndCode = '';
	constructionToolsToggle = false;
	deliveryAddresses = [];
	defaultDeliveryAdress = {};

	url = 'Companies';

	fetch(json) {
		super.fetch(json);
		this.nameAndCode = this.code + ' ' + this.name;
	}
	get defaultDeliveryAddress() {
		if (this.deliveryAddresses.length > 0) {
			return this.deliveryAddresses[0];
		}
		return {
			Oid: -1,
		};
	}
	getCompanyAddressById(id) {
		let addresses = this.companyAddresses.filter((a) => a.id === id);
		if (addresses.length > 0) {
			return addresses[0];
		}

		return { country: '' };
	}
	getCompanyAddress(index) {
		if (typeof this.companyAddresses[index] !== 'undefined' && this.companyAddresses[index] !== null) {
			return this.companyAddresses[index];
		}
		return { country: '' };
	}
	getById(id, force, thenCallBack, catchCallBack) {
		if (typeof id === 'undefined') {
			return;
		}
		super.getById(id, force, thenCallBack, catchCallBack);
	}
}

import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
import { JsonBraceColumn } from '@/configurator/hicad/jsonBraceColumn';

export class BraceColumnRight3D extends Model3D {
	objectName = 'BraceColumnRight3D';
	rotationX = 180;
	rotationZ = 20;
	fallBackName = 'column';
	fallBackData = { width: 90, depth: 90 };
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		if (Configuration.CURRENT.colors.bracingColumns.finish === 1 || Configuration.CURRENT.colors.bracingColumns.finish === 2) {
			this.ralColor = 7036;
		} else {
			this.ralColor = Configuration.CURRENT.colors.bracingColumns.ralColor;
		}

		// Door manier van tekenen en rotatie eigen diepte erbij
		this.z += columnWidth;

		// Hoogte die we standaard meegeven is etage hoogte.
		// Daar dan finishHoogte en beamHoogte vanaf zodat hij precies daaronder komt.
		this.y -= deckingFinishHeight;
		this.y -= this.mainBeamHeight;

		this.height -= deckingFinishHeight;
		this.height -= this.mainBeamHeight;

		// Rechts naast de kolom, dus plus de kolom breedte
		this.x += columnWidth;

		// Min 135 omdat de schoorkolom altijd 135mm onder de profielen moet komen.
		this.y -= 135;

		// Min 35 omdat we op een andere aslocatie roteren en het dan niet goed oogt.
		this.y -= 35;

		this.basePlate.x = this.x + this.info.depth;
		this.basePlate.z = this.z - columnWidth;

		this.basePlate.draw(app);
		super.draw(app);
	}

	convert() {
		return new JsonBraceColumn(this);
	}
}

import { Configuration } from '../../configurator/configuration';
import { HandRails } from '../../configurator/handRails';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
import { HandRailPlateHolder3D } from './handrailplate/handrailplateholder/HandRailPlateHolder3D';

// DIT IS HET EERSTE PLAATJE OP HET PAALTJE
export class HandRailPoleCapPlateMiddle3D extends Model3D {
	objectName = 'HandRailPoleCapPlateMiddle3D';
	assetName = 'handrail_capplate_middle';
	fallBackName = 'handrail_capplate_middle';
	fallBackData = {
		depth: 40,
		height: 10,
		width: 120,
	};
	constructor(x, y, z, params) {
		super(x, y, z, params);

		// Toevoegen van de plaatjes houder, dit scroeft de leuning op de paaltjes
		Canvas3D.CURRENT.addDrawObject(new HandRailPlateHolder3D(x, y, z, params), Canvas3D.TYPE_HOLDER);
	}
	draw(app) {
		let middlePlateWidth = Canvas3D.CURRENT.getModelData('handrail_capplate_middle', 'width');
		let middlePlateDepth = Canvas3D.CURRENT.getModelData('handrail_capplate_middle', 'depth');
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');

		this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;

		switch (this.position) {
			case HandRails.TOP:
				this.x -= middlePlateWidth / 2;
				this.x += poleWidth / 2;
				this.z += (poleWidth - middlePlateDepth) / 2;
				break;
			case HandRails.BOTTOM:
				this.x -= middlePlateWidth / 2;
				this.x += poleWidth / 2;
				this.z += (poleWidth - middlePlateDepth) / 2;
				break;
			case HandRails.LEFT:
				this.rotationY = -90;
				this.z -= middlePlateWidth / 2;
				this.z += poleWidth / 2;
				this.x += (poleWidth - middlePlateDepth) / 2;
				this.x += middlePlateDepth;
				break;
			case HandRails.RIGHT:
				this.rotationY = -90;
				this.z -= middlePlateWidth / 2;
				this.z += poleWidth / 2;
				this.x += (poleWidth - middlePlateDepth) / 2;
				this.x += middlePlateDepth;
				break;
		}

		// Hoogte paaltje bij de Y optellen
		this.y += this.middlePoleLength;

		super.draw(app);
	}
}

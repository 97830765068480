import { HandRails } from '../../configurator/handRails';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';

export class HandRailChildPole3D extends Model3D {
	objectName = 'HandRailChildPole3D';
	width = 1;
	depth = 1;
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.height = 825;
		this.oid = params.handRailMaterials.childFriendlyPost;
		this.ralColor = 7016;
	}
	draw(app) {
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.childFriendlyPost, 'width');
		let childPoleWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');

		switch (this.position) {
			// Bij bottom handrail willen we de childpoles zo ver mogelijk naar onderen uitlijnen.
			// Dus + normale pole - eigen pole breedte.
			case HandRails.BOTTOM:
				this.z += poleWidth - childPoleWidth;
				break;
			// Plus normale paaltjes breedte om zo ver mogelijk rechts op de buitenrand.
			case HandRails.RIGHT:
				this.x += poleWidth - childPoleWidth;
				break;
		}
		super.draw(app);
	}
}
